import { Entity, Model, from, nullable, parse, type } from 'typox'
import { GamePlatformCatgory } from '@/views/game/consts/categorys'
import { _GameItemInter, _GameItemType } from './gameItemInter'
import { _PlatformCategoriesItemInter } from '../gameCategorie/platformCategoriesItemInter'
import { gameOssDomin } from '../util/util'
import { numberToBool } from '@/service/extensions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

/** 热门游戏或普通子游戏信息 */
@Entity()
export default class _GameItem extends Model implements _GameItemInter {
  /** 类型守卫 */
  public static isThisType(data: Any): data is _GameItem {
    const keys = ['gameIcon', 'gameType']
    if (keys.every((key) => Reflect.has(data, key))) {
      return true
    } else {
      return false
    }
  }

  /** 解析平台类型成游戏数据类型
   * 在热门/收藏/最近的标签页,平台游戏都必须以游戏样式进行展示,此时就要先转成游戏样式再进行下一步操作.
   */
  parsePlatformCategoriesItem(source: _PlatformCategoriesItemInter): this {
    this.webId = source.webId
    this.gameId = source.gameId
    this.demoModeSupport = source.demoModeSupport
    this.isCustomIcon = source.isCustomIcon
    this.isOriginImg = source.isOriginImg
    this.gameCategoryId = source.gameCategoryId
    this.platformId = source.platformId
    this.platformName = source.platformName
    this.platformLogo = source.platformLogo
    this.maintainStatus = source.maintainStatus
    this.minimumEntryBalance = source.minimumEntryBalance
    this.anchorSupport = source.anchorSupport
    this.isExternalLink = source.isExternalLink
    // ^^^^^^^^^^^^^^^^^^^^^^^以上为同名字段转换,
    // vvvvvvvvvvvvvvvvvvvvvvv以下为需要特殊处理的字段
    // 平台数据,拿不到推荐状态.
    // this.recommendStatus=source.xxxxxxxx
    this.gameName = source.brand
    this.isDisplayHotIcon = true
    this.gameType = _GameItemType.Platform
    return this
  }

  /** web端使用的唯一id */
  @type(String)
  @parse((_, item) => `${item?.g9}_${item?.g10}_${item?.g0}`)
  webId = ''

  /** 游戏 id */
  @from('g0')
  @type(Number)
  @nullable()
  gameId = 0

  /** 游戏名称 */
  @from('g1')
  @type(String)
  @nullable()
  gameName = ''

  /** 游戏图标 不在此处解析了,因为后台返回的数据结构的问题,有些数据还需要从别的地方取,再加上它比较消耗性能,按需解析,避免重复 */
  // @from('g2')
  // @parse((val, data) => CombinePictureUrl.parseGameItem(data))
  // @type(String)
  // @nullable()
  gameIcon = ''

  /** 自定义图片 0-否, 1-是  */
  @from('g3')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isCustomIcon = false

  /** 是否美术上传的原始图片 0-否, 1-是 */
  @from('g16')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isOriginImg = false

  /** 维护状态 0-否, 1-是  */
  @from('g4')
  @type(Boolean)
  @parse(numberToBool)
  maintainStatus = false

  /** 是否推荐 0-否, 1-是  */
  @from('g5')
  @type(Boolean)
  @parse(numberToBool)
  recommendStatus = false

  /** 是否支持试玩 0-否, 1-是  */
  @from('g6')
  @type(Boolean)
  @parse(numberToBool)
  demoModeSupport = false

  /** 屏幕方向: 0=横屏, 1=竖屏  */
  @from('g7')
  @type(Boolean)
  @parse(numberToBool)
  screenDirection = false

  /** 是否主播号: 0-否, 1-是 */
  @from('g8')
  @type(Boolean)
  @parse(numberToBool)
  anchorSupport = false

  /** 游戏类别  */
  @from('g9')
  @type(Number)
  @nullable()
  gameCategoryId = 0

  /** 游戏平台ID
   * 注: 方型卡片,需要特殊判断platformId,如果是13(WG)平台,则要进行logo的拼接,其它平台全都是整图,则没有这个问题.
   */
  @from('g10')
  @type(Number)
  @nullable()
  platformId = 0

  /**
   * 热门类型 1=平台入口 2=普通游戏
   * 这里的普通游戏可能会不给返回值，那我们就给它默认赋值2(普通游戏)
   */
  @from('g11')
  @type(Number)
  @nullable()
  @parse(
    (_, item) =>
      item?.g11 ||
      (GamePlatformCatgory.includes(item.g9)
        ? _GameItemType.Platform
        : _GameItemType.NormalGame)
  )
  gameType: _GameItemType = _GameItemType.NormalGame

  /** 是否三合一图(图片/logo/背景色) 0-否，1是
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  @from('g12')
  @type(Boolean)
  @parse(numberToBool)
  isCombinate = false

  /** (5159特殊方型) 方形图标
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  @from('g13')
  @type(String)
  @parse(gameOssDomin)
  squareGameIcon = ''

  /** (5159特殊方型) 是否为自定义图片 0-否, 1-是 */
  @from('g14')
  @type(Boolean)
  @parse(numberToBool)
  isSquareCustomIcon = false

  /** (5159特殊方型)  是否方形三合一图片 (图片/logo/背景色) 0-否，1是
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  @from('g15')
  @type(Boolean)
  @parse(numberToBool)
  isSquareCombinate = false

  /** (5159特殊方型) 是否美术上传的原始图片 0-否, 1-是 */
  @from('g17')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isSquareOriginImg = false

  /** 平台名称 */
  platformName = ''

  /** 平台logo */
  platformLogo = ''

  /** 游戏打开方式 0-内嵌, 1-外链 */
  isExternalLink = false

  /** 最小准入余额 */
  minimumEntryBalance = 0

  /** 是否优先展示热门图标(该项为true,不考虑别的条件,直接展示热门图标,它的优先级最高,该项为false,也有可能因为其它条件展示热门图标)
   *  [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
   */
  @from('isDisplayHotIcon')
  @type(Boolean)
  @nullable()
  isDisplayHotIcon = false
}
