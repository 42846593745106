import { Base, Component, Ref } from '@/vue-property-decorator'
import { KindType, PackKindType, androidDownloadType } from '@/api/common/type'
import {
  UrlsItem,
  useDownloadAppStore
} from '@/layouts/components/downloadApp/store'
import { apiNetstatPlayerAdInfo } from '@/api/download'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { getSearchParamsAndTransformObjectByURL } from '@/utils/Tool'
import { isValidUrl } from '@/utils/Tool'
import { random } from 'lodash'
import { replaceUrlByNewDomain } from '@/utils/Tool'
import { sendPointerDomain, windowOpen } from '@/utils/business-utils'
import { useMainStore } from '@/store/index'
import ClipboardJS from 'clipboard'
import isMobile from '@/utils/isMobile'
@Component<BaseMixins>({ name: 'BaseMixins' })
export default class BaseMixins extends Base {
  /**当前点击的计数 */
  protected count = 0
  /**当前点击的下载项，极速包或者常规包 */
  protected item: UrlsItem = {} as UrlsItem
  @Ref()
  protected readonly downloadBtnRef!: HTMLDivElement
  /**格式化获取新人APP下载奖励 */
  protected get formatDownloadAppReward() {
    const { downloadAppReward, userInfos } = useMainStore()
    const formatedDownloadAppReward = currencyFormatter(
      downloadAppReward || 0,
      {
        code: userInfos?.currency,
        precision: 2,
        symbol: ''
      }
    )
    return formatedDownloadAppReward
  }

  /**
   * 获取加速域名的列表
   */
  private get getAccDomainList(): Array<string> {
    const { siteURLInfos } = useMainStore()
    return siteURLInfos?.oss_domain ?? []
  }

  /**
   * @description 替换安卓下载地址的站点域名为加速域名
   */
  private async replaceSiteDomainByAccDomain(downloadUrl: string) {
    const accDomainList = this.getAccDomainList
    // 如果没有加速域名的场景
    if (!accDomainList.length) {
      return downloadUrl
    }
    const randomAccDodamin = accDomainList[random(1, accDomainList.length) - 1]
    const replacedUrl = replaceUrlByNewDomain(downloadUrl, randomAccDodamin)
    // 校验是否为一个有效的url
    const isValid = await isValidUrl(replacedUrl)
    if (isValid) {
      return replacedUrl
    } else {
      return downloadUrl
    }
  }

  /**点击下载按钮*/
  protected async handlerDownloadBtn(item: UrlsItem, count: number) {
    const isIOS = isMobile().apple.device
    this.item = item
    this.count = count
    if (isIOS) {
      this.innerHandlerDownloadBtn()
    }
  }

  /**点击下载按钮通过渠道强制下载app*/
  protected async handlerDownloadBtnByForceDownload(
    item: UrlsItem,
    count: number
  ) {
    this.item = item
    this.count = count
    this.innerHandlerDownloadBtn()
  }

  /**点击下载按钮*/
  private async innerHandlerDownloadBtn() {
    const { count, item } = this
    const isIOS = isMobile().apple.device
    const { downloadPath, customizePath, value } = item
    const notClickedUrls: string[] = []
    if (isIOS) {
      notClickedUrls.push(value as string)
    } else {
      if (downloadPath) {
        const replacedUrl = await this.replaceSiteDomainByAccDomain(
          downloadPath
        )
        notClickedUrls.push(replacedUrl)
      }
      if (customizePath) {
        notClickedUrls.push(customizePath)
      }
    }
    useDownloadAppStore().reportDownloadUrlClick(item)
    let packKind = PackKindType.Normal
    if (item.type === androidDownloadType.SPEED) {
      packKind = PackKindType.Quickly
    }
    sendPointerDomain(KindType.ClickDownLoad, packKind)
    //2个链接循环尝试
    windowOpen(notClickedUrls[count % notClickedUrls.length])
  }

  /**
   * @description 将字符串转化为数字
   * @param value number
   */
  protected validateNumber(value: string | undefined): number {
    if (!value || !Number(value)) {
      return 0
    }
    return Number(value)
  }

  /**
   * @description 创建一个剪贴板，将url相关参数贴到粘贴板，给极速包用
   * @example
   * {
    * "facebook": {
          "fbcid": "",
          "pixel_id": ""
      },
      "wg": {
          "name": "WG",
          "cid": 123,
          "id": 123
      },
      "timestamp": 17123133313
    }
   */
  protected get getClipBoardParams() {
    const searchParams = getSearchParamsAndTransformObjectByURL()
    const { fbclid, pixel_id, cid, id } = searchParams
    const clipBoardParams = {
      facebook: {
        fbclid,
        pixel_id
      },
      wg: {
        name: 'WG',
        cid,
        id
      },
      timestamp: Math.floor(Date.now() / 1000)
    }
    return JSON.stringify(clipBoardParams)
  }

  /**
   * @description 将对象中属性转化为数字
   */
  private get clipBoardParamsTransformNumber() {
    const validateNumber = this.validateNumber
    const parseedClipBoardParams = JSON.parse(this.getClipBoardParams)
    const { facebook, wg } = parseedClipBoardParams
    const { fbclid, pixel_id } = facebook
    const { cid, id } = wg
    return {
      facebook: {
        fbclid,
        pixel_id
      },
      wg: {
        name: 'WG',
        cid: validateNumber(cid),
        id: validateNumber(id)
      },
      timestamp: Math.floor(Date.now() / 1000)
    }
  }

  /**
   * @description 粘贴成功或者失败的回调函数
   */
  private clipBoardCallback() {
    this.innerHandlerDownloadBtn()
    this.postApiNetstatPlayerAdInfo()
  }

  protected postApiNetstatPlayerAdInfo() {
    // 被转化为数字后的对象
    const transformedClipBoardParams = this.clipBoardParamsTransformNumber
    apiNetstatPlayerAdInfo(transformedClipBoardParams)
  }

  /**
   * @description 创建剪贴板
   */
  protected async createClipBoard() {
    // 只有安卓设备才需要创建剪贴板，应用的极速包为安卓极速包
    const isIos = isMobile().apple.device
    if (isIos) {
      return
    }
    const clipboard = new ClipboardJS(this.downloadBtnRef)
    clipboard.on('success', this.clipBoardCallback)
    clipboard.on('error', this.clipBoardCallback)
  }

  /**
   * @description 获取极速包，或者常规包的安卓或者iOS的版本号
   */
  protected getAppVersion(item: UrlsItem): string {
    const { appVersion } = useDownloadAppStore()
    if (!appVersion) {
      return ''
    }
    const {
      iosPkgVersion,
      iosWebSpeedPkgVersion,
      androidPkgVersion,
      androidWebSpeedPkgVersion
    } = appVersion
    const { type } = item
    const isApple = isMobile().apple.device
    if (isApple) {
      if (type === androidDownloadType.SPEED) {
        return iosWebSpeedPkgVersion
      }
      if (type === androidDownloadType.NORMAL) {
        return iosPkgVersion
      }
    } else {
      if (type === androidDownloadType.SPEED) {
        return androidWebSpeedPkgVersion
      }
      if (type === androidDownloadType.NORMAL) {
        return androidPkgVersion
      }
    }
    return ''
  }

  created() {
    const { getNewComerBenefit } = useDownloadAppStore()
    getNewComerBenefit()
  }

  render() {
    return <template></template>
  }
}
