/**
 * 因持久化插件是一个store中的状态被变更后，都会整个store状态的整体更新
 * 比如登出或者踢线的场景，某个tab已经清理了localStorage 其它tab中可能会用内存中的状态再次写入进去导致token又被写入
 * 故独立一个状态来存token，且不可以加其它状态
 */

import { createMyPersistedStorage } from '@/utils/business-utils/presisted'
import { defineStore } from 'pinia'
import { pick } from 'lodash'
interface TokenInfos {
  jwt_token: string
  session_key: string
  userkey: string
}

interface TokenState {
  tokenInfos: TokenInfos | null
}

export const useTokenStore = defineStore('token', {
  state: (): TokenState => ({
    tokenInfos: null
  }),
  actions: {
    removeToken() {
      this.tokenInfos = null
    },
    updateToken(tokenInfos: TokenInfos) {
      this.tokenInfos = tokenInfos
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.token',
        storage: createMyPersistedStorage(
          {
            getItem(key, storage) {
              /**
               * 兼容旧的取值
               */
              if (!storage.getItem(key, storage)) {
                const { userInfos } =
                  JSON.parse(localStorage.getItem('app.userInfos') || '') || {}

                if (userInfos && userInfos.jwt_token && userInfos.session_key) {
                  storage.setItem(
                    key,
                    JSON.stringify({
                      tokenInfos: pick(userInfos, [
                        'jwt_token',
                        'session_key',
                        'userkey'
                      ])
                    }),
                    storage
                  )
                }
              }

              return storage.getItem(key, storage)
            }
          },
          localStorage
        ),
        paths: ['tokenInfos']
      }
    ]
  }
})
