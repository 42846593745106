import { Base, Component, VueTsx, Watch } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { Modal, ModalEmits } from '@/plugins/ant-design-vue/tsx-support'
import { createPngSprite } from '@/utils/business-utils/assets'
import { delay } from '@/utils/Tool'
import { useMainStore } from '@/store/index'
import style from './style.module.scss'

export interface Options {
  perfectScrollbarDisabled?: boolean
  modalConfig?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Modal.Tsx,
    unknown
  > & {
    on?: ModalEmits
    isFullScreen?: boolean // 全屏
  }
  renderContent?: () => Arrayed<BaseRenderContent>
  h5OriginClosableCallback?: () => void
  /** 填充关闭按钮的高度 h5下面是否把关闭按钮计算入弹框高度，适用于 按钮底部距离屏幕底部的距离 = 弹框顶部距离屏幕顶部的距离 */
  fillCloseIconHeight?: boolean
}

@Component<ModalBase>({
  name: 'ModalBase'
})
export default class ModalBase<
  State = unknown,
  Props = unknown,
  PrefixedEvents = unknown,
  ScopedSlotArgs = unknown
> extends Base<State, Props, PrefixedEvents, ScopedSlotArgs> {
  public animationDuration = 300
  protected get maskTransitionName() {
    return GlobalConst.CssClassName.Animate.TransitionName.Fade
  }
  protected get transitionName() {
    return GlobalConst.CssClassName.Animate.TransitionName.Zoom
  }
  protected visible = false
  protected destroyOnClose = true
  protected perfectScrollbarDisabled = false
  // 移动端自动撑满屏幕
  protected autoresize = false
  protected centered = true
  /**执行额外的方法，当弹出框被关闭前执行 */
  /* eslint-disable @typescript-eslint/no-empty-function*/
  protected extraCallbackBeforeClose? = () => {}
  protected renderModal(options: Options = {}) {
    this.destroyOnClose =
      options?.modalConfig?.props?.destroyOnClose ?? this.destroyOnClose
    this.extraCallbackBeforeClose =
      options?.modalConfig?.props?.extraCallbackBeforeClose
    const { isWeb, siteMaintenanceStatus } = useMainStore()
    this.perfectScrollbarDisabled = !!options.perfectScrollbarDisabled
    const originClosable = options?.modalConfig?.props?.closable
    const closeConfig: { closable?: boolean } = { closable: undefined }
    if (
      (originClosable || originClosable === undefined) &&
      options.modalConfig?.isFullScreen !== true
    ) {
      // 如果配置可以关闭，则需要在h5模式的时候隐藏默认关闭按钮
      if (!isWeb) {
        closeConfig.closable = false
      }
    } else if (options?.modalConfig?.props?.closable === false) {
      closeConfig.closable = false
    }

    const hasH5Closable =
      originClosable !== false &&
      options.modalConfig?.isFullScreen !== true &&
      !isWeb

    return (
      <Modal.Tsx
        class={{ 'my-modal-base': true, [style.modalBase]: this.autoresize }}
        centered={this.centered}
        destroyOnClose={this.destroyOnClose}
        v-model={this.visible}
        maskClosable={false}
        footer={null}
        afterClose={this.afterClose}
        maskTransitionName={this.maskTransitionName}
        transitionName={this.transitionName}
        {...{
          directives: this.perfectScrollbarDisabled
            ? []
            : [
                {
                  name: 'perfect-scrollbar',
                  value: {
                    el: (el: HTMLElement) =>
                      el.querySelector('.ant-modal-content')
                  }
                }
              ]
        }}
        {...(options?.modalConfig as VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
          typeof Modal,
          unknown
        >)}
        data-fill-close-icon-height={
          hasH5Closable && options.fillCloseIconHeight
        }
        props={{ ...options?.modalConfig?.props, ...closeConfig }}
      >
        {options?.renderContent?.()}
        {hasH5Closable && (
          <div
            v-show={!siteMaintenanceStatus}
            class={[style.closeIcon, 'closeIcon']}
            onClick={VueTsx.modifiers.stop(() => {
              this.close()
              // 组件传递了 afterClose 回调，适当的时机会自动触发，这里不用显示调用，会导致周期错乱
              // this.afterClose()
              options?.h5OriginClosableCallback?.()
            })}
          >
            <icon-sprite
              sprite={createPngSprite('sprite_main_img_close_s2')}
              scale={0.68}
            />
          </div>
        )}
      </Modal.Tsx>
    )
  }

  render() {
    return this.renderModal()
  }

  /**
   * 基础打开方法
   */
  protected async baseOpen() {
    this.visible = true
    if (this.animationDuration) {
      await delay(this.animationDuration)
    }
  }
  /** 关闭弹框事,需要被传递的参数 */
  private closeEmitOptions: unknown[] | undefined
  @Watch('visible')
  protected async baseOnVisibleChanged() {
    if (!this.visible) {
      if (this.animationDuration) {
        await delay(this.animationDuration)
      }
      this.$emit('modal:close', ...(this.closeEmitOptions || []))
      this.closeEmitOptions = undefined
    }
  }

  /**
   * 基础关闭方法
   */
  protected async baseClose() {
    this.visible = false
    if (this.animationDuration) {
      await delay(this.animationDuration)
    }
  }

  /**
   * 基础关闭后的回调
   */
  protected baseAfterClose() {
    if (this.destroyOnClose) {
      this.$reset()
    }
  }

  public afterClose() {
    this.extraCallbackBeforeClose?.()
    this.baseAfterClose()
  }
  public async open(_options?: unknown) {
    await this.baseOpen()
  }
  public async close(...closeEmitOptions: unknown[]) {
    if (this.isKeptAlive) {
      return
    }
    this.closeEmitOptions = closeEmitOptions
    await this.baseClose()
    // 此处用于对登录弹窗中使用传送门,将dom传出去,未实际打开弹窗,但有动到数据,需要清空的场景
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((closeEmitOptions?.[0] as any)?.destroyOnClose) {
      this.$reset()
    }
  }
  public async toggle() {
    this.visible = !this.visible
    if (this.animationDuration) {
      await delay(this.animationDuration)
    }
  }
}
