import { loadScripts } from './Loader'

export interface Options {
  appierId?: string
}
export default class Appier {
  private options!: Options

  constructor(options: Options) {
    this.options = Object.assign({}, options)
    this.init()
  }

  public init(): void {
    this.loadJs()
  }

  public AppierRetargeting() {
    window.appier_q = window.appier_q || []
    window.appier_q.push(
      { t: 'register', content: { id: '5aa3', site: 'nn.vip' } },
      {
        t: 'pv_track',
        action_id: 'PageView_28e5',
        track_id: 'dd427a4eaded57b',
        isCountReload: true,
        counter: 0
      },
      {
        t: 'pv_track',
        action_id: 'ViewTwoPages_5455',
        track_id: 'dd427a4eaded57b',
        isCountReload: false,
        counter: 1
      }
    )
  }

  public AppierLandingPage() {
    window.appier_q = window.appier_q || []
    window.appier_q.push(
      { t: 'register', content: { id: '5aa3', site: 'nn.vip' } },
      {
        t: 'type_landing',
        action_id: 'ViewLanding_8a28',
        track_id: 'dd427a4eaded57b',
        opts: { unique_key: 'true' }
      }
    )
  }

  public AppierEventPush(eventName: string, _params: object): void {
    const eventNameFormat: { [key: string]: () => void } = {
      register: () => {
        window.appier_q = window.appier_q || []
        window.appier_q.push(
          { t: 'register', content: { id: '5aa3', site: 'nn.vip' } },
          {
            t: 'type_conversion',
            content: 'submit',
            action_id: 'Conversion_825b',
            track_id: 'dd427a4eaded57b',
            opts: { unique_key: 'true' }
          }
        )
      }
    }
    if (eventNameFormat[eventName]) eventNameFormat[eventName]()
    else this.AppierRetargeting()
  }

  private async loadJs() {
    const gtag = {
      id: 'script-Appier',
      async: true,
      src: `https://jscdn.appier.net/aa.js?id=${this.options.appierId}`
    }

    const scriptArray = []
    scriptArray.push(gtag)
    await loadScripts(scriptArray)
    this.AppierRetargeting()
    this.AppierLandingPage()
  }
}
