/**
 * 单例模式
 */
interface InstanceMap<T> {
  [key: string]: T
}
export class Singleton {
  private static instances: InstanceMap<unknown> = {}

  static getInstance<T>(className: string, constructor: new () => T): T {
    const instance = Singleton.instances[className]
    if (instance) {
      return instance as T
    }

    const newInstance = new constructor()
    Singleton.instances[className] = newInstance
    return newInstance
  }
}

/**
 *
 * @param obj1 source object
 * @param obj2 target object
 * @returns diff object'
 * @description 深度比较两个对象，返回两个对象之间的差异
 * @example
 * deepObjectDiff({ a: 1, b: 2 }, { a: 1, b: 3 }) // { b: 2 }
 */
/* eslint-disable */
export const deepObjectDiff = (
  obj1: Record<string, any> | null,
  obj2: Record<string, any> | null
): Record<string, any> => {
  const diff: Record<string, any> = {}

  /* eslint-enable */

  // 确保 obj1 和 obj2 不是 null
  if (obj1 === null || obj2 === null) {
    return diff // 在这种情况下直接返回空的 diff 对象
  }

  // 获取所有字段的集合
  const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)])

  // 遍历每个字段
  for (const key of keys) {
    const val1 = obj1[key]
    const val2 = obj2[key]

    // 如果字段在 obj1 中但不在 obj2 中，或者在 obj2 中但不在 obj1 中，将其添加到 diff 中
    if (!obj2.hasOwnProperty(key)) {
      diff[key] = val1
    } else if (!obj1.hasOwnProperty(key)) {
      diff[key] = val2
    } else {
      // 如果字段存在于两个对象中，但值不相等，递归比较值
      if (typeof val1 === 'object' && typeof val2 === 'object') {
        const nestedDiff = deepObjectDiff(val1, val2)
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff
        }
      } else if (val1 !== val2) {
        diff[key] = val1
      }
    }
  }

  return diff
}
