/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-11 15:20:47
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\rules\params.ts
 * @Description: 附加参数规则，用于某些规则下获取一些额外的信息（这些信息应该是公共的，不应该是业务相关的）
 * @example:
 * const ext = await getExtParams([EParamsType.withContext, EParamsType.withNavigateConnection])
 */
import { ContextInfo, NavigatorConnectionInfo } from '../modules/type'

export enum EParamsType {
  withContext = 'withContext',
  withNavigateConnection = 'withNavigateConnection',
  withUserInfo = 'withUserInfo'
}

export const paramsWithFn: {
  [key in EParamsType]: () => {
    context?: ContextInfo
    navigatorConnection?: NavigatorConnectionInfo
    userInfo?: Record<string, unknown>
  }
} = {
  /**
   * 添加当前上下文环境信息
   */
  [EParamsType.withContext]: () => {
    return {
      context: {
        // 其他系统环境变量写这里
      }
    }
  },
  /**
   * 添加关于设备网络环境的信息
   */
  [EParamsType.withNavigateConnection]: () => {
    const connection =
      (navigator as Navigator & { connection?: NavigatorConnectionInfo })
        ?.connection || {}
    return {
      navigatorConnection: {
        downlinkMax: connection.downlinkMax,
        effectiveType: connection.effectiveType,
        rtt: connection.rtt,
        saveData: connection.saveData
      }
    }
  },
  /**
   * 添加用户信息到上报参数
   */
  [EParamsType.withUserInfo]: () => {
    return {
      userInfo: {
        // 其他用户信息写这里
      }
    }
  }
}

/**
 * 获取附加参数，执行所有的规则函数，并将结果合并
 * @param fnNames
 * @returns
 */
export const getExtParams = async (fnNames: Array<EParamsType>) => {
  const promises = fnNames.map((name) => paramsWithFn[name]())
  return Promise.all(promises).then((results) => {
    return results.reduce((acc, cur) => ({ ...acc, ...cur }), {})
  })
}
