import { AxiosResponse } from 'axios'
import { CombinePictureUrl } from '../entity/util/combinePictureUrl'
import { FavoriteInfo } from '../entity/favorite'
import { GameCategoriesInfo } from '../entity/gameCategorie'
import { GetPlatformGameListParams } from './type'
import { GlobalService } from '@/context'
import { ModelExtensions } from '@/service/extensions'
import { SupplyPlatformInfo } from './supplyPlatformInfo'
import AnchorHot from '../entity/anchorHot'
import GameItem, { GameItemInter } from '../entity/gameItem'
/* eslint-disable @typescript-eslint/no-explicit-any*/
type Any = any
/** 游戏接口的整合类 */
export class GameRepository {
  /** 为gameItem增补上平台信息工具类 */
  private static supplyIns = new SupplyPlatformInfo()
  /** 为gameItem增补上平台信息函数(方法将改变原对象) */
  public static async supplementPlatformInfo(
    data: GameItemInter | Array<GameItemInter>
  ) {
    await this.supplyIns.run(data)
  }
  /** 重置补充平台信息的状态
   * 重置之后,再调用该接口进行赋值时,会重新刷新 platformIdObjMapper(平台信息的映射)
   */
  public static resetSupplementPlatformInfoStatus() {
    this.supplyIns.resetStatus()
  }
  /**
   * 获取收藏游戏id列表
   * @returns Promise<FavoriteInfo>
   */
  static async getFavoriteGameIdList(): Promise<FavoriteInfo> {
    return GlobalService.post({
      url: '/gameapi/favorite-list-all/v2'
    }).then((res) => {
      return new FavoriteInfo().parse(res.data.data)
    })
  }

  /**
   * 获取游戏类别列表
   * @returns Promise<GameCategoriesAvailableInfos[]>
   */
  static async getGameCategorieList(): Promise<GameCategoriesInfo[]> {
    return GlobalService.get<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/listPlatformCateLoadV2',
      customParams: {
        tryStaticMode: true,
        cache: true,
        staticKeyOmit: ['platformType']
      }
    }).then((res) => {
      const data = res?.data?.data ?? []
      const ret = ModelExtensions.parseArray(data, GameCategoriesInfo)
      ret.forEach(({ list }) => {
        list.forEach(
          (item) =>
            (item.platformIcon = CombinePictureUrl.parsePlatformItem(item))
        )
      })
      return ret
    })
  }

  /**
   * 获取特色子游戏列表
   * @returns Promise<GameItemInter[]
   */
  static async getSpecialGameList(): Promise<GameItemInter[]> {
    return GlobalService.get<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/listSpecialGameV2',
      customParams: {
        tryStaticMode: true,
        cache: true,
        staticKeyOmit: ['platformType']
      }
    }).then(async (res) => {
      const data = res?.data?.data ?? []
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        item.gameIcon = CombinePictureUrl.parseGameItem(item)
      })
      return ret
    })
  }

  /**
   * 搜索游戏列表
   * @param {Object} payload - 搜索参数
   * @param {string} payload.gameName - 游戏名称
   * @returns Promise<GameItemInter[]
   */
  static async searchGameList(gameName: string): Promise<GameItemInter[]> {
    return GlobalService.post<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/listSearchGameV2',
      customParams: {
        cache: true
      },
      data: { gameName }
    }).then(async (res) => {
      let data: Array<Any> = res?.data?.data ?? []
      // [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
      data = data.map((item) => {
        item['isDisplayHotIcon'] = true
        return item
      })
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        item.gameIcon = CombinePictureUrl.parseGameItem(item)
      })
      return ret
    })
  }

  /**
   * 获取平台下二级子游戏列表
   * @param {GetPlatformGameListParams} payload 类别和平台id
   * @returns Promise<GameItemInter[]
   */
  static async getPlatformGameList({
    platformId,
    gameCategoryId
  }: GetPlatformGameListParams): Promise<GameItemInter[]> {
    return GlobalService.get<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/listPlatformGameV2',
      customParams: {
        tryStaticMode: true,
        cache: true,
        staticKeyOmit: ['platformType']
      },
      params: {
        categoryId: gameCategoryId,
        platformId
      }
    }).then(async (res) => {
      const data = res?.data?.data ?? []
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        item.gameIcon = CombinePictureUrl.parseGameItem(item)
      })
      return ret
    })
  }

  /**
   * 获取热门游戏列表
   * @returns Promise<GameItemInter[]
   */
  static async getHotGameList(): Promise<GameItemInter[]> {
    return GlobalService.get<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/hotListV2',
      customParams: {
        tryStaticMode: true,
        cache: true,
        staticKeyOmit: ['platformType']
      }
    }).then(async (res) => {
      let data: Array<Any> = res?.data?.data ?? []
      // [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
      data = data.map((item) => {
        item['isDisplayHotIcon'] = true
        return item
      })
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        item.gameIcon = CombinePictureUrl.parseGameItem(item)
      })
      return ret
    })
  }

  /**
   * 获取试玩游戏列表
   * @returns Promise<GameItemInter[]
   */
  static async getDemoGameList(): Promise<GameItemInter[]> {
    return GlobalService.get<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/listTryGameV2',
      customParams: {
        tryStaticMode: true,
        cache: true,
        staticKeyOmit: ['platformType']
      }
    }).then(async (res) => {
      const data = res?.data?.data ?? []
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        item.gameIcon = CombinePictureUrl.parseGameItem(item)
      })
      return ret
    })
  }

  /**
   * 获取最近游戏列表
   * @returns Promise<GameItemInter[]
   */
  static async getRecentGameList(): Promise<GameItemInter[]> {
    return GlobalService.post<unknown, AxiosResponse<[]>>({
      url: '/gameapi/recent-list/v3'
      // customParams: {
      //   cache: true
      // }
    }).then(async (res) => {
      let data: Array<Any> = res?.data?.data ?? []
      // [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
      data = data.map((item) => {
        item['isDisplayHotIcon'] = true
        return item
      })
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        const url = CombinePictureUrl.parseGameItem(item)
        item.gameIcon = url
      })
      return ret
    })
  }

  /**
   * 获取收藏游戏列表
   * @returns Promise<GameItemInter[]
   */
  static async getFavoriteGameList(): Promise<GameItemInter[]> {
    return GlobalService.post<unknown, AxiosResponse<[]>>({
      url: '/gameapi/favorite-list-all/v3'
      // customParams: {
      //   cache: true
      // }
    }).then(async (res) => {
      let data: Array<Any> = res?.data?.data ?? []
      // [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
      data = data.map((item) => {
        item['isDisplayHotIcon'] = true
        return item
      })
      const ret = ModelExtensions.parseArray(data, GameItem)
      await this.supplementPlatformInfo(ret)
      ret.forEach((item) => {
        const url = CombinePictureUrl.parseGameItem(item)
        item.gameIcon = url
      })
      return ret
    })
  }
  /*
   * 获取主播热门游戏列表(只有json)
   * @returns Promise<AnchorHot>
   */
  static async getAnchorHotList(): Promise<AnchorHot[]> {
    return GlobalService.get<unknown, AxiosResponse<[]>>({
      url: '/api/game/hall/listAnchorHotLink',
      customParams: {
        tryStaticMode: true,
        onlyStaticMode: true,
        cache: true,
        staticKeyOmit: ['platformType']
      }
    }).then((res) => {
      const data = res?.data?.data ?? []
      const ret = ModelExtensions.parseArray(data, AnchorHot)
      return ret
    })
  }
}
