import { Banner, MessageAllRequest } from '@/api/home/type'
import { HomeState } from './type'
import { JumpType } from '@/views/game/consts'
import { apiGetMarqueeList, apiGetMessageAll } from '@/api/home'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { useEventStore } from '@/views/event/store'
import { useNoticeStore } from '@/views/notice/store'
import moment from 'moment'

export const useHomeStore = defineStore('home', {
  state: (): HomeState => ({
    banner: [],
    marquee: [],
    isFirstLoading: true, //记录是首次加载跑马灯，刚启动时跑马灯延迟5秒加载 其他页面回到主页不用延迟
    isUserCloseSuggest: false, //是否关闭过广告
    collapsed: false, // 首页侧边栏是否收起状态 false为开启
    h5AsideShow: false, // 首页H5侧边栏 是否展开 false为关闭
    loginPanel: false, // 展示银河金登录弹窗
    galaxyGoldL2RActiveType: 'login', //银河金登录/注册弹窗tab状态
    isLongScreen: false, // 是否为长屏（手机端）
    isShowSuggest: false, //是否展示广告
    h5AsideMaskSHow: true //H5侧边栏遮罩层 false为关闭 默认打开侧边栏需要打开，230定制打开侧边栏需要关闭遮罩层（设置成透明）
  }),
  getters: {
    bannerList(): HomeState['banner'] {
      const { activeList } = useEventStore()
      // 针对跳转的路径进行检查
      const list = this.banner.map((banner) => {
        // 非活活动跳转直接返回
        if (banner.location_type !== JumpType.EVENT) {
          return banner
        }
        // 查找跳转的活动中是否在数据中有，如果没有该活动则会增加 disabled 字段来甄别
        if (
          activeList?.findIndex(
            (item) => Number(banner.location_value) === item?.activeId
          ) !== -1
        ) {
          return banner
        }
        return {
          ...banner,
          disabled: true
        }
      })

      return list || []
    },
    marqueeList(): HomeState['marquee'] {
      return this.marquee
    },
    getCurrentL2RType(): HomeState['galaxyGoldL2RActiveType'] {
      return this.galaxyGoldL2RActiveType
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: createMyPersistedStorage(),
        paths: ['banner', 'marquee']
      },
      {
        storage: createMyPersistedStorage(null, sessionStorage),
        paths: ['isUserCloseSuggest', 'isLongScreen', 'isShowSuggest']
      }
    ]
  },
  actions: {
    updateBanner(banner: Banner[] = []) {
      this.banner = banner.filter(({ startTime, endTime }) => {
        const now = moment().unix()
        return (
          (!startTime && !endTime) ||
          (startTime && !endTime && startTime <= now) ||
          (endTime && !startTime && endTime >= now) ||
          (startTime && endTime && startTime <= now && endTime >= now)
        )
      })
    },
    async getMarqueeList() {
      this.marquee = await apiGetMarqueeList()
    },
    setIsUserCloseSuggest(val: boolean) {
      this.isUserCloseSuggest = val
    },
    setCollapsed(val: boolean) {
      this.collapsed = val
    },
    setH5AsideShow(val: boolean) {
      this.h5AsideShow = val
    },
    setLoginPanel(flag?: boolean) {
      this.loginPanel = (flag != undefined ? flag : !this.loginPanel) as boolean
    },
    setGalaxyGoldCurrentTab(val: string) {
      this.galaxyGoldL2RActiveType = val
    },
    closeGalaxyL2RModal() {
      this.setLoginPanel(false)
    },
    setLongScreen(val: boolean) {
      this.isLongScreen = val
    },
    setIsShowSuggest(val: boolean) {
      this.isShowSuggest = val
    },
    async getMessageAll(payload: MessageAllRequest) {
      const { setNoticeList } = useNoticeStore()
      const res = await apiGetMessageAll(payload)
      if (res?.marqueeList) {
        this.marquee = res.marqueeList
      }
      if (res?.list && payload.listType && payload.listPage) {
        setNoticeList(res?.list, payload.listType, payload.listPage)
      }
      return res
    }
  }
})
