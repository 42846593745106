/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-11 15:32:14
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\rules\effective.ts
 * @Description: 上报有效性规则，根据不同的规则，判断不同的上报平台是否需要上报
 * @example:
 * const effective = await excuteEffective(
 *  [EEffecitveType.uploadBySiteCodes, EEffecitveType.filterGoEvent], {
 *    eventName: 'ResponseSystemError',
 *    uploadPlatforms: [EUploadPlatform.GO, EUploadPlatform.GA],
 *    payload: {},
 *    extParams: {}
 *  })
 */
import { EEventType, EUploadPlatform } from '../modules/type'

export enum EEffecitveType {
  filterGoEvent = 'filterGoEvent',
  filterGaEvent = 'filterGaEvent',
  filterByTime = 'filterByTime'
}

export type TConditions = {
  reportSiteCode?: Array<string | number>
  validTime?: {
    start: number
    end: number
  }
}

type TParams = {
  eventName: string
  eventType: EEventType
  payload: Record<string, unknown>
  uploadPlatforms: Array<EUploadPlatform>
  extParams: Record<string, unknown>
  conditions?: TConditions
}
export const effectiveWithFn: {
  [key in EEffecitveType]: (
    params: TParams
  ) => Array<{ [key in EUploadPlatform]?: boolean }>
} = {
  /**
   * 过滤 GO 不需要上报的场景
   * @param params
   * @returns
   */
  filterGoEvent: (params) => {
    const { uploadPlatforms, eventName } = params
    const blockEvents = ['ResponseSystemError']
    return uploadPlatforms.map((platform) => {
      if (platform !== EUploadPlatform.GO) return { [platform]: true }
      return {
        [platform]: !blockEvents.includes(eventName)
      }
    })
  },
  /**
   * 过滤 GA 不需要上报的场景
   * @param params
   * @returns
   */
  filterGaEvent: (params) => {
    const { uploadPlatforms, eventName } = params
    const blockEvents = ['']
    return uploadPlatforms.map((platform) => {
      if (platform !== EUploadPlatform.GA) return { [platform]: true }
      return {
        [platform]: !blockEvents.includes(eventName)
      }
    })
  },
  /**
   * 按时间过滤
   * @param params
   * @returns
   */
  filterByTime: (params) => {
    const { uploadPlatforms, conditions } = params
    const { validTime } = conditions || {}
    if (!validTime)
      return uploadPlatforms.map((platform) => ({ [platform]: false }))
    const now = Date.now()
    return uploadPlatforms.map((platform) => {
      return {
        [platform]: now >= validTime?.start && now <= validTime?.end
      }
    })
  }
}

/**
 * 执行所有的规则函数，并将结果合并
 * @param effectiveTypes
 * @param params
 * @returns
 */
export const excuteEffective = async (
  effectiveTypes: Array<{
    name: EEffecitveType
    conditions?: TConditions
  }>,
  params: TParams
) => {
  // 如果没有有效性规则，直接返回所有平台都需要上报
  if (effectiveTypes.length === 0) {
    return params.uploadPlatforms.reduce((acc, platform) => {
      acc[platform] = true
      return acc
    }, {} as { [key in EUploadPlatform]?: boolean })
  }
  // 执行所有的规则函数
  const promises = effectiveTypes.map((effective) =>
    effectiveWithFn[effective.name]({
      ...params,
      conditions: effective.conditions
    })
  )
  return Promise.all(promises).then((results) => {
    return mergeObjects(results)
  })
}

/**
 *
 * @param objs 合并有效性规则的结果，如果有一个为false，则返回false
 * @returns
 */
function mergeObjects(objs: { [key: string]: boolean }[][]): {
  [key: string]: boolean
} {
  const result: { [key: string]: boolean } = {}

  for (const arr of objs) {
    for (const obj of arr) {
      for (const key of Object.keys(obj)) {
        result[key] =
          result[key] === false || obj[key] === false ? false : obj[key]
      }
    }
  }

  return result
}
