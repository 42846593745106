/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-10 17:01:21
 * @LastEditors: Wallis@cg.app
 * @FilePath: \reportersdk\src\reporter\ReporterMananger.ts
 * @Description:i
 */
import { EEventType, EUploadPlatform, IUploadData } from './modules/type'
import { GameItemInter } from '../game/entity/gameItem'
import { RULES } from './rules/configs'
import { Singleton } from './utils'
import { makeRuleSuite } from './rules'
import ReporterGAImp from './modules/ReporterGAImp'
import ReporterGOImp from './modules/ReporterGoImp'
import ReporterSensorsImp from './modules/ReporterSensorsImp'

interface SearchStatus {
  key_word?: string
  result_number?: number
  gameList?: void | GameItemInter[]
}
class ReporterMananger {
  public rules = RULES
  public eventType = EEventType

  private GAInstance: ReporterGAImp
  private GOInstance: ReporterGOImp
  private SensorsInstance: ReporterSensorsImp

  constructor() {
    this.GAInstance = Singleton.getInstance('ReporterGAImp', ReporterGAImp)
    this.GOInstance = Singleton.getInstance('ReporterGOImp', ReporterGOImp)
    this.SensorsInstance = Singleton.getInstance(
      'ReporterSensorsImp',
      ReporterSensorsImp
    )
  }

  /** 记录是否搜索过后的操作 */
  public searchStatus: SearchStatus | undefined = undefined

  public updateSerachStatus(val?: SearchStatus) {
    this.searchStatus = val
  }
  /**
   * 上报数据
   * @param params
   */
  public async doReport(params: IUploadData) {
    const ruleSuite = await makeRuleSuite(params)
    const effective = ruleSuite.effective
    if (effective[EUploadPlatform.GO] === true) {
      this.GOInstance.action(ruleSuite)
    }
    if (effective[EUploadPlatform.GA] === true) {
      this.GAInstance.action(ruleSuite)
    }
    if (effective[EUploadPlatform.SENSORS] === true) {
      this.SensorsInstance.action(ruleSuite)
    }
  }
}

export default ReporterMananger
