import { Button } from '@/plugins/ant-design-vue/tsx-support'
import {
  ChannelStatusList,
  IsForceDownloadList,
  RewardsCondition,
  UserDisplayList,
  ViewDownloadList
} from '@/views/channel/const'
import { CommonPopupH5 } from '@/components/business-components'
import { Component, Mixins, Ref } from '@/vue-property-decorator'
import {
  GlobalEventActions,
  GlobalEventActionsTypeEnum
} from '@/context/events'
import { GlobalEvents } from '@/context'
import { LoginOsType } from './const'
import { Toast } from 'vant'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { useChannelStore } from '@/views/channel/store'
import { useDownloadAppStore } from '@/layouts/components/downloadApp/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BaseMixins from './mixin/index'
import BusinessUtils, { windowOpen } from '@/utils/business-utils'
import ClipboardJS from 'clipboard'
import TooltipsComp from '@/components/business-components/tooltips'
import isMobile from '@/utils/isMobile'
import style from './channelRequireForce.module.scss'
type AppNameLanguage = {
  key: string
  value: string
}
type State = {
  /**是否展示下载app弹出框 */
  showDownloadApp: boolean
}
/**通过渠道配置要求强制开启APP提示触发该弹出框 */
@Component<DownloadAppForceByChannel>({ name: 'DownloadAppForceByChannel' })
export default class DownloadAppForceByChannel extends Mixins(BaseMixins) {
  @Ref()
  acccountRef!: HTMLDivElement
  state: State = {
    showDownloadApp: false
  }
  private handleDownloadApp(action: GlobalEventActions) {
    if (
      action.type ===
      GlobalEventActionsTypeEnum.DOWNLOAD_APP_REQUIRE_FORCE_BY_CHANNEL
    ) {
      this.setState({
        showDownloadApp: true
      })
      this.copyAccount()
    }
  }

  /**
   * @description 复制当前登录的账号
   */
  private copyAccount() {
    const innerCopyAccount = () => {
      const copyUrl = new ClipboardJS(this.acccountRef)

      copyUrl.on('success', () => {
        Toast.success('成功文案')
      })
      copyUrl.on('error', () => {
        Toast.fail('失败文案')
      })
    }
    this.$nextTick(innerCopyAccount)
  }

  created() {
    this.getDownloadConfig()
  }

  private getDownloadConfig() {
    useDownloadAppStore().getDownloadConfig(this.getDownloadConfig)
  }

  mounted() {
    GlobalEvents.subscribe(this.handleDownloadApp)
  }

  beforeDestroy() {
    GlobalEvents.unsubscribe(this.handleDownloadApp)
  }

  /**
   * 是否展示领取奖励条件的文案
   * 条件为
   * 1.展示为全部会员
   * 2.领取条件为首充会员
   */
  private get isShowRewardsConditionLiteral() {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return false
    }
    const { userDisplay, rewardsCondition } = channelInfo
    const { AllUser } = UserDisplayList
    const { FirstDeposit } = RewardsCondition
    if (userDisplay === AllUser && rewardsCondition === FirstDeposit) {
      return true
    }
    return false
  }

  /**没有在APP内登录过 */
  private static get isNotLoginApp() {
    const { userInfos } = useMainStore()
    return userInfos?.loginOsType === LoginOsType.NotLoginApp
  }

  /**是否展示下载APP弹出框来自渠道 */
  private static get showDownloadAppByChannel() {
    const { channelInfo } = useChannelStore()
    const { isFirstDeposit } = useMainStore()
    if (!channelInfo) {
      return false
    }

    const { downloadDisplay, userDisplay, viewDownload, isForceDownload } =
      channelInfo
    const { Open } = ChannelStatusList
    const { AllUser } = UserDisplayList
    const { APP } = ViewDownloadList
    const { Yes } = IsForceDownloadList
    const openPartialCondition =
      downloadDisplay === Open &&
      viewDownload === APP &&
      isForceDownload === Yes

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 展示的用户群体为全部
     */
    if (openPartialCondition && userDisplay === AllUser) {
      return true
    }

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 用户的充值次数大于或者大于1次（仅限已首充会员）
     */
    if (openPartialCondition && isFirstDeposit) {
      return true
    }

    return false
  }
  /**是否能够打开下载APP的提示通过渠道配置 */
  static get canOpenDownloadAppByChannel() {
    const { hiddenDownload } = useMainStore()
    return (
      this.isNotLoginApp && !hiddenDownload && !!this.showDownloadAppByChannel
    )
  }

  get onlineCustomers() {
    return useMainStore().serviceInformation?.customer.onlineCustomers ?? []
  }

  get customerUrl() {
    if (!this.onlineCustomers.length) {
      return ''
    }
    return this.onlineCustomers[0].customerUrl
  }

  /**获取下载APP的格式化的金额-通过渠道配置 */
  private get formatDownloadAppRewardByChannel() {
    const { userInfos, downloadAppRewardByChannel } = useMainStore()
    const formatedDownloadAppRewardByChannel = currencyFormatter(
      downloadAppRewardByChannel || 0,
      {
        code: userInfos?.currency,
        precision: 2,
        symbol: ''
      }
    )
    return formatedDownloadAppRewardByChannel
  }

  /**
   * @description
   * 将国际化语言扁平化数组[{key: 'pt,th,vi', value: 'aaa'}] => [{key: 'pt', value: 'aaa'}, {key: 'th', value: 'aaa'}, {key: 'vi', value: 'aaa'}]
   *
   */
  private flattenAppNameLanguage(appNameLanguage: Array<AppNameLanguage>) {
    const flattenAppNameLanguage = appNameLanguage.reduce(
      (appNameLanguageList, appNameLanguage) => {
        const { key, value } = appNameLanguage
        const keyList = key.split(',')
        if (keyList.length === 1) {
          appNameLanguageList = appNameLanguageList.concat({
            key: key,
            value: value
          })
        } else {
          keyList.forEach((_key) => {
            appNameLanguageList = appNameLanguageList.concat({
              key: _key,
              value: value
            })
          })
        }

        return appNameLanguageList
      },
      [] as Array<AppNameLanguage>
    )
    return flattenAppNameLanguage
  }

  /**APP的名称 */
  private get appName() {
    const { channelGlobalConfig, language } = useMainStore()
    if (!channelGlobalConfig) {
      return ''
    }
    const { appNameLanguage } = channelGlobalConfig
    if (!appNameLanguage) {
      return ''
    }
    const flattenedAppNameLanguage =
      this.flattenAppNameLanguage(appNameLanguage)
    const currentAppNameLanguage = flattenedAppNameLanguage.find(
      (_appNameLanguage) => language.includes(_appNameLanguage.key)
    )

    if (!currentAppNameLanguage) {
      return ''
    }
    return currentAppNameLanguage.value
  }

  private get getUsername() {
    return useMainStore().userInfos?.platfromid ?? ''
  }

  private get guildInstallAppContentByiOS() {
    const { t } = useI18n()
    return (
      <div class={style.guildContent}>
        <h3 class={style.guildTitle}>{t('lobby.rightBar.installGuild')}</h3>
        <p class={style.guildDetail}>
          {t('lobby.rightBar.installDetail1ByiOS')}
        </p>
        <p class={style.guildDetail}>
          {t('lobby.rightBar.installDetail2ByiOS')}
        </p>
        <p class={[style.guildDetail, style.wrapperSetting]}>
          <span>{t('lobby.rightBar.installDetail3ByiOS')}</span>
          <my-img
            class={style.setting}
            src={'/lobby_asset/common/common/home/img_ios_setting.png'}
          />
          <span>{t('lobby.rightBar.installDetail4ByiOS')}</span>
        </p>
        <p class={style.guildDetail}>
          {t('lobby.rightBar.installDetail5ByiOS', [this.appName])}
        </p>
      </div>
    )
  }

  private get guildInstallAppContentByAndroid() {
    const { t } = useI18n()
    return (
      <div>
        <div class={style.guildContent}>
          <h3 class={style.guildTitle}>{t('lobby.rightBar.installGuild')}</h3>
          <p class={style.guildDetail}>
            {' '}
            {t('lobby.rightBar.installDetail1ByAndroid')}
          </p>
          <p class={style.guildDetail}>
            {t('lobby.rightBar.installDetail2ByAndroid')}
          </p>
          <p class={style.guildDetail}>
            {t('lobby.rightBar.installDetail3ByAndroid', [this.appName])}
          </p>
        </div>
        <div class={style.appMarketAuth}>
          <icon-sprite sprite={createSvgSprite('comm_icon_zyts')} />
          <p>{t('lobby.rightBar.installDetail4ByAndroid')}</p>
        </div>
      </div>
    )
  }

  private get guildInstallAppContent() {
    const isApple = isMobile().apple.device
    if (isApple) {
      return this.guildInstallAppContentByiOS
    } else {
      return this.guildInstallAppContentByAndroid
    }
  }

  render() {
    const { t } = useI18n()
    const { downloadAppRewardByChannel, isShowDownloadRewardsByChannel } =
      useMainStore()
    const formatDownloadAppRewardByChannel =
      this.formatDownloadAppRewardByChannel

    const { channelGlobalConfig } = useMainStore()
    const { urlslist } = useDownloadAppStore()

    return (
      <CommonPopupH5
        closeOnClickOverlay={false}
        value={this.state.showDownloadApp}
        position="bottom"
        class={style.wrapperPopup}
      >
        <div class={style.wrapperChannel}>
          <div class={style.wrapperBg}>
            <my-img
              class={style.bg1}
              src={'/lobby_asset/common/common/home/img_xzap_hj.png'}
            />
            <div class={style.bg2}>
              <icon-sprite sprite={createSvgSprite('img_xzap_hj2')} />
            </div>
          </div>
          <div class={style.innerWrapper}>
            <div class={style.titleFromChannel}>
              <icon-sprite sprite={createSvgSprite('comm_icon_zyts')} />
              <span class={style.title}>
                {t('lobby.rightBar.titleFromChannel')}
              </span>
            </div>
            <div class={style.wrapperTip}>
              <b class={style.versionUpdate}>
                {t('lobby.rightBar.versionUpdate')}
              </b>
              <span style={style.pleaseInstallApp}>
                {t('lobby.rightBar.downloadAndInstall')}
              </span>
              <span
                class={style.account}
                ref={'acccountRef'}
                data-clipboard-text={this.getUsername}
              >
                <b>{this.getUsername}</b>
                <icon-sprite sprite={createSvgSprite('style_1_icon_copy')} />
              </span>
              {!!downloadAppRewardByChannel && isShowDownloadRewardsByChannel && (
                <span class={style.award}>
                  {t('lobby.rightBar.getAward')}
                  <b class={style.amount}>{formatDownloadAppRewardByChannel}</b>
                  {this.isShowRewardsConditionLiteral &&
                    t('lobby.rightBar.channelTip5')}
                </span>
              )}

              {!!this.customerUrl && (
                <span class={style.service}>
                  {t('lobby.rightBar.contactService')}
                  <span
                    onClick={() => windowOpen(this.customerUrl)}
                    class={style.innerService}
                  >
                    <b>{t('lobby.rightBar.contactService1')}</b>
                    <icon-sprite sprite={createSvgSprite('icon_dt_1kf')} />
                  </span>
                </span>
              )}
            </div>
            <div class={style.appShowIcon}>
              <div class={style.friendTip}>
                {t('lobby.rightBar.confirmApp')}
              </div>
            </div>

            <div class={style.wrapperIcon}>
              <my-img src={channelGlobalConfig?.appIcon ?? ''} />
              <p class={style.literal}>{this.appName}</p>
            </div>
            <div
              class={style.wrapperUrlslistByChannel}
              ref={'downloadBtnRef'}
              data-clipboard-text={this.getClipBoardParams}
            >
              {urlslist?.map((item, index) => {
                let count = 0
                const appVerson = this.getAppVersion(item)
                return (
                  <Button.Tsx
                    key={item.name}
                    type="primary"
                    ghost={!(index % 2)}
                    class={style.downloadItem}
                    onClick={() => {
                      this.handlerDownloadBtnByForceDownload(item, count)
                      count++
                      this.postApiNetstatPlayerAdInfo()
                    }}
                  >
                    {!!downloadAppRewardByChannel &&
                      isShowDownloadRewardsByChannel && (
                        <TooltipsComp
                          charge={1}
                          top={BusinessUtils.px2rem(-20)}
                          text={formatDownloadAppRewardByChannel}
                        />
                      )}
                    {isMobile().apple.device ? (
                      <icon-sprite
                        class={style.appIcon}
                        sprite={createSvgSprite('comm_icon_xz-ios')}
                      />
                    ) : (
                      <my-img
                        class={style.appImg}
                        src={
                          '/lobby_asset/common/web/home/comm_icon_xz-android.png'
                        }
                      />
                    )}

                    <span class={style.appName}>
                      V{appVerson}
                      {item.name}
                    </span>
                  </Button.Tsx>
                )
              })}
            </div>
            {this.guildInstallAppContent}
          </div>
        </div>
      </CommonPopupH5>
    )
  }
}
