import '@/styles/index.scss'
import { PiniaVuePlugin, createPinia } from 'pinia'
import DisableCopyParseDirective from '@/plugins/disable-copy-parse'
import Fastclick from 'fastclick'
import InnerText2Title from '@/plugins/inner-text-to-title'
import PerfectScrollbar from '@/plugins/perfect-scrollbar'
import PortalVue from 'portal-vue'
import Swiper from '@/plugins/swiper'
import VueMeta from 'vue-meta'
import piniaPersist, { indexDB } from '@/plugins/pinia-plugin-persist'
import router from '@/router'
import vClickOutside from 'v-click-outside'

import { GlobalEvents, GlobalManager, GlobalService } from '@/context'
import App from '@/App'
import VueRouter from 'vue-router'

import { loadScripts } from '@/utils/Loader'
import { setLogger } from 'typox'
import { trace } from '@/context/tacker'
import ApngCanvas from '@/components/apng-canvas'
import AutoText from '@/components/auto-text'
import CheckView from '@/components/check-view'
import CountTo from '@/components/count-to'
// import Fragment from 'vue-fragment'
import IconSprite from '@/components/icon-sprite'
import InnerHTML from '@/components/inner-html'
import MyAdapter from '@/components/adapter'
import MyEmpty from '@/components/my-empty'
import MyImg from '@/components/img'
import MyScroll from '@/components/my-scroll'
import MyTitle from '@/components/my-title'
import PageVisibility from '@/utils/Visibility'
import Preload from '../../controller/Preload'
import Vue from 'vue'
import type { VueConstructor } from 'vue'

const isProd = process.env.NODE_ENV !== 'development'

// 重写vue警告
Vue.config.warnHandler = function (msg, vm, trace) {
  // 忽略掉 InjectReactive 没有找到父极注入时,抛出的警告
  // 注:(该警告在有默认值的时候不应该再被抛出,仍抛出是 vue-property-decorator 的bug,因此我们选择屏蔽它)
  if (msg === 'Injection "__reactiveInject__" not found') {
    return
  }
  // 其他情况下，使用默认的警告处理
  console.error(msg, trace)
}
/**
 * 全局注册页面离开和激活的事件
 */
const pageVisibility = new PageVisibility()
pageVisibility.visibilityChange((visibility) => {
  GlobalEvents.dispatch({
    type: 'PAGE_VISIBILITY_CHANGE',
    payload: {
      visibility
    }
  })
})

Fastclick.attach(document.body)

setLogger({
  error: (message: string) => {
    console.error(message)
    // 指定环境开启提示
    if (['080', '024', '025'].includes(window.LOBBY_SITE_CONFIG?.siteCode)) {
      GlobalEvents.dispatch({
        type: 'RESPONSE_ERROR',
        payload: { message }
      })
    }
  }
})

/**
 * 加载Vue相关
 */
const initVue = async (Vue: VueConstructor<Vue>) => {
  const pinia = createPinia()
  pinia.use(piniaPersist)
  await indexDB.setup({
    recordIndexDBKeyInLocalStorage: `web.lobby.indexDBKey`
  })

  Vue.use(vClickOutside)
    // .use(Fragment.Plugin)
    .use(PortalVue)
    .use(Swiper)
    .use(InnerText2Title)
    .use(DisableCopyParseDirective)
    .use(PerfectScrollbar, { wheelPropagation: true, wheelSpeed: 0.4 })
    .use(PiniaVuePlugin)
    .use(VueMeta)
    .use(VueRouter) // <--- MUST BE AFTER Vue.use(PiniaVuePlugin)
    .use({
      /**
       * 按需加载三方组件
       */
      install() {
        Vue.component('VueQr', () => import('vue-qr'))

        Vue.component('LivePlayer', async () => {
          await loadScripts([
            {
              src: '/libs/liveplayer@2.7.8/dist/component/liveplayer-lib.min.js'
            },
            {
              src: '/libs/liveplayer@2.7.8/dist/component/liveplayer-component.min.js'
            }
          ])
          return (
            window as unknown as {
              LivePlayer: {
                default: unknown
              }
            }
          ).LivePlayer.default
        })
      }
    })
    .use({
      install() {
        const FragmentName = 'fragment'
        const Fragment = Vue.extend({
          name: FragmentName,
          render(h) {
            return h(
              'div',
              {
                style: {
                  display: 'contents'
                }
              },
              this.$slots.default
            )
          }
        })

        Vue.component(FragmentName, Fragment)
        Vue.component('MyImg', MyImg)
        Vue.directive('MyImg', MyImg.Directive)
        Vue.component('InnerHtml', InnerHTML)
        Vue.component('IconSprite', IconSprite)
        Vue.component('ApngCanvas', ApngCanvas)
        Vue.component('MyScroll', MyScroll)
        Vue.component('CountTo', CountTo)
        Vue.component('MyAdapter', MyAdapter)
        Vue.component('MyTitle', MyTitle)
        Vue.component('AutoText', AutoText)
        Vue.component('CheckView', CheckView)
        Vue.component('MyEmpty', MyEmpty)
      }
    })
  Vue.config.productionTip = false

  const preErrorHandler = Vue.config.errorHandler
  Vue.config.errorHandler = (err: Error, vm: Vue, info) => {
    preErrorHandler?.(err, vm, info)
    err && trace('vue-error', { message: err.message, vm: vm?.$options?.name })
  }

  /**
   * 异步加载国际化内容
   */
  const i18n = await GlobalManager.I18n.create()
  /**
   * 创建Vue实例，初始化数据在router的拦截中处理
   */
  GlobalManager.App = new Vue({
    router,
    i18n,
    pinia,
    render: (h) => h(App)
  }).$mount('#app')

  if (window.LOBBY_SITE_CONFIG.SITE_DOMAIN_NOT_MATCH) {
    GlobalManager.Modal.create({
      content: () =>
        i18n.t('lobby.common.errorMessages.DOMAIN_NOT_MATCH') ||
        'The domain is accessed, please try again later',
      okText: (i18n.t('lobby.common.tips.confirm') as string) || 'OK',
      cancelText: '',
      onOk: async () => {
        window.location.reload()
      },
      cancelButtonProps: {
        style: {
          display: 'none'
        }
      }
    })
  }

  GlobalService.cache.setup()
  trace('init', {
    html: window.LOBBY_SITE_CONFIG.LOG,
    version: (() => {
      const time = document.querySelector('html')?.getAttribute('data-version')
      if (!time) return null
      try {
        return +new Date(time)
      } catch (error) {
        return null
      }
    })()
  })
}

export const events = GlobalEvents.subscribe((actions) => {
  switch (actions.type) {
    case 'LOADED': {
      const { Vue } = actions.payload
      initVue(Vue)
      break
    }

    case 'CLIENT_DATA_INITIALIZATION_COMPETED': {
      // 生产环境开启预先加载处理
      if (isProd) {
        try {
          new Preload()
        } catch (e) {}
      }
      break
    }

    default:
      break
  }
})
