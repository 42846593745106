import {
  Collapse,
  CollapseItem,
  DatetimePicker,
  Empty,
  Grid,
  GridItem,
  List,
  NoticeBar,
  Picker,
  Popover,
  Popup,
  Slider,
  Sticky,
  Tab,
  Tabbar,
  TabbarItem,
  Tabs
} from 'vant'
import { merge } from 'lodash'
import Vue from 'vue'
export type ExtractClassProps<T> = Partial<
  Omit<T, keyof Vue | 'style' | 'class'>
>

const DateTimePickerTsx = merge(DatetimePicker, {})
const PopupTsx = merge(Popup, {})
const TabsTsx = merge(Tabs, {})
const TabTsx = merge(Tab, {})
const TabbarTsx = merge(Tabbar, {})
const TabbarItemTsx = merge(TabbarItem, {})
const GridTsx = merge(Grid, {})
const GridItemTsx = merge(GridItem, {})
const EmptyTsx = merge(Empty, {})
const ListTsx = merge(List, {})
const NoticeBarTsx = merge(NoticeBar, {})
const PopoverTsx = merge(Popover, {})
const CollapseTsx = merge(Collapse, {})
const CollapseItemTsx = merge(CollapseItem, {})
const StickyTsx = merge(Sticky, {})
const PickerTsx = merge(Picker, {})
const SliderTsx = merge(Slider, {})

export {
  DateTimePickerTsx as DatePicker,
  PopupTsx as Popup,
  TabsTsx as Tabs,
  TabTsx as Tab,
  TabbarTsx as Tabbar,
  TabbarItemTsx as TabbarItem,
  GridTsx as Grid,
  GridItemTsx as GridItem,
  EmptyTsx as VantEmpty,
  ListTsx as VantList,
  NoticeBarTsx as VantNoticeBar,
  PopoverTsx as Popover,
  CollapseTsx as Collapse,
  CollapseItemTsx as CollapseItem,
  StickyTsx as Sticky,
  PickerTsx as Picker,
  SliderTsx as VantSlider
}
