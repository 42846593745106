/** 游戏顶的类型 */
export enum _GameItemType {
  /** 平台 */
  Platform = 1,
  /** 普通游戏 */
  NormalGame = 2
}

/** 游戏项的接口 */
export interface _GameItemInter {
  /** web端使用的唯一id */
  webId: string
  /** 游戏 id */
  gameId: number
  /** 游戏名称 */
  gameName: string
  /** 游戏图标 */
  gameIcon: string
  /** 自定义图片 0-否, 1-是  */
  isCustomIcon: boolean
  /** 是否美术上传的原始图片 0-否, 1-是 */
  isOriginImg: boolean
  /** 维护状态 0-否, 1-是  */
  maintainStatus: boolean
  /** 是否推荐 0-否, 1-是  */
  recommendStatus: boolean
  /** 是否支持试玩 0-否, 1-是  */
  demoModeSupport: boolean
  /** 屏幕方向: 0=横屏, 1=竖屏  */
  screenDirection: boolean
  /** 是否主播号: 0-否, 1-是 */
  anchorSupport: boolean
  /** 游戏类别  */
  gameCategoryId: number
  /** 游戏平台ID
   * 注: 方型卡片,需要特殊判断platformId,如果是13(WG)平台,则要进行logo的拼接,其它平台全都是整图,则没有这个问题.
   */
  platformId: number
  /** 热门类型 1=平台 2=游戏 */
  gameType: _GameItemType
  /** 平台名称 */
  platformName: string
  /** 平台logo */
  platformLogo: string
  /** 游戏打开方式 0-内嵌, 1-外链 */
  isExternalLink: boolean
  /** 最小准入余额 */
  minimumEntryBalance: number
  /** 是否三合一图(图片/logo/背景色) 0-否，1是
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  isCombinate: boolean
  /** (5159特殊方型) 方形图标
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  squareGameIcon: string
  /** (5159特殊方型) 是否为自定义图片 0-否, 1-是 */
  isSquareCustomIcon: boolean
  /** (5159特殊方型)  是否方形三合一图片 (图片/logo/背景色) 0-否，1是
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  isSquareCombinate: boolean
  /** (5159特殊方型) 是否美术上传的原始图片 0-否, 1-是 */
  isSquareOriginImg: boolean
  /** 是否优先展示热门图标(该项为true,不考虑别的条件,直接展示热门图标,它的优先级最高,该项为false,也有可能因为其它条件展示热门图标)
   *  [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
   */
  isDisplayHotIcon: boolean
}
