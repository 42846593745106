/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-11 15:36:12
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\rules\index.ts
 * @Description:
 */

import { EEventType, EStategyType, IUploadData } from '../modules/type'
import { RULES } from './configs'
import { excuteEffective } from './effective'
import { getExtParams } from './params'

/**
 * 生成规则套件，用于添加附加参数，判断有效性，选择上报策略
 * @param data
 * @returns
 */
export const makeRuleSuite = async (data: IUploadData) => {
  const { eventName } = data
  const rule = data.rule || RULES.COMMON_ALL
  const payload = data.payload || {}
  const extParams = await getExtParams(rule.params || [])
  const effective = await excuteEffective(rule.effective || [], {
    uploadPlatforms: rule.uploadPlatforms,
    eventName,
    eventType: rule.eventType || EEventType.DEFAULT,
    payload,
    extParams
  })
  return {
    eventName: data.eventName,
    eventType: rule.eventType || EEventType.DEFAULT,
    payload: payload,
    strategy: rule.strategy || { name: EStategyType.default },
    extParams,
    effective
  }
}
