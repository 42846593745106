/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-10 15:55:20
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\ReporterSensorsImp.ts
 * @Description: 使用 神策上报
 */
import { EUploadPlatform, TUploadPayload } from './type'
import { windowConfig } from '@/utils/window'
import ReportAbst from './ReporterAbst'
import siteConfig from '@/config/site.config'

const ENV_VARS = [
  {
    env: 'dev',
    address: 'https://apiup-cf.cbfes.com/debug'
  },
  {
    env: 'test',
    address: 'https://apiup-cf.cbfes.com/debug',
    allowSites: ['2558']
  },
  {
    env: 'stest',
    address: 'https://apiup-cf.cbfes.com/debug'
  },
  {
    env: 'try',
    address: 'https://apiup-cf.cbfes.com/debug'
  },
  {
    env: 'prod',
    address: 'https://apiup-cf.cbfes.com/cfcdn',
    allowSites: ['296', '230,', '5050']
  }
]
export class ReporterSensorsImp extends ReportAbst {
  constructor() {
    super(EUploadPlatform.SENSORS)
    this.init()
  }

  protected init(): void {
    /** 抓出当前环境 */
    const currentSiteInfo = ENV_VARS.find((el) => {
      return el.env === process.env.ENVIRONMENT
    })
    if (!currentSiteInfo?.allowSites?.includes(siteConfig?.siteCode)) {
      return
    }
    /** 载入脚本 */
    const script = document.createElement('script')
    script.id = 'script-Sensors'
    script.async = true
    script.onload = () => this.onloaded(currentSiteInfo)
    script.src = `https://cdn.jsdelivr.net/npm/sa-sdk-javascript@latest/sensorsdata.min.js`
    document.head.appendChild(script)
  }

  /** 确认脚本载入后执行初始、注册 */
  private onloaded(currentSiteInfo) {
    const sensors = window['sensorsDataAnalytic201505']
    sensors.init({
      server_url: currentSiteInfo?.address,
      is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
      use_client_time: true,
      send_type: 'beacon',
      heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'default',
        //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect'
      }
    })
    /** 注册公共属性 */
    sensors.registerPage({
      platform: windowConfig.package.isVest
        ? 'vest'
        : windowConfig.package.isLite
        ? 'lite'
        : 'h5',
      version: process.env.APP_VERSION || process.env.ENVIRONMENT,
      siteCode: siteConfig?.siteCode
    })
    sensors.quick('autoTrack') //用于采集 $pageview 事件。
  }

  public upload(data: TUploadPayload): void {
    // 其他需要封装的参数，写这里 * 仅封装该上传渠道特定的参数，非公共参数
    try {
      const sensors = window['sensorsDataAnalytic201505']
      sensors?.track(data.eventName, {
        ...data.payload
      })
    } catch (error) {
      console.error('upload error: ', error)
    }
  }
}

export default ReporterSensorsImp
