/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-10 15:55:20
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\ReporterGAImp.ts
 * @Description: 使用 GA 上报错误
 */
import { EUploadPlatform, TUploadPayload } from './type'
import ReporterAbst from './ReporterAbst'

export class ReporterGAImp extends ReporterAbst {
  constructor() {
    super(EUploadPlatform.GA)
  }

  protected init(): void {
    // 初始化GA
    // eslint-disable-next-line
    console.info('GA init')
  }

  public upload(data: TUploadPayload): void {
    // 其他需要封装的参数，写这里 * 仅封装该上传渠道特定的参数，非公共参数
    const payload = {
      ...data.payload,
      ...data.extParams
    }
    // eslint-disable-next-line
    console.info('GA upload', payload)
    // window?.MonitoringAnalysis?.gtag('event', data.eventName, payload)
  }
}

export default ReporterGAImp
