import localforage from 'localforage'
export const IndexDb = (eruda: import('eruda').Eruda) => {
  class IndexDB extends eruda.Tool {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $el!: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $devtools!: any
    constructor() {
      super()
      this.name = 'IndexDB'
    }

    showSources(type: string, data: unknown) {
      const sources = this.$devtools.get('sources')
      if (!sources) return
      sources.set(type, data)
      this.$devtools.showTool('sources')
      return true
    }

    bindEvent() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$el.on('click', '.show-detail', async (event: any) => {
        const key = event.curTarget.innerHTML
        const val = (await localforage.getItem(key)) as string
        try {
          this.showSources('object', JSON.parse(val))
        } catch (e) {
          this.showSources('raw', val)
        }
      })
    }

    async createHtml() {
      let html = `<table>`
      await localforage
        .iterate(function (value, key) {
          html += `<tr><td class="show-detail">${key}</td></tr>`
        })
        .then(function () {
          html += `</table>`
        })
        .catch(function () {
          /**
           */
        })
      this.$el.html(html)
    }

    init(...rest: unknown[]) {
      this.$el = rest[0]
      this.$devtools = rest[1]
      this.createHtml()
      this.bindEvent()
      super.init(this.$el)
    }
    show() {
      super.show()
      this.createHtml()
      return this
    }
  }
  return new IndexDB()
}
