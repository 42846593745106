import {
  AccountType,
  AuditModeType,
  BindType,
  CommonSwitchStatus,
  ContryInfos,
  CurrencyInfos,
  EventCategoriesAvailableEnum,
  GAStatus,
  IpBindInfos,
  LargeAmountMustBind,
  LinkType,
  MUST_BIND_ITEM,
  MaintainType,
  OfficialChannelConfig,
  OptResult,
  RegisterValidateRequired,
  RewardBindData,
  RewardBindType,
  SecondAuthType,
  SecurityVerify,
  SmsCountry,
  SystemInfos,
  ThirdWayStatus,
  UserInfos
} from '@/api/common/type'
import {
  ActivityTemplate,
  ReceiveAwardAllSwitchEnum,
  RechargeFundConfigItem,
  ReturnGoldConfigCurrencyEnum,
  ReturnGoldConfigItem,
  ReturnGoldSwitchEnum,
  UserLevelEnum,
  VipConfig,
  YueBaoConfig
} from '@/api/event/type'
import { AntLocale } from '@/plugins/ant-design-vue/type'
import { ApiVipSwitchStatusResponse } from '@/api/vip/type'
import {
  AutoFillData,
  ChangePasswordWs,
  CurrentPageTabs,
  DefaultActivesAuthInfos,
  MainState,
  PermissionOperation,
  ScreenSize
} from './type'
import { AxiosError } from 'axios'
import { BrandLogoInfosRes } from '../../api/common/type'
import { CurrencySymbol } from '@/config/currencies.config'
import { DemoLevel } from '@/views/service/type'
import { FrameStorageType } from '@/views/global-modal/modal/force-popup/types'
import {
  FrameType,
  mapFieldLocalStorage
} from '@/views/global-modal/modal/force-popup/const'
import {
  GlobalConst,
  GlobalCrypto,
  GlobalEvents,
  GlobalManager,
  GlobalService
} from '@/context'
import { IpCheck } from '@/service/site/entity/site'
import { LongConnectionEvent } from '@/controller/LongConnection'
import {
  RedPocketPollingCountEnum,
  RedPocketPollingTimeEnum
} from '@/api/redPocket/type'
import { RouteName } from '@/router/config'
import { TabsEnum } from '@/views/center/security/const'
import { ThemeCssVar } from '@/config/theme.config'
import { ThirdWayType } from '@/controller/ThirdPartyLogin/types'
import { ValidateConfig, VerifyScene } from '@/api/center/security/type'
import { apiActiveIsShowV2, apiEventAndDiscountsRedDot } from '@/api/event'
import {
  apiGetActiveRewardBind,
  apiGetActiveRewardBindBeforeLogin,
  apiGetBrandLogoInfos
} from '@/api/common'
import { apiServiceListV2 } from '@/api/service'
import { apiUserVipDetails, apiVipDetails } from '@/api/vip'
import { createRangeStr } from '@/controller/Rules'
import { defaultUrlQueryParams } from '@/context/const'
import { defineStore } from 'pinia'
import { disabledModal, getStorage } from '@/utils/Tool'
import { gameOpener } from '@/views/game/services'
import { getOsType } from '@/utils/loginRegister'
import { judgeIsForbidPopByPageAddress } from '@/views/global-modal/modal/common/util/judgeIsForbidPopByPageAddress'
import { merge } from 'lodash'
import { useChannelStore } from '@/views/channel/store'
import { useEventStore } from '@/views/event/store'
import { useGameStore } from '@/views/game/store'
import { useRedPocketStore } from '@/views/redPocket/store'
import { useSecurity } from '@/views/center/security/store'
import { useServiceStore } from '@/views/service/store'
import { useSmartPayStore } from '@/views/global-modal/modal/smart-pay/store'
import { useTaskPageStore } from '@/views/task/store/taskPageStore'
import { useTokenStore } from '../token'
import { useVipStore } from '@/views/reward/store'
import { v4 } from 'uuid'
import { windowConfig } from '@/utils/window'
import BusinessUtils, { createMyPersistedStorage } from '@/utils/business-utils'
import LongConnection from '@/controller/LongConnection'
import Modal from '@/controller/Modal'
import Polling from '@/controller/Polling'
import User from '@/controller/User'
import audioFile from '@/assets/audio/receive.mp3'
import autoModalManager from '@/context/autoModalManager/index'
import locale, { I18nMessages, Language, useI18n } from '@/i18n'
import moment from 'moment'
import router from '@/router'
import siteConfig, { defaultBaseUrl } from '@/config/site.config'
import to from 'await-to-js'
import usePromoteStore from '@/views/promote/store'
import type { LoginType } from '@/controller/User'

export const useMainStore = defineStore('app', {
  state: (): MainState => ({
    hasClickButtonSaveShortCutByChannel: false,
    /**记录下当前版本号成功引导用户刷新次数 */
    versionReloadRecord: {} as Record<string, number>,
    skinAssetsHash: {} as Record<string, string>,
    /**历史原因，之前是使用的noSaveShortcutTip这个属性，如果没有设置过就是false, 只要设置过就是返回'true' */
    hasClickButtonSaveShortCut: !!localStorage.getItem('noSaveShortcutTip'),
    downloadReward: {} as RewardBindData,
    sabaMaintenance: false,
    routerViewWithTransitionModeOnCurrentChange: null,
    routerViewWithTransitionMode: 'Base',
    /**
     * 收到维护公告后记录维护的时间，供到点后去做检测是否需要弹维护
     */
    maintainTimeInfo: null,
    ipCheck: new IpCheck(),
    isUserKeyFromUrlFail: false,
    defaultUrlQueryParams,
    /**
     * 当前的主题数据信息
     */
    currentTheme: {} as ThemeCssVar,
    responseHeaders: {},
    isSystemStatusLoading: false,
    isBannerLoading: false,
    areaStatus: true,
    siteMaintenanceStatus: false,
    rootKey: v4(),
    osType: getOsType(),
    allPngSprite: null,
    isInitializationCompleted: false, //是否初始化完毕
    isFetchingPrize: false, //是否正在更新分数奖励
    geetestRestrictedTime: 0, //极验限制操作的时间
    uuid: v4(), //浏览器层面的设备id
    rememberPwd: true, //控制是否自动填充密码
    timeDiffs: [], //服务器时间差集合
    language: 'zh_CN', //前端语言标识
    siteConfig, //站点配置信息
    siteURLInfos: null, //站点url信息
    urltime: 0,
    siteInfos: null,
    serviceInformation: null, //客服资讯
    systemInfos: null,
    autoFillSignKey: 'tgJt2ol!^E)E8(%P',
    autoFillData: '',
    lastLoginedUsername: '',
    userInfos: null, // 用户基础信息
    userKeyForFastLogin: '', //快速登录的key
    skinName: 'blue', //当前的主题名
    walletDownloadUrl: undefined,
    baseURL: defaultBaseUrl, // 全局api/游戏新开窗口地址
    bestBaseURL: '', // 最优的api请求域名
    ipBindInfos: null, //IP绑定信息
    linkType: null, // 推广链接跳转类型
    vipSwitchStatus: [], //vip系统开关
    vipInfos: null, // 用户详细信息
    vipDetails: null, //vip详情
    scrollLock: false, // 全局滚动锁
    brandLogoInfos: (() => {
      const { data, health } =
        BusinessUtils.parseInjectData<'apiGetOptimizationSiteConfig'>(
          'apiGetOptimizationSiteConfig'
        )
      if (data?.brandLogoUse) {
        data.brandLogoUse._isFromService = true
      }
      return health && data?.brandLogoUse ? data.brandLogoUse! : null
    })(),
    /**下载配置数据来自站点数据配置 */
    channelGlobalConfig: null,
    frameBoxInfos: [], // 弹窗信息
    rewardBindData: [], //奖励绑定信息
    globalModalManager: null, //全局弹层流程管理
    checkNewBenefits: false, // 选中新人福利当次不再提示
    checkDailyTask: false, //检测是否选中每日任务不再提示
    checkWeeklyTask: false, //检测是否选中每周任务不再提示
    checkEvery3DayTask: false, //检测是否选中每三日神秘任务不再提示
    isNewBenefitsInSaveUrl: false,
    checkNeverNewBenefits: false, // 选中新人福利永不再提示
    isNoticePopupFinish: false, // 通知公告是否执行完成
    isNewBenefitFinish: false, // 新人福利是否执行完成
    isDailyTaskFinish: false, //检测是否每日任务是否执行完成
    isWeeklyTaskFinish: false, //检测每周任务是否执行完成
    isEvery3DayTaskFinish: false, //检测每三日任务是否执行完成
    activesAuthInfos: DefaultActivesAuthInfos, // 活动任务返水 权限状态
    returnGoldConfig: [], // 返水配置
    vipConfig: {} as VipConfig, // vip配置
    yueBaoConfig: {} as YueBaoConfig, // 利息宝配置
    rechargeFundConfig: [], // 公积金配置
    activePageSort: '', // 优化页面排序
    isFromBindModal: false,
    ignoreSecurityBindkey: {},
    bindModalIsOpen: false,
    bindModalIsLargeBalances: false,
    currencyList: [], // （所有币种列表信息，当前站点配置币种列表从siteInfos.currencyInfos取）
    defaultCountry: null,
    smsCountrys: [],
    smsSegment: '',
    screenSize: ScreenSize.S, // 窗口尺寸
    screenAspectRatio: 1, // 屏幕纵横比
    homeLayoutInfos: null, // 首页版式配置信息
    popRechargeEvent: undefined,
    enterPagePopupType: 0, // 存储打开页面时的弹框类型，0：无，1：登录，2：注册
    browserFingerId: '',
    geeGuardToken: '',
    getredDot: null,
    gameListTabActive: 0,
    installPrompt: null,
    currentTryCurrency: null, //当前选择的试玩币种
    realnameValue: '', // 存真实姓名
    viewResize: 0, //屏幕尺寸改变随机码(每当屏幕尺寸发生变化的,随机)
    hiddenDownload: false,
    loadingSvgSprite: false, // svg合图
    loadingImgSprite: false, // 雪碧合图 png合图
    isBrowserOpenChoosed: false,
    alreadyPopupBindItem: '', // 记录强制绑定已弹窗内容
    rootFontSize: 0, //屏幕尺寸改变随机码(每当屏幕尺寸发生变化的)
    useLocalSkinAssets: false,
    needChangeWithdrawPassword: false,
    hasChangedWithdrawPassword: false,
    skinId: '',
    largeAmountMustBind: null,
    largeAmountRange: null,
    previousRouteName: '',
    bindModalIsWithdrawVerify: false,
    robot: 1,
    isEmailExist: false,
    isPhoneExist: false,
    registerBubbleData: {},
    isSecurityHad: false,
    /**5198主题奖金金额缓存 */
    theme18RollingRed: 100000,
    /**金币超时错误 */
    isCurrencyTimeoutError: false,
    /**官方渠道接口错误 */
    brandAxiosError: null,
    brandAxiosErrorLoading: false
  }),
  getters: {
    /**渠道是否配置了强制安装APP开关 */
    isForceDownloadByChannel(): boolean {
      const { channelInfo } = useChannelStore()
      return !!channelInfo?.isForceDownload
    },
    /**是否显示渠道的下载额外奖励 */
    isShowDownloadRewardsByChannel(): boolean {
      const { channelInfo } = useChannelStore()
      return !!channelInfo?.downloadRewards
    },
    /**用户是否已经完成首冲 */
    isFirstDeposit(): boolean {
      const depositCount = this.userInfos?.deposit_count ?? 0
      return depositCount > 0
    },
    /**获取新人APP下载奖励通过渠道任务配置 */
    downloadAppRewardByChannel() {
      const { channelInfo } = useChannelStore()
      const downloadAppReward = channelInfo?.rewardsAmount ?? 0
      return downloadAppReward
    },
    /**获取新人APP下载奖励通过站点任务配置 */
    downloadAppReward(): number {
      const { downloadReward, filterCurrencyListByCurrentCurrency } =
        useMainStore()
      // 有可能在新人福利没有配置下载福利
      // 可能配置了，但是气泡关闭了，目前为了节省数据，只返回了isPop为1的数据
      let filteredReward = 0
      if (downloadReward?.currency) {
        filteredReward = filterCurrencyListByCurrentCurrency(
          downloadReward.currency
        )
      }
      return filteredReward
    },
    ossCommonHost(): string {
      return this.siteURLInfos?.commonOssBucket || ''
    },
    /**
     * 公共桶 oss 域名集合 cdn最多取两个，然后用公共桶域名保底
     */
    ossCommonDomain(): string[] {
      const { siteURLInfos } = this
      return [
        // 'https://test-oss-common-domain',
        ...(siteURLInfos?.commonOssDomain || []).slice(0, 2),
        ...(this.ossCommonHost ? [this.ossCommonHost] : [])
      ].map((it) => (it.endsWith('/') ? it : `${it}/`))
    },

    /**
     * oss 皮肤资源地址前缀
     */
    ossSkinOrigin(): string[] {
      return [
        ...this.ossDomain.map(
          (it) => `${it.endsWith('/') ? it : `${it}/`}siteadmin/skin`
        )
      ]
    },
    /**
     * oss 域名集合 cdn最多取两个，然后用ossHost保底
     */
    ossDomain(): string[] {
      const { ossHost, siteURLInfos } = this
      return [...(siteURLInfos?.oss_domain || []).slice(0, 2), ossHost]
        .map((it) => (it.endsWith('/') ? it : `${it}/`))
        .filter((value, index, context) => {
          return context.indexOf(value) === index
        })
    },

    /**
     * 是否尝试使用本地资源
     */
    useLocalSkinAssets(): boolean {
      return !!(
        this.defaultUrlQueryParams['mock.useLocalSkinAssets'] === 'true' &&
        (process.env.NODE_ENV === 'development' ||
          process.env.SITE_CONFIG_ALLOW_DEBUG === 'true')
      )
    },

    /**
     * 是否直接使用本地资源
     */
    useLocalSkinAssetsOnly(): boolean {
      return !!(
        this.defaultUrlQueryParams['mock.useLocalSkinAssetsOnly'] === 'true' &&
        (process.env.NODE_ENV === 'development' ||
          process.env.SITE_CONFIG_ALLOW_DEBUG === 'true')
      )
    },

    /**
     * assetsPath资源前缀
     */
    assetsPathOrigin(): string[] {
      const localOrigin = [`${location.origin}/assets`]
      return this.useLocalSkinAssetsOnly
        ? localOrigin
        : [
            ...this.ossSkinOrigin,
            ...(this.useLocalSkinAssets ? localOrigin : [])
          ]
    },
    /**
     * 部署模式，为true代表部署在oss桶，当前域名回源到oss桶，为false代表部署在web容器，当前域名回源到web容器，可/hall反代到api
     */

    isOssMode(): boolean {
      return Boolean(this.siteConfig?.OSS_MODE)
    },

    ossHost(): string {
      let ossName = this.siteConfig?.ossName
      let ossType = this.siteConfig?.ossType
      if (!ossName && !ossType) {
        ossName = process.env.SITE_CONFIG_OSS_NAME
        ossType = process.env.SITE_CONFIG_OSS_TYPE
      }
      return `https://${ossName}.${ossType}/`
    },

    siteCode(): string {
      return this.siteConfig?.siteCode as string
    },

    auth(): string {
      return useTokenStore().tokenInfos?.userkey as string
    },
    /** 浏览器指纹 */
    getFingerId(): string {
      return this.userInfos?.deviceFingerprint || this.browserFingerId
    },
    /**
     * ant 内部国际化数据，已挂载到i18n.messages
     */
    antLocale(): AntLocale | undefined {
      return (locale.i18n.messages as I18nMessages)[this.language]?.ant
    },
    /**
     * 是否需要强制修改密码
     */
    isNeedForceChangePwd(): boolean {
      return this.userInfos?.change_password === 1
    },
    /**
     * 是否需要绑定手机
     */
    isNeedBindPhone(): boolean {
      return this.userInfos?.must_bind_phone === 1
    },
    /**
     * 是否为试玩账号 (看不到用户头像,用各环境静态token mock的用户)
     */
    isTryMode(): boolean {
      return this.userInfos?.account_type === AccountType.Try
    },

    /**
     * 是否为体验账号 (可以看到用户头像，需要主动进行登录,能访问无需登录的页面及需要登录但whiteRoleType加白了的页面)
     */
    isDemoMode(): boolean {
      return this.userInfos?.account_type === AccountType.Demo
    },

    /**
     * 本地是否有token
     */
    hasToken(): boolean {
      return !!useTokenStore().tokenInfos?.userkey
    },

    /**
     * 是否正式登录过（可能是正式账号登录或者体验账号登录）
     * 1. 存在 session_key （登陆后的标识）
     * 2. 非试玩账号
     */
    hasLogined(): boolean {
      return this.hasToken && !this.isTryMode
    },

    /**
     *  是否是真正的登录状态（可能是正式账号登录或者体验账号登录）
     *  1. 存在独立持久化的 userkey (当前版本需根据userInfos是否有从stroage中标识来区分)
     */
    isRealLogined(): boolean {
      return !this.userInfos?.FROM_STROAGE && this.hasLogined
    },

    /**
     * 1.地址栏的userkey合法
     * 2.地址栏userkey登录未标记失败
     */
    userkeyFromUrl(): string {
      const userkey = String(this.defaultUrlQueryParams?.userkey || '')
      return !this.isUserKeyFromUrlFail && userkey.length === 342 ? userkey : ''
    },

    /**
     * 是否用快速登录
     * 1. 本地有token信息
     * 2. 地址栏有userkey信息
     */
    useFastLogin(): boolean {
      return this.hasToken || !!this.userkeyFromUrl
    },
    /**
     * 服务器时间 - 本地时间的差值
     */
    timeDiff(): number {
      return this.timeDiffs.length
        ? Math.floor(
            this.timeDiffs.reduce((a, b) => a + b) / this.timeDiffs.length
          )
        : 0
    },

    remUnit(): number {
      return Number(this.currentTheme['--theme-rem-unit'])
    },
    /**
     * 当前站点的货币金额符号
     */
    i18nAmountSymbol(state): CurrencySymbol {
      return state.siteInfos?.currencySign || ''
    },
    /**
     * 根据货币code获取货币信息
     */
    getCurrencyInfoByCode(state) {
      return (code = 'CNY') => {
        return state.currencyList.find((item) => item.currencyCode === code)
      }
    },
    /**
     * 当前账号的验证配置信息
     */
    validateConfig() {
      if (!this.userInfos) return {}
      const {
        email,
        thirdEmail,
        thirdType,
        mobile_phone,
        cpf,
        is_open_google_auth,
        permissionOpt,
        must_bind_phone,
        must_bind_email,
        must_bind_google_auth,
        mustBindWithdrawPass: must_bind_withdraw_pass,
        mustBindSecurityQuestion: must_bind_question,
        mustBindWithdrawAccount: must_bind_withdraw_account
      } = this.userInfos as UserInfos
      const systemInfos = (this.systemInfos || {}) as Partial<SystemInfos>

      // 用户已设置过 对应项
      // 登录返回字段新增permissionOpt(替代userOptResult)
      const hasQuestion = permissionOpt?.hasSecurityQuestion

      const hasWithdrawPwd = permissionOpt?.hasWithdrawPasswd

      const hasGoogleAuth = is_open_google_auth === GAStatus.OPEN
      const hasEmail = !!email
      const hasThirdEmail = !!thirdEmail
      const hasPhoneNumber = !!mobile_phone
      const hasCpf = !!cpf
      const hasWithdrawAct = permissionOpt?.hasWithdrawAccount

      // 情况1 有谷歌或邮箱或者手机
      const hasGaOrEmailOrPhoneNumber =
        hasGoogleAuth || hasEmail || hasPhoneNumber

      // 情况2 有密保问题或者提现密码
      const hasQuestionOrWithdrawPwd = hasQuestion || hasWithdrawPwd

      const { t } = useI18n()
      const canUpdatePwd = [
        systemInfos?.securityVerify?.securityLoginPass,
        hasPhoneNumber && systemInfos?.securityVerify?.securityPhone,
        hasEmail && systemInfos?.securityVerify?.securityEmail,
        hasGoogleAuth && systemInfos?.securityVerify?.securityGoogle,
        hasQuestion && systemInfos?.securityVerify?.securityPassQuestion
      ].some(Boolean)

      // 安全校验项title
      const securityVerifyTitles: string[] = []
      if (systemInfos?.securityVerify?.securityPhone) {
        securityVerifyTitles.push(
          t('lobby.modal.login.form.phone.label') as string
        )
      }
      if (systemInfos?.securityVerify?.securityEmail) {
        securityVerifyTitles.push(
          t('lobby.center.security.email.tab') as string
        )
      }
      if (systemInfos?.securityVerify?.securityGoogle) {
        securityVerifyTitles.push(t('lobby.center.security.ga.tab') as string)
      }
      if (systemInfos?.securityVerify?.securityPassQuestion) {
        securityVerifyTitles.push(
          t('lobby.common.components.auth.question.name') as string
        )
      }

      const showBind = {
        phone:
          must_bind_phone !== undefined &&
          must_bind_phone !== BindType.OFF &&
          !hasPhoneNumber,
        email:
          must_bind_email !== undefined &&
          must_bind_email !== BindType.OFF &&
          !hasEmail,
        ga:
          must_bind_google_auth !== undefined &&
          must_bind_google_auth !== BindType.OFF &&
          is_open_google_auth === GAStatus.NONE,
        withdrawPass:
          must_bind_withdraw_pass !== undefined &&
          must_bind_withdraw_pass !== BindType.OFF &&
          !hasWithdrawPwd,
        question:
          must_bind_question !== undefined &&
          must_bind_question !== BindType.OFF &&
          !hasQuestion,
        withdrawAccount:
          must_bind_withdraw_account !== undefined &&
          must_bind_withdraw_account !== BindType.OFF &&
          !hasWithdrawAct
      }

      const forceBind = {
        phone: showBind.phone && must_bind_phone === BindType.REQUIRED,
        email: showBind.email && must_bind_email === BindType.REQUIRED,
        ga: showBind.ga && must_bind_google_auth === BindType.REQUIRED,
        withdrawPass:
          showBind.withdrawPass &&
          must_bind_withdraw_pass === BindType.REQUIRED,
        question: showBind.question && must_bind_question === BindType.REQUIRED,
        withdrawAccount:
          showBind.withdrawAccount &&
          must_bind_withdraw_account === BindType.REQUIRED
      }

      return {
        email,
        phoneNumber: mobile_phone,
        cpf,

        thirdType,
        thirdEmail,
        hasThirdEmail,

        /**
         * 当前的GA状态
         */
        gaStatus: is_open_google_auth,
        /**
         * 是否设置过密保问题
         */
        hasQuestion,
        /**
         * 是否设置过提现密码
         */
        hasWithdrawPwd,
        /**
         * 是否设置过提现账号
         */
        hasWithdrawAct,
        /**
         * 是否设置过谷歌验证
         */
        hasGoogleAuth,
        /**
         * 是否绑定过邮箱
         */
        hasEmail,
        /**
         * 是否绑定过手机号
         */
        hasPhoneNumber,
        /**
         * 是否绑定过cpf
         */
        hasCpf,
        /**
         * 安全中心中是否可以修改密码
         */
        canUpdatePwd,
        /**
         * 使用business-components\auth\group-a 进行验证
         */
        hasGaOrEmailOrPhoneNumber,
        /**
         * 使用business-components\auth\group-b 进行验证
         */
        hasQuestionOrWithdrawPwd,

        forceBind,
        showBind,
        /**
         * 安全验证项title
         */
        securityVerifyTitles
      }
    },
    /**
     * 当前站点的三方配置信息
     */
    threeWayConfig(state) {
      const { facebook, google, wechat, qq, line } = state.systemInfos || {}
      const useFacebook = facebook?.status === CommonSwitchStatus.OPEN
      const useGoogle = google?.status === CommonSwitchStatus.OPEN

      const useWechat = wechat?.status === CommonSwitchStatus.OPEN
      const useQQ = qq?.status === CommonSwitchStatus.OPEN
      const useLine = line?.status === CommonSwitchStatus.OPEN
      const list: ThirdWayType[] = []
      if (useGoogle) {
        list.push(ThirdWayType.GOOGLE)
      }
      if (useFacebook) {
        list.push(ThirdWayType.FACEBOOK)
      }
      if (useWechat) {
        list.push(ThirdWayType.WECHAT)
      }
      if (useQQ) {
        list.push(ThirdWayType.QQ)
      }
      if (useLine) {
        list.push(ThirdWayType.LINE)
      }
      return {
        useFacebook,
        useGoogle,
        useWechat,
        useQQ,
        useLine,
        list
      }
    },
    /**
     * 全局api/游戏新开窗口地址
     */
    apiBaseUrl(): MainState['baseURL'] {
      return this.baseURL
    },
    /**
     * 活动任务返水是否显示
     */
    getActivesAuthInfos(): Record<
      keyof MainState['activesAuthInfos'],
      boolean
    > {
      const {
        returnGoldClosed,
        rechargeFundClosed,
        rewardCount,
        disableReceiveAwardAll,
        loading
      } = this.activesAuthInfos

      return {
        activeCount: true, // 活动 默认开启
        taskCount: true, // 任务 默认开启
        returnGoldClosed: returnGoldClosed === ReturnGoldSwitchEnum.Open, // 返水
        rechargeFundClosed: rechargeFundClosed === ReturnGoldSwitchEnum.Open, // 公积金
        disableReceiveAwardAll:
          disableReceiveAwardAll === ReceiveAwardAllSwitchEnum.Open, // 一键领取开关
        rewardCount: rewardCount === EventCategoriesAvailableEnum.Show,
        disableReceiveLogPop: false, // 待领取弹窗
        loading
      }
    },
    /**
     * 响应式布局断点
     */
    getScreenSize(): ScreenSize {
      return this.screenSize
    },
    /**
     * screenSize 不为 'small' 才视为PC视窗
     * PC下架后，应该恒等于'small'
     */
    isWeb(): boolean {
      // const isH5 =
      //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      //     navigator.userAgent
      //   )
      // return this.screenSize !== ScreenSize.S && !isH5
      return this.screenSize !== ScreenSize.S
    },
    /**
     * 是否为中文[包含繁体]
     */
    isCn(state) {
      return ['zh_CN', 'zh_TW'].includes(state.language)
    },
    /**
     *是否需要校验真实姓名必须为大写(169站点越南语需求)
     */
    isValidateRealNameUppercase(state) {
      const { realNameMustUppercase = 0 } = state.systemInfos ?? {}
      return realNameMustUppercase === RegisterValidateRequired.YES
    },
    /**
     *活动领取音效
     */
    audioReceive() {
      return new Audio(audioFile)
    },
    /**判断VIP入口是否隐藏*/
    getVipSwitchIsHide(state): boolean {
      const { useFastLogin } = useMainStore()
      const vipConfig = state.vipConfig
      const currency = state.userInfos?.currency
      let isHide = true
      if (useFastLogin) {
        // 2.查看会员层级 若会员层级在在禁止层级里则不展示vip
        const meberLevel = state.userInfos?.member_level?.toString() ?? ''
        if (vipConfig && meberLevel) {
          Object.keys(vipConfig).forEach((key: string) => {
            if (key === currency) {
              const banUserLevel =
                vipConfig[key]?.banUserLevel?.split(',') ?? []
              isHide = banUserLevel.includes(meberLevel)
            }
          })
        }
        return isHide
      } else {
        // 游客模式（未登录）若试玩账号层级在禁止层级里 则不展示vip入口
        if (vipConfig && currency) {
          Object.keys(vipConfig).forEach((key: string) => {
            if (key === currency) {
              const banUserLevel =
                vipConfig[key]?.banUserLevel?.split(',') ?? []
              isHide = banUserLevel.includes(DemoLevel.toString())
            }
          })
        }

        return isHide
      }
    },
    /** 返水开关 入口是否需要隐藏 */
    getReturnGoldIsHide(state): boolean {
      const { useFastLogin, userInfos, activesAuthInfos } = useMainStore()
      // 当前会员币种
      const currency = userInfos?.currency ?? ''
      // 返水配置
      const returnGoldConfig = state.returnGoldConfig ?? []
      // 当前会员层级
      const meberLevel = userInfos?.member_level?.toString() ?? ''
      // 返水总开关是否为关闭状态
      let isHide =
        activesAuthInfos.returnGoldClosed === ReturnGoldSwitchEnum.Close
      // 如果后台关闭了返水总开关或返水配置为空 则隐藏返水入口
      if (
        activesAuthInfos.returnGoldClosed === ReturnGoldSwitchEnum.Close ||
        returnGoldConfig?.length === 0
      ) {
        isHide = !!ReturnGoldSwitchEnum.Close
        return isHide
      }

      isHide = !returnGoldConfig.some((item: ReturnGoldConfigItem) => {
        const currencys = item?.currency?.split(',') ?? []
        const userLevels = item?.userLevels?.split(',') ?? []

        if (useFastLogin) {
          // 判断是否为全部币种【'0'】
          if (currencys.includes(ReturnGoldConfigCurrencyEnum.All)) {
            return userLevels.includes(meberLevel)
          }

          return currencys.includes(currency) && userLevels.includes(meberLevel)
        }

        // 游客模式 判断是否为全部币种【'0'】
        if (currencys.includes(ReturnGoldConfigCurrencyEnum.All)) {
          return userLevels.includes(DemoLevel.toString())
        }

        return (
          currencys.includes(currency) &&
          userLevels.includes(DemoLevel.toString())
        )
      })

      return isHide
    },
    /** 公积金入口显示开关 */
    getRechargeFundIsHide(state): boolean {
      const { useFastLogin, userInfos, activesAuthInfos, currentTryCurrency } =
        useMainStore()
      // 当前会员币种
      const currency =
        userInfos?.currency ?? currentTryCurrency?.currencyCode ?? ''
      // 公积金配置
      const rechargeFundConfig = state.rechargeFundConfig ?? []
      // 当前会员层级
      const meberLevel = userInfos?.member_level?.toString() ?? ''
      // 公积金总开关是否为关闭状态
      let isHide =
        activesAuthInfos.rechargeFundClosed === ReturnGoldSwitchEnum.Close
      // 如果后台关闭了公积金总开关或公积金配置为空 则隐藏公积金入口
      if (isHide || rechargeFundConfig?.length === 0) {
        isHide = !!ReturnGoldSwitchEnum.Close
        return isHide
      }

      isHide = !rechargeFundConfig.some((item: RechargeFundConfigItem) => {
        const currencys = item?.currency?.split(',') ?? []
        const userLevels = item?.userLevels?.split(',') ?? []

        if (useFastLogin) {
          // 判断是否为全部币种【'0'】
          if (currencys.includes(ReturnGoldConfigCurrencyEnum.All)) {
            return userLevels.includes(meberLevel)
          }

          return (
            currencys.includes(currency) &&
            (userLevels.includes(meberLevel) ||
              userLevels.includes(UserLevelEnum.All))
          )
        }

        // 游客模式 判断是否为全部币种【'0'】
        if (currencys.includes(ReturnGoldConfigCurrencyEnum.All)) {
          return userLevels.includes(DemoLevel.toString())
        }

        return (
          currencys.includes(currency) &&
          (userLevels.includes(DemoLevel.toString()) ||
            userLevels.includes(UserLevelEnum.All))
        )
      })

      return isHide
    },
    getHiddenDownload(state): boolean {
      return state.hiddenDownload
    },

    // 是否显示音乐播放器
    showMusic(state): boolean {
      return state.systemInfos?.musicShow === CommonSwitchStatus.OPEN
    },

    /** 是否需要显示修改提现密码弹窗 */
    withdrawPasswordDialogVisible(): boolean {
      const needChangeWithdrawPassword =
        this.needChangeWithdrawPassword ||
        this.userInfos?.changeWithdrawPassword === CommonSwitchStatus.OPEN
      return needChangeWithdrawPassword && !this.hasChangedWithdrawPassword
    },
    /** 大厅官方频道列表 */
    officialChannelList(): OfficialChannelConfig[] {
      const currency = this.hasLogined
        ? (this.userInfos?.currency as string)
        : (this.currentTryCurrency?.currencyCode as string)
      const channelList = this.brandLogoInfos?.hallOfficialCommunitys

      if (!channelList || channelList.length === 0) {
        return []
      }

      return channelList.filter((item: OfficialChannelConfig) => {
        return item.currency.indexOf(currency) !== -1
      })
    },
    enableUsingsms(): boolean {
      return (
        this.systemInfos?.enable_using_sms === CommonSwitchStatus.OPEN &&
        useMainStore().smsCountrys.length > 0
      )
    },
    enableUsingmail(): boolean {
      return this.systemInfos?.enable_using_gmail === CommonSwitchStatus.OPEN
    },
    smsCountry(): ContryInfos | undefined {
      return this.smsCountrys.find(
        (d) => d.phoneNumberSegment === this.smsSegment
      )
    },
    /** 余额模式 */
    curAuditMode(): AuditModeType {
      // 奖励余额 > 0 则是模式2  =0 则取auditMode
      if (Number(this.userInfos?.bonus) > 0) {
        return AuditModeType.DIRECT_WITHDRAWAL
      } else {
        return this.userInfos?.auditMode as AuditModeType
      }
    },
    emailSuffix(): string[] {
      const { isCn } = useMainStore()
      const { zh, en } = GlobalConst.EmailSuffix
      return isCn ? zh : en
    }
  },
  actions: {
    setHasClickButtonSaveShortCutByChannel(
      hasClickButtonSaveShortCutByChannel: boolean
    ) {
      this.hasClickButtonSaveShortCutByChannel =
        hasClickButtonSaveShortCutByChannel
    },
    setUserInfos(userInfos: UserInfos) {
      this.userInfos = userInfos
    },
    setHasClickButtonSaveShortCut(hasClickButtonSaveShortCut: boolean) {
      this.hasClickButtonSaveShortCut = hasClickButtonSaveShortCut
    },
    setDownloadReward(downloadReward: RewardBindData) {
      this.downloadReward = downloadReward
    },
    setSabaMaintenance(sabaMaintenance: boolean) {
      this.sabaMaintenance = sabaMaintenance
    },
    /**
     * 监听是否需要修改提现密码
     */
    listenChangeWithdrawPassword() {
      LongConnection.ws.on(
        LongConnectionEvent.CHANGE_BANK_PASS_REGISTER,
        (data: ChangePasswordWs) => {
          if (data.data.isRegister) {
            this.needChangeWithdrawPassword = true
          }
        }
      )
    },
    /** 监听用户金额发生变化更新 */
    listenChangeGameGold() {
      LongConnection.ws.on(
        LongConnectionEvent.GAME_GOLD_CHANGE,
        (data: { game_gold?: number }) => {
          if (data?.game_gold) {
            const { updateUserInfos } = useMainStore()
            updateUserInfos(
              merge({}, useMainStore().userInfos, {
                game_gold: data.game_gold
              })
            )
          }
        }
      )
    },

    /**
     * 记录维护通告时的维护时间，供到点后进行检查是否去维护
     */
    updateMaintainTimeInfo(
      maintainTimeInfo: Pick<MaintainType, 'end_time' | 'start_time'>
    ) {
      this.maintainTimeInfo = maintainTimeInfo
    },
    async fetchHiddenDownloadStatus() {
      /**
       * 极速包内部强行不显示下载相关的引导UI
       */
      if (windowConfig.speedPackageConfig) {
        this.hiddenDownload = true
        return
      }
    },

    getAutoFillData(): AutoFillData {
      if (!this.autoFillData) return {}

      try {
        return JSON.parse(
          GlobalCrypto.decrypt(this.autoFillData, this.autoFillSignKey)
        )
      } catch (error) {
        return {}
      }
    },
    /**
     * @param payload 传入要存储的内容
     * 账号登录的，仅存账号，如果记住密码，加上密码一起存
     */
    updateAutoFillData(payload: AutoFillData) {
      const mergeData = { ...this.getAutoFillData(), ...payload }
      this.autoFillData = GlobalCrypto.encrypt(
        JSON.stringify(mergeData),
        this.autoFillSignKey
      )
    },
    reRenderRoot() {
      this.rootKey = v4()
    },
    /**
     *音效播放
     */
    audioReceivePlay() {
      this.audioReceive?.play()
    },
    async initGetredDot(isRefresh = false) {
      if (!useMainStore().hasLogined) {
        return
      }
      // 刷新[任务store/活动store/mainStore]中的小红点数量,三者最终都是调用的同一个接口.就算是重复请求,最终也会被请求合并
      // 已经测试过了,此处,最终只会发出去一次接口请求.
      useEventStore().freshEventRedDot(isRefresh)
      useTaskPageStore().freshCodeCateReceive(isRefresh)
      const [err, data] = await to(apiEventAndDiscountsRedDot(isRefresh))
      if (!err) {
        this.getredDot = data
      }
    },
    async setLanguage(language: Language) {
      await locale.setLanguage(language)
      this.language = language
      // location.href = '/'
    },

    /**
     * @deprecated 已废弃,请不要再使用它,请直接使用 initGetredDot
     */
    updateReceiveAward(isRefresh?: boolean) {
      this.initGetredDot(isRefresh)
    },
    updateLinkType(linkType: LinkType) {
      this.linkType = linkType
    },

    updateBrowserFingerId(browserFingerId: string) {
      this.browserFingerId = browserFingerId
    },
    updateCountryInfos(smsCountry: SmsCountry) {
      this.defaultCountry = smsCountry.defaultCountry
      this.smsCountrys = smsCountry.countryInfos || []
    },
    updateSmsSegment(segment?: string) {
      this.smsSegment = segment ?? ''
    },
    validSegmentRange(phoneNumber = '') {
      const { t } = useI18n()
      const { smsCountry } = useMainStore()
      const segment = smsCountry?.phoneNumberSegment
      const scopeString = smsCountry?.phoneLengthScope
      const getPurePhoneNumber = () => {
        return phoneNumber.includes('-')
          ? phoneNumber.split('-')[1]
          : phoneNumber
      }
      /** 不带区号的手机号 */
      const purePhoneNumber = getPurePhoneNumber()

      if (!scopeString || !purePhoneNumber) {
        return
      }
      const scope = scopeString.split(',').map(Number)
      const _min = Math.min(...scope) ?? 8
      const _max = Math.max(...scope) ?? 12

      if (purePhoneNumber.length >= _min && purePhoneNumber.length <= _max) {
        return
      }

      const range = createRangeStr(_min, _max)
      return `${t('lobby.modal.loginRegister.phoneErrorMessage2', {
        segment,
        range
      })}`
    },

    /**
     * 收集每次响应的服务器时间
     */
    updateTimeDiff(serverTime: number) {
      const localTime = moment().unix()
      this.timeDiffs.push(serverTime - localTime)
      if (this.timeDiffs.length > 10) {
        this.timeDiffs.shift()
      }
    },

    updateSystemInfos(systemInfos: SystemInfos) {
      this.systemInfos = systemInfos
    },
    /**
     * 优先级调整为：强制修改密码>绑定验证(手机>邮箱>ga>提现密码)>弹窗>
     * @param isLoginTrigger 是否由登录事件触发,有些弹窗规则仅在登录时触发才弹
     */
    async doActionsAfterLogin() {
      if (disabledModal()) {
        return
      }

      // 每次登录后把isNoticePopupFinish设置为false,让他继续走通知弹窗
      this.isNoticePopupFinish = false

      const {
        setRedEnvelopeQueryCount,
        setRedPocketPollingTime,
        getRedListData
      } = useRedPocketStore()

      // 请求红包数据
      setRedPocketPollingTime(RedPocketPollingTimeEnum.Seconds10)
      setRedEnvelopeQueryCount(RedPocketPollingCountEnum.Count)
      await getRedListData()
      //* 标记日期 1/28 标记编号(相同编号的逻辑,要一起删除) 544125478
      // await this.getPopRechargeList()
      //  跳转到首页需要打开弹框，但如果是[我的]页面，则不要打开弹窗(此处有历史遗留的设计问题，我的属于首页，并不是单独路由页。)
      // if (useTabBarStore().currentTabItem !== TabKey.MINE) {
      //   this.modalManager({ isLoginTrigger })
      // }
      this.longConnectionEventActive()
    },
    /**红包活动长连接 */
    longConnectionEventActive() {
      const { setRedEnvelopeQueryCount, changeRedPocketInterval } =
        useRedPocketStore()
      if (!this.hasLogined) return
      GlobalManager.WS.ws.on(LongConnectionEvent.ACTIVE_DETAILS, (data) => {
        // ws推送消息有红包，恢复红包轮询
        if (data.template === ActivityTemplate.RedPack) {
          setRedEnvelopeQueryCount(RedPocketPollingCountEnum.Count)
          changeRedPocketInterval(true)
          return
        }
      })
    },
    /**
     * 转换绑定项为key值
     */
    convertItemToKey(
      item: string,
      requiredBindItems: Record<string, string>
    ): string {
      for (const [key, value] of Object.entries(requiredBindItems)) {
        if (value === item) {
          return key
        }
      }
      return ''
    },
    /**
     * 根据当前用户绑定的配置项和后台配置的安全项过滤出第一步验证和最后一步校验项
     */
    getUserSecurityStepsList(
      validateConfig: ValidateConfig,
      scene: VerifyScene
    ) {
      const firstVerify: number[] = []
      const lastVerify: number[] = []
      const { forgetPwdVerify, securityVerify } = useMainStore()
        .systemInfos as SystemInfos
      const verify =
        scene === VerifyScene.FORGOT_PASSWORD ? forgetPwdVerify : securityVerify

      const {
        securityEmail,
        securityGoogle,
        securityLoginPass,
        securityPassQuestion,
        securityPhone,
        securityWithdrawPass
      } = verify

      const {
        hasEmail,
        hasGoogleAuth,
        hasPhoneNumber,
        hasQuestion,
        hasWithdrawPwd
      } = validateConfig

      /**
       * 获取后台开启的安全配置和当前用户绑定的配置过滤出第一步验证和最后一步校验项
       * step 表示第一步还是最后一步
       * verity 后台验证字段与groupA验证组合方式的映射
       */
      const userVaildObj: Record<string, { step: unknown; verity: number }> = {
        securityLoginPass: {
          /**
           * 忘记密码场景不能用密码作为验证方式
           */
          step: scene !== VerifyScene.FORGOT_PASSWORD && securityLoginPass,
          verity: SecondAuthType.LOGIN_PWD
        },

        securityWithdrawPass: {
          step: hasWithdrawPwd && securityWithdrawPass,
          verity: SecondAuthType.WITHDRAW_PWD
        },

        securityPassQuestion: {
          step: hasQuestion && securityPassQuestion,
          verity: SecondAuthType.QUESTION
        },

        securityGoogle: {
          step: hasGoogleAuth && securityGoogle,
          verity: SecondAuthType.GA
        },

        securityEmail: {
          step: hasEmail && securityEmail,
          verity: SecondAuthType.EMAIL
        },

        securityPhone: {
          step: hasPhoneNumber && securityPhone,
          verity: SecondAuthType.PHONE
        }
      }

      // 后台是否有第一步的安全配置
      let first_step_verify = false
      // 后台是否有最后一步的安全配置
      let last_step_verify = false

      Object.keys(userVaildObj).forEach((key) => {
        if (scene === VerifyScene.FORGOT_PASSWORD) {
          if (userVaildObj[key].step === SecurityVerify.FIRST_VERIFY) {
            firstVerify.push(userVaildObj[key].verity)
          }
          if (userVaildObj[key].step === SecurityVerify.LAST_VERIFY) {
            lastVerify.push(userVaildObj[key].verity)
          }
          first_step_verify = Object.values(verify).includes(
            SecurityVerify.FIRST_VERIFY
          )
          last_step_verify = Object.values(verify).includes(
            SecurityVerify.LAST_VERIFY
          )
        } else if (scene === VerifyScene.SECURITY_CENTER) {
          if (userVaildObj[key].step === SecurityVerify.FIRST_VERIFY) {
            lastVerify.push(userVaildObj[key].verity)
          }
          last_step_verify = Object.values(verify).includes(
            SecurityVerify.FIRST_VERIFY
          )
        }
      })

      // 已绑定的和后台交集为空 但配置了第一步 且是忘记密码场景
      // 已绑定的和后台交集为空 但配置了最后一步
      // 默认是 账号密码 校验项
      if (
        firstVerify.length &&
        first_step_verify &&
        scene !== VerifyScene.FORGOT_PASSWORD
      ) {
        firstVerify.push(SecondAuthType.LOGIN_PWD)
      } else if (
        !lastVerify.length &&
        last_step_verify &&
        scene !== VerifyScene.FORGOT_PASSWORD
      ) {
        lastVerify.push(SecondAuthType.LOGIN_PWD)
      }

      return {
        firstVerify,
        lastVerify
      }
    },

    autoUpdateIsFromBindModal(name?: string | null) {
      /** 如果跳转的路由不是在强制绑定的路由范围内，重置不在路由内的状态，再次检测是否需要强制绑定 */
      if (
        ![RouteName.SECURITY, RouteName.WITHDRAW].includes(name as RouteName)
      ) {
        useMainStore().isFromBindModal = false
      }
    },

    doLoginForceBindPopup() {
      if (this.needForceBindSecurity()) {
        this.handleBindModalForName()
      }
    },

    needForceBindSecurity() {
      /** 初始化后台配置项 */
      useSecurity().initBindConfig(this.bindModalIsWithdrawVerify)
      /** 获取上面初始化后的配置项 */
      const { systemMastBindList, systemAllBindList } = useSecurity()

      /**
       * 强制绑定逻辑变更（10.12）弹框提示语改为至少需要绑定${绑定项}$中的2/1项，不可以关闭跳过，点击后跳转安全中心。
       * 切换手机，邮箱，谷歌验证器、提现密码不显示提示，切换其他tab再次提示，直到强制绑定流程结束后不再提示。
       * 体验账号跳过强制绑定
       */

      if (
        /** 当前正处于强制绑定流程中 - 不弹框 */
        this.isFromBindModal ||
        /** 弹框已经弹出了，不重复弹 */
        this.bindModalIsOpen ||
        /** 所有的绑定项都为空 - 不弹框 */
        !systemAllBindList.length ||
        /** 体验账户 - 不弹框 */
        this.isDemoMode ||
        /** 如果关闭过 且 没有必绑项 - 不弹框 */
        (this.getIgnoreSecurityBindkey() && !systemMastBindList.length)
      ) {
        return false
      }

      return true
    },

    async handleBindModalForName(bool = false) {
      const { t } = useI18n()
      const { systemMastBindList } = useSecurity()
      if (!this.bindModalIsOpen) {
        if (bool) {
          return bool
        }
        //判断当前路由页面已经处于可选绑定项的页面 就不再弹窗
        const optional: TabsEnum[] = []
        if (this.validateConfig?.showBind?.phone) {
          optional.push(TabsEnum.PHONE)
        }
        if (this.validateConfig?.showBind?.email) {
          optional.push(TabsEnum.EMAIL)
        }
        if (this.validateConfig?.showBind?.ga) {
          optional.push(TabsEnum.GA)
        }
        if (this.validateConfig?.showBind?.withdrawPass) {
          optional.push(TabsEnum.WITHDRAWPASS)
        }
        const { name: routeName, query } = router.app.$route
        if (
          query?.current &&
          routeName == 'security' &&
          optional.includes(Number(query.current))
        ) {
          this.isFromBindModal = true
          return false
        }
        this.bindModalIsOpen = true
        const closable = false
        // 记录已弹窗内容
        this.alreadyPopupBindItem = this.bindSecurityModalTitle()

        // 当前绑定项是否可以取消，如果都为非强制绑定项，可以取消
        let isShowCancelBtn = 'none'
        if (!systemMastBindList.length) {
          isShowCancelBtn = 'inline-block'
        }
        // 创建绑定弹窗
        GlobalManager.Modal.create({
          title: t('lobby.modal.title.error'),
          content: () => {
            return this.bindSecurityModalTitle()
          },
          titleType: 'error',
          class: 'modal-account-bind',
          titlePosition: 'center',
          contentPosition: 'center',
          closable,
          keyboard: systemMastBindList.length <= 0, // 存在必绑的不支持esc退出
          cancelButtonProps: {
            style: {
              display: isShowCancelBtn
            }
          },
          okText: t('lobby.modal.pay.bindBtn').toString(),
          onOk: () => {
            this.alreadyPopupBindItem = ''
            this.bindModalIsOpen = false
            this.isFromBindModal = true

            const { systemAllBindList, getBindKeyValueInfos } = useSecurity()
            const { bindKeyValues } = getBindKeyValueInfos()
            const targetkey = systemAllBindList[0]
            const targetval = bindKeyValues[targetkey].value

            /** 如果是要到提现账户 且 已经绑定了提现密码，跳转到提现账户 */
            if (
              targetkey === MUST_BIND_ITEM.WITHDRAWACCOUNT &&
              useMainStore().validateConfig.hasWithdrawPwd
            ) {
              router.push({
                name: RouteName.WITHDRAW,
                query: { current: String(CurrentPageTabs.withdrawAccount) }
              })
              return
            }

            /** 跳转到相应的绑定页，如果是提现账户 且 没有绑定提现密码，跳转到提现密码 */
            router.push({
              name: RouteName.SECURITY,
              query: {
                current: `${
                  targetkey === MUST_BIND_ITEM.WITHDRAWACCOUNT
                    ? TabsEnum.WITHDRAWPASS
                    : targetval
                }`
              }
            })
          },
          onCancel: () => {
            this.isFromBindModal = false
            this.bindModalIsOpen = false
            this.setIgnoreSecurityBindkey()

            // 点击时 关闭强制绑定弹窗，继续下一个弹窗队列
            autoModalManager.onModalClose('forceBindSecurity')
          }
        })
      }
    },

    /**
     * 为当前用户设置一个点击过安全中心绑定项的返回按钮或者跳过按钮的标识
     */
    setIgnoreSecurityBindkey() {
      const username = this.userInfos?.username
      if (username) {
        this.ignoreSecurityBindkey[username] = true
      }
    },
    /**
     * @returns 返回当前用户是否点击过安全中心绑定项的返回按钮或者跳过按钮的标识
     */
    getIgnoreSecurityBindkey() {
      const username = this.userInfos?.username
      if (!username) {
        return false
      }
      return !!this.ignoreSecurityBindkey[username]
    },

    bindSecurityModalTitle() {
      const { t } = useI18n()
      const { systemAllBindList, getBindKeyValueInfos } = useSecurity()
      const { bindKeyValues } = getBindKeyValueInfos()
      const andText = t('lobby.center.security.forceBindAnd') as string

      if (this.bindModalIsLargeBalances) {
        return t('lobby.center.security.LargeBalancesBindTitle') as string
      }
      const bindListToLabel = systemAllBindList.map(
        (d) => bindKeyValues[d].label
      )
      const lastLabel = bindListToLabel.pop()
      const bindItem = [bindListToLabel.join('、'), lastLabel]
        .filter(Boolean)
        .join(andText)
      if (this.bindModalIsWithdrawVerify) {
        const bindTitle =
          systemAllBindList?.length === 1
            ? t('lobby.center.security.forceBindTitle', {
                bindItem: bindItem
              })
            : t('lobby.center.security.forceBindItemTitle', {
                bindItem: bindItem,
                length: 1
              })
        return bindTitle as string
      }

      return `${t('lobby.center.security.forceBindTitle', {
        bindItem
      })}`
    },

    /** 每次登录大厅展示弹框 */
    async doLoginShowForcePopup(done?: () => void) {
      // frame_type弹出方式:1=每次登陆,2=每日一次,3=每回大厅必弹,4=一次性
      // 必弹的弹框类型设置不再提示没用
      const newFrameBoxInfos = [
        ...this.frameBoxInfos.filter((item) => {
          // 先不区分登录/未登录弹窗，后面产品会重构
          if (item.not_remind_again || item.not_remind_login) {
            return false
          }
          return true
        })
      ]
      if (newFrameBoxInfos.length === 0) {
        GlobalManager.Modal.close('forcePopup')
        done?.()
      }
      const showoForcePopup = () => {
        if (newFrameBoxInfos.length > 0 && !judgeIsForbidPopByPageAddress()) {
          GlobalManager.Modal.open('forcePopup', {
            frameBoxInfo: newFrameBoxInfos[0],
            onOpen: (currentFrameBoxInfo) => {
              // 兼容弹窗接口风格不统一
              const frameTypes = [
                currentFrameBoxInfo.frameType,
                currentFrameBoxInfo.frame_type
              ]
              /** 当类型为一次性的弹框，执行完就把不再提示(item.not_remind_again)状态设为true */
              if (frameTypes.includes(FrameType.ONE)) {
                currentFrameBoxInfo.not_remind_again = true
              }
              /** 当类型为每次登录的弹框，需要将提示(not_remind_login)状态设为true, 但重新登录的该状态将重置为false */
              if (frameTypes.includes(FrameType.LOGIN)) {
                currentFrameBoxInfo.not_remind_login = true
              }
              /** 当类型为每日执行一次，执行完当天不再提示(item.not_remind_again)状态设为true，并设置storage过期时间 */
              if (frameTypes.includes(FrameType.DAY)) {
                currentFrameBoxInfo.not_remind_again = true
                currentFrameBoxInfo.storageExpirationTime = moment()
                  .endOf('day')
                  .valueOf()
              }

              // 以下是为了操作将状态保存到本地存储中
              // storage存储的弹窗数据
              let frameBoxStorages: Array<FrameStorageType> =
                getStorage('frameBoxInfos') || []

              if (!Array.isArray(frameBoxStorages)) {
                frameBoxStorages = []
              }

              // trialUsers 为试玩
              const platfromid = this.userInfos?.platfromid

              // 当前弹窗过滤掉其他字段后的数据
              const localStorageFrameBoxInfo =
                mapFieldLocalStorage(currentFrameBoxInfo)
              // 两层数据结构，第一层区分用户账号、第二层区分弹窗id
              if (frameBoxStorages.length && platfromid) {
                const userFrameBox = frameBoxStorages.find((i) => {
                  return i.platfromid === platfromid
                })
                if (userFrameBox) {
                  if (userFrameBox.data) {
                    const frameBoxItemKey = userFrameBox.data.findIndex(
                      (i) => i.id === currentFrameBoxInfo.id
                    )
                    if (frameBoxItemKey > 0) {
                      userFrameBox.data[frameBoxItemKey] = {
                        ...userFrameBox.data[frameBoxItemKey],
                        ...localStorageFrameBoxInfo
                      }
                    } else {
                      userFrameBox.data.push(localStorageFrameBoxInfo)
                    }
                  } else {
                    userFrameBox.data = [localStorageFrameBoxInfo]
                  }
                } else {
                  frameBoxStorages.push({
                    platfromid,
                    data: [localStorageFrameBoxInfo]
                  })
                }
              } else if (platfromid) {
                frameBoxStorages = [
                  {
                    platfromid,
                    data: [localStorageFrameBoxInfo]
                  }
                ]
              }
              if (frameBoxStorages.length) {
                localStorage.setItem(
                  'frameBoxInfos',
                  JSON.stringify(frameBoxStorages)
                )
              }
            },
            onClose: () => {
              autoModalManager.onModalClose('forcePopup')
              done?.()
              // newFrameBoxInfos.shift()
              // showoForcePopup()
            }
          })
        } else {
          done?.()
        }
      }
      showoForcePopup()
    },
    // 处理storage里的每日及每次登录的弹窗状态
    frameBoxStoragesHandler() {
      // storage存储的弹窗数据
      const frameBoxStorages: Array<FrameStorageType> =
        getStorage('frameBoxInfos')
      if (!Array.isArray(frameBoxStorages)) return
      const currentUserFrames = frameBoxStorages.filter(
        (item) => item.platfromid === this.userInfos?.platfromid
      )?.[0]?.data
      if (!currentUserFrames?.length) return
      currentUserFrames.forEach((item) => {
        const frameTypes = [item.frameType, item.frame_type]
        if (
          frameTypes.includes(FrameType.DAY) &&
          moment(item.storageExpirationTime).isBefore(moment())
        ) {
          item.not_remind_again = false
        }
        if (frameTypes.includes(FrameType.LOGIN)) {
          item.not_remind_login = false
        }
      })
      localStorage.setItem('frameBoxInfos', JSON.stringify(frameBoxStorages))
    },
    /**
     * 用户登出时将一些请求暂停
     */
    networkProtect() {
      //重置service里的key，可拦截登录状态发生变化期间的请求
      GlobalService.reset()
      //销毁所有在轮询中的请求
      Polling.destroy()
    },

    /**
     * 完整的更新用户信息，同时拿到后续有登录需求的接口 加解密 所需要的 key
     * 一般各种登录行为后会触发
     * userInfos中的token相关数据  session_key,jwt_token,userkey 可能不直接从某个接口来，可能接口不返回我们从本地取
     */
    updateUserInfos(
      userInfos: UserInfos | null,
      loginType?: LoginType,
      { clearTokenOnRemoveUserInfos = true, updateToken = false } = {}
    ) {
      this.userInfos = userInfos
      useSmartPayStore().init(userInfos)
      if (userInfos) {
        const { session_key, platfromid, userkey, jwt_token } = this
          .userInfos as UserInfos

        const needUpdateToken =
          updateToken || (loginType && loginType !== 'trymode')
        if (needUpdateToken && session_key && userkey && jwt_token) {
          useTokenStore().updateToken({
            session_key,
            jwt_token,
            userkey
          })
        }

        if (loginType) {
          GlobalService.setLoginState(
            loginType === 'trymode' ? 'fakeLogined' : 'logined'
          )
          this.updateUserKeyForFastLogin(userkey)
          if (loginType !== 'trymode') {
            GlobalEvents.dispatch({
              type: 'LOGIN_SUCCESS',
              payload: {
                loginType,
                userInfos
              }
            })
            // 创建websocket链接
            GlobalManager.WS.connectWS(
              this.siteCode,
              session_key,
              '/ws/websocket',
              GlobalService.apiDomain
            )
          }

          // 重置公告消息弹窗的状态
          this.frameBoxStoragesHandler()
          // 更新活动模块权限状态
          // this.fetchActivesAuthInfos()
          // 获取活动列表数据
          // this.getEventCategories()
          // 获取首充弹窗信息
          // this.getPopRechargeList()
          setTimeout(() => {
            usePromoteStore().getPromoteAdvertise()
          }, 3000)

          /**
           * 非试玩账号更新用户详细信息
           */
          if (!this.isTryMode) {
            if (!this.isDemoMode) {
              // this.updateUserVipInfos()
              // this.updateUserVipDetail()
            }
            if (userInfos?.lastgameinfo) {
              gameOpener.continueGameDialog(userInfos.lastgameinfo)
            } else {
              // 没有继续信息，默认调一次下分避免余额没拉回
              useGameStore().setGamePlaying(false)
              this.logoutAndUpdatePrize()
            }

            // this.getRewardBindData(false, [], false)
            this.lastLoginedUsername = platfromid
            /**
             * 代表非初始化时自动登录的行为的（直接登录，或者注册页面登录等）
             */
            // if (this.isInitializationCompleted) {
            //   this.doActionsAfterLogin()
            // }
          }
          // 添加安全中心强制绑定数量
          const {
            must_bind_email,
            must_bind_phone,
            must_bind_google_auth,
            mustBindWithdrawPass: must_bind_withdraw_pass,
            mustBindSecurityQuestion: must_bind_question,
            mustBindWithdrawAccount: must_bind_withdraw_account
          } = this.userInfos!
          const bindItem: Record<string, BindType> = {
            must_bind_withdraw_pass,
            must_bind_phone,
            must_bind_email,
            must_bind_google_auth,
            must_bind_question,
            must_bind_withdraw_account
          }
          const count = Object.values(bindItem).reduce(
            (acc, value) =>
              value === BindType.REQUIRED || value === BindType.NOT_ESSENTIAL
                ? acc + 1
                : acc,
            0
          )
          if (this.userInfos) {
            this.userInfos.must_bind_num = count ?? 0
            // 如果当前没有弹窗 且 顺序队列已经执行完
            if (!Modal.hasOpeningModal() && autoModalManager.isFinished) {
              this.doLoginForceBindPopup()
            }
          }
        }
      } else {
        if (clearTokenOnRemoveUserInfos) {
          useTokenStore().removeToken()
        }

        this.updateUserKeyForFastLogin('')
        /**
         * 移植退出登录的逻辑到这里
         * token过期 退出登录
         * 是否需要手动remove app.userInfos待观察
         */
        localStorage.removeItem('app.userInfos')
        usePromoteStore().clearPromoteAdvertise()
        this.networkProtect()
        LongConnection.ws?.disconnect()

        this.updateCheckNewBenefits(false)
      }
    },
    updateRewardBindData(rewardBindData: Array<RewardBindData>) {
      this.rewardBindData = rewardBindData
    },
    /**
     * 更新IM客服地址
     */
    async updateStaffall() {
      const { setServiceAxiosError, setLoading } = useServiceStore()
      setLoading(true)
      const [err, serviceInformation] = await to(apiServiceListV2())
      setLoading(false)
      if (err) {
        setServiceAxiosError(err as AxiosError)
        return
      }
      setServiceAxiosError(null)
      serviceInformation.customer.onlineCustomers =
        serviceInformation?.customer?.onlineCustomers ?? []

      this.serviceInformation = serviceInformation
    },
    /**
     * 获取系统Vip开关
     * */
    async updateVipSwitchStatus(list?: ApiVipSwitchStatusResponse[]) {
      if (list) {
        this.vipSwitchStatus = list
        return
      }
    },
    /**
     * 获取用户详细信息
     * @returns
     */
    async updateUserVipInfos() {
      if (this.isDemoMode) {
        return
      }
      const [err, data] = await to(apiUserVipDetails())
      if (err) {
        useVipStore().axiosError.userDetailsErr = err
        return
      }
      useVipStore().axiosError.userDetailsErr = null
      this.vipInfos = data
      this.realnameValue = this.vipInfos.realname
      this.$patch({
        userInfos: {
          ...this.userInfos,
          vip_status: this.vipInfos.vip_status
        }
      })
      return data
    },
    /**
     * 获取vipDetail
     * @returns
     */
    async updateUserVipDetail() {
      const [err, data] = await to(apiVipDetails())
      if (err) {
        return
      }
      this.vipDetails = data
      this.$patch({
        userInfos: {
          ...this.userInfos,
          vip_icon_back_color_value: this.vipDetails.icon_color_value
        }
      })
    },

    /**
     * 更新真实姓名
     * @param realname
     */
    updateRealname(realname: string) {
      if (realname) {
        this.realnameValue = realname
      }
    },

    /**
     * 手动传入数据更新用户真实姓名
     */
    updateUserVipInfosRealName(realname: string) {
      if (this.vipInfos && realname) {
        this.vipInfos = { ...this.vipInfos, realname }
      }
    },
    /**
     * 手动传入更新用户信息
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setUserVipInfosForm(vipInfosForm: any) {
      if (this.vipInfos && vipInfosForm) {
        this.vipInfos = vipInfosForm
      }
    },
    /**
     * 更新用户金额信息
     */
    async updatePrize() {
      if (this.isFetchingPrize) return
      this.isFetchingPrize = true
      const [err] = await to(User.updatePrize())
      this.isCurrencyTimeoutError = !!err
      this.isFetchingPrize = false
    },
    /**
     * 游戏下分并更新用户金额信息(非游戏场景不要调用)
     */
    async logoutAndUpdatePrize(force = false) {
      const { isGameScoring, isGamePlaying } = useGameStore()
      // 游戏上分中或游戏进行中禁止更新金额下分
      if ((this.isFetchingPrize || isGameScoring || isGamePlaying) && !force)
        return
      this.isFetchingPrize = true
      await gameOpener.gameLogout().finally(() => {
        this.isFetchingPrize = false
      })
    },
    /**
     * 更新GA状态
     */
    updateGaStatus(is_open_google_auth: UserInfos['is_open_google_auth']) {
      this.userInfos!.is_open_google_auth = is_open_google_auth
    },

    /**
     * 更新提现密码的状态
     */
    updateWithdrawalPwdStatus(optResult: OptResult) {
      this.userInfos!.permissionOpt[PermissionOperation.WithdrawalPasswd] =
        optResult === OptResult.OPEN

      if (optResult === OptResult.OPEN) {
        this.hasChangedWithdrawPassword = true
      }
    },
    /**
     * 更新管理账户的状态
     */
    updateWithdrawalActStatus(optResult: OptResult) {
      this.userInfos!.permissionOpt[PermissionOperation.WithdrawalAccout] =
        optResult === OptResult.OPEN
    },

    /**
     * 更新密保问题的状态
     */
    updateSecretQuestion(optResult: OptResult) {
      this.userInfos!.permissionOpt[PermissionOperation.SecretSecurity] =
        optResult === OptResult.OPEN
    },
    /**
     * 更新手机号 （需要脱敏）
     */
    updateMobilePhone(mobile_phone: string) {
      this.userInfos!.mobile_phone = mobile_phone
      const optResult = mobile_phone ? OptResult.OPEN : OptResult.NONE
      this.userInfos!.permissionOpt[PermissionOperation.MobilePhoneNumber] =
        optResult === OptResult.OPEN
    },
    /**
     * 更新手机号 （需要脱敏）
     */
    updateCpf(cpf: string) {
      this.userInfos!.cpf = cpf
    },
    /**
     * 更新真实姓名 （需要脱敏）
     */
    updateRealName(realname: string) {
      this.vipInfos!.realname = realname
    },
    /**
     * 更新邮箱（需要脱敏）
     */
    updateEmail(email: string) {
      this.userInfos!.email = email
    },
    /**
     * 更新三方绑定状态
     */
    updateThirdInfo(payload: {
      thirdEmail: string
      thirdType: ThirdWayStatus
    }) {
      this.userInfos!.thirdEmail = payload.thirdEmail
      this.userInfos!.thirdType = payload.thirdType
      /**
       * 如果未绑定邮箱，则同步三方邮箱
       */
      if (!this.userInfos?.email) {
        this.updateEmail(payload.thirdEmail)
      }
    },
    /**
     * 更新ip绑定信息
     */
    updateIpBindInfo(payload: IpBindInfos | null) {
      this.ipBindInfos = payload
    },
    /** 获取新人福利奖励列表
     * 这个方法还涉及到更新气泡的内容,所以就算是不给弹窗了,该方法该调还是得调,只是把弹窗逻辑去掉.
     */
    async getRewardBindData(
      beforeLogin?: boolean,
      triggerList?: Array<RewardBindType>,
      openNewBenefits = true
    ) {
      if (beforeLogin || !this.hasLogined) {
        const [err, response] = await to(apiGetActiveRewardBindBeforeLogin())
        if (err) {
          return
        }
        this.updateRewardBindData(response?.list || [])
      } else {
        const [err, response] = await to(apiGetActiveRewardBind())
        if (err) {
          return
        }
        const resData = response.data.data || []
        this.updateRewardBindData(resData)
      }
      // TODO66 当前已经不再需要打开新人福利弹窗的逻辑了,很多调用的地方,有传入后面的参数用于判断新人福利弹窗打开.
      // TODO66 这两个参数,暂时不太方便删除,所以写一个判断,通过eslint校验
      if (triggerList || openNewBenefits) {
        return
      }
      // 需要打开新人福利弹窗(打开新人福利弹窗这个逻辑需要废弃,因为新人福利弹窗中已经没有状态了)
      // if (openNewBenefits && triggerList?.length) {
      //   useTaskModalConstant().openSequentiallyTaskModal({
      //     apiCode: TaskCategoryApiCodeEnum.xrfl,
      //     ruleIds: triggerList,
      //     ignorePageAddress: true
      //   })
      // }
    },
    /**
     * 设置全局api/游戏新开窗口地址
     */
    setApiBaseUrl(url: MainState['baseURL']) {
      this.baseURL = url
    },
    /**
     * 设置全局线路测速中最优的域名
     */
    setBestApiBaseUrl(url: string) {
      this.bestBaseURL = url
    },
    /**
     * 获取活动模块权限状态
     */
    async fetchActivesAuthInfos() {
      this.activesAuthInfos.loading = true
      // const [err, data] = await to(apiGetEventCategoriesAvailable())
      const [err, data] = await to(apiActiveIsShowV2())
      if (err) {
        return
      }

      this.returnGoldConfig = data.returnGoldConfig
      this.vipConfig = data.vipConfig
      this.yueBaoConfig = data.yueBaoConfig
      this.rechargeFundConfig = data.rechargeFundConfig
      this.activePageSort = data.activePageSort

      this.activesAuthInfos = {
        ...this.activesAuthInfos,
        disableReceiveAwardAll: data.disableReceiveAwardAll,
        disableReceiveLogPop: data.disableReceiveLogPop,
        returnGoldClosed: data.returnGoldClosed,
        rechargeFundClosed: data.rechargeFundClosed,
        loading: false
      }
    },
    /**
     * 更新新人福利已完成
     */
    updateNewBenefitFinish(isNewBenefitFinish = true) {
      this.isNewBenefitFinish = isNewBenefitFinish
    },

    /**
     * 切换当次选中
     */
    updateCheckNewBenefits(checkNewBenefits: boolean) {
      this.checkNewBenefits = checkNewBenefits
    },
    /**
     * 进行保存网址任务不弹出新人任务
     */
    updateNewBenefitsIsInSaveUrl(isInSaveUrl: boolean) {
      this.isNewBenefitsInSaveUrl = isInSaveUrl
    },
    /**
     * 新人福利永不提示选中
     */
    updateCheckNeverNewBenefits(checkNeverNewBenefits: boolean) {
      this.checkNeverNewBenefits = checkNeverNewBenefits
    },
    /**
     * 响应式布局断点
     */
    setScreenSize(ScreenSize: ScreenSize) {
      this.screenSize = ScreenSize
    },
    /**
     * 屏幕纵横比（高/宽）
     */
    setScreenAspectRatio(ratio: number) {
      this.screenAspectRatio = ratio
    },
    /**
     * 更新快速登录的key
     */
    updateUserKeyForFastLogin(userKeyForFastLogin: string) {
      this.userKeyForFastLogin = userKeyForFastLogin
    },
    /**
     * 切换首页游戏列表选中的标签
     */
    updateGameListTabActive(key: number) {
      this.gameListTabActive = key
    },
    /** 请求品牌logo */
    async getBrandLogoInfos() {
      const [err, response] = await to(apiGetBrandLogoInfos())
      if (response.data.data) {
        this.brandLogoInfos = response.data.data
      }
      if (err) {
        this.brandAxiosError = err as AxiosError
      }
    },
    async updateBrandLogoInfos(brandLogoInfos: BrandLogoInfosRes) {
      this.brandLogoInfos = brandLogoInfos
    },
    /**
     * 更新当前试玩币种
     */
    async updateCurrentTryCurrency(currency: CurrencyInfos) {
      User.mockUnLoginedUserInfo({
        tryCurrency: currency
      })
      this.updataCurrentyTryCache(currency)
    },
    /**
     * 更新游客试玩币种的前端缓存。
     * */
    updataCurrentyTryCache(currency: CurrencyInfos) {
      this.currentTryCurrency = currency
    },
    /** 屏幕尺寸改变则更新rootFontSize */
    updateRootFontSize() {
      this.rootFontSize = parseFloat(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue('font-size')
      )
    },
    /**
     * 极速包或者马甲包中需要更新uuid为原生注入的
     * 不能只改上面store的初始值，因为uuid被持久化了，用不到初始值
     */
    updateDeviceUUID(uuid: string) {
      if (uuid && uuid !== this.uuid) {
        this.uuid = uuid
      }
    },
    updateLargeForcedBind(largeAmountMustBind: LargeAmountMustBind) {
      this.largeAmountMustBind = largeAmountMustBind
    },
    updateLargeAmountRange(largeAmountRange: Record<string, number>) {
      this.largeAmountRange = largeAmountRange
    },
    /**
     * 更新上一个访问的路由名称
     */
    updatePreviousRouteName(previousRouteName: string) {
      this.previousRouteName = previousRouteName
    },
    updateRememberPwd(newPwd: boolean) {
      this.rememberPwd = newPwd
    },
    setEmailExist(value?: boolean) {
      this.isEmailExist = value
    },
    setPhoneExist(value?: boolean) {
      this.isPhoneExist = value
    },
    /** 屏幕尺寸改变则更新标记 */
    updateViewResize() {
      this.viewResize = Math.random()
    },
    /** 修改登录注册弹窗新人福利 */
    setRegisterBubbleData(value: MainState['registerBubbleData']) {
      this.registerBubbleData = value
    },
    /** 修改绑定密保操作状态 */
    setSecurityHad(value: boolean) {
      this.isSecurityHad = value
    },
    /**从奖励列表中的所以币种中，筛选出来当前币种对应的奖励 */
    filterCurrencyListByCurrentCurrency(currency: string) {
      try {
        const currencyList = JSON.parse(currency)
        const currentCurrency = useMainStore().userInfos?.currency
        const currencyObject = currencyList.find(
          (currency: { currency: string; amount: string }) =>
            currency.currency === currentCurrency
        )
        if (currencyObject) {
          return currencyObject.amount
        }
      } catch (error) {}
    },
    /**
     * 计算对应币种的优惠金额
     */
    getBenefitReward(
      item: RewardBindData,
      currency: string,
      neeCurrency?: string
    ) {
      const currentCurrency = neeCurrency || useMainStore().userInfos?.currency
      // currency为特殊值时代表有所有币种权限
      const allCurrency = ['', '0', 0]
      let isAll = false
      if (allCurrency.includes(String(currency))) isAll = true
      if (!currency.includes(String(currentCurrency)) && !isAll) {
        item.reward = 0
        return item
      }
      const amountList = JSON.parse(String(item.currency)) as Array<{
        amount: number
        currency: string
      }>
      const currencyItem = amountList.find(
        (ele) => ele.currency === currentCurrency
      )
      item.reward = currencyItem?.amount || 0
      return item
    },

    updateRouterTransition(mode: MainState['routerViewWithTransitionMode']) {
      this.routerViewWithTransitionModeOnCurrentChange = mode
    },
    updateVersionReloadRecord(version: string) {
      const currentCount = this.versionReloadRecord[version] || 0
      this.versionReloadRecord[version] = currentCount + 1
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: createMyPersistedStorage(null, localStorage),
        paths: [
          'versionReloadRecord',
          'currencyList',
          'skinAssetsHash',
          'responseHeaders',
          'rememberPwd',
          'autoFillData',
          'uuid',
          'geetestRestrictedTime',
          'lastLoginedUsername',
          'browserFingerId',
          'isBrowserOpenChoosed',
          /***
           * 迭代几个版本后启用
           * 暂时只存在stroage中但不启用
           */
          'userKeyForFastLogin',
          'maintainTimeInfo',
          'currentTryCurrency',
          'sabaMaintenance',
          'ipBindInfos',
          'activesAuthInfos',
          'returnGoldConfig',
          'vipConfig',
          'yueBaoConfig',
          'rechargeFundConfig',
          'activePageSort',
          'robot',
          'ignoreSecurityBindkey',
          'theme18RollingRed' /**5198主题奖金金额缓存 */
        ]
      },
      {
        storage: createMyPersistedStorage(null, sessionStorage),
        paths: ['language']
      },

      {
        key: 'app.userInfos',
        paths: ['userInfos'],
        storage: createMyPersistedStorage(
          {
            getItem(key, storage) {
              const result = storage.getItem(key, storage) || null
              if (result === null) {
                return result
              }
              try {
                const origialData = JSON.parse(result)
                origialData.userInfos.FROM_STROAGE = true
                return JSON.stringify(origialData)
              } catch (error) {
                return result
              }
            }
          },
          localStorage
        )
      }
    ]
  }
})
