import { Button } from '@/plugins/ant-design-vue/tsx-support'
import {
  ChannelStatusList,
  IsForceDownloadList,
  RewardsCondition,
  UserDisplayList,
  ViewDownloadList
} from '@/views/channel/const'
import { Component, Mixins } from '@/vue-property-decorator'
import {
  GlobalEventActions,
  GlobalEventActionsTypeEnum
} from '@/context/events'
import { GlobalEvents } from '@/context'
import { LoginOsType } from './const'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { useChannelStore } from '@/views/channel/store'
import { useDownloadAppStore } from '@/layouts/components/downloadApp/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BaseMixins from './mixin/index'
import BusinessUtils from '@/utils/business-utils'
import Modal from '@/controller/Modal'
import TooltipsComp from '@/components/business-components/tooltips'
import isMobile from '@/utils/isMobile'
import style from './channel.module.scss'
/**通过渠道配置放开APP强制开启APP提示触发该弹出框 */
@Component<DownloadAppNotRequireForceByChannel>({
  name: 'DownloadAppNotRequireForceByChannel'
})
export default class DownloadAppNotRequireForceByChannel extends Mixins(
  BaseMixins
) {
  /**弹出开是否是调用了游戏接口后弹出来 */
  private isFromCallGameApi = false
  private handleDownloadApp(action: GlobalEventActions) {
    const { setHasClickDownloadAPPByChannel } = useDownloadAppStore()
    if (
      action.type ===
      GlobalEventActionsTypeEnum.DOWNLOAD_APP_NOT_REQUIRE_FORCE_BY_CHANNEL
    ) {
      this.downloadAppByChannel()
      setHasClickDownloadAPPByChannel(true)
    }
  }

  /**弹出渠道下载app弹出开通过调用游戏登录接口后的3010状态码 */
  private handleDownloadAppByGameapi(action: GlobalEventActions) {
    if (
      action.type ===
      GlobalEventActionsTypeEnum.DOWNLOAD_APP_CHANNEL_BY_CALL_GAMEAPI
    ) {
      this.isFromCallGameApi = true
      this.downloadAppByChannel()
    }
  }

  mounted() {
    GlobalEvents.subscribe(this.handleDownloadApp)
    GlobalEvents.subscribe(this.handleDownloadAppByGameapi)
  }

  beforeDestroy() {
    GlobalEvents.unsubscribe(this.handleDownloadApp)
    GlobalEvents.unsubscribe(this.handleDownloadAppByGameapi)
  }

  /**
   * 是否展示领取奖励条件的文案
   * 条件为
   * 1.展示为全部会员
   * 2.领取条件为首充会员
   */
  private get isShowRewardsConditionLiteral() {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return false
    }
    const { userDisplay, rewardsCondition } = channelInfo
    const { AllUser } = UserDisplayList
    const { FirstDeposit } = RewardsCondition
    if (userDisplay === AllUser && rewardsCondition === FirstDeposit) {
      return true
    }
    return false
  }

  /**没有在APP内登录过 */
  private static get isNotLoginApp() {
    const { userInfos } = useMainStore()
    return userInfos?.loginOsType === LoginOsType.NotLoginApp
  }

  /**是否展示下载APP弹出框来自渠道 */
  private static get showDownloadAppByChannel() {
    const { channelInfo } = useChannelStore()

    const { isFirstDeposit } = useMainStore()
    if (!channelInfo) {
      return false
    }

    const { downloadDisplay, userDisplay, viewDownload, isForceDownload } =
      channelInfo
    const { Open } = ChannelStatusList
    const { AllUser } = UserDisplayList
    const { APP } = ViewDownloadList
    const { Not } = IsForceDownloadList
    const openPartialCondition =
      downloadDisplay === Open &&
      viewDownload === APP &&
      Not === isForceDownload

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 展示的用户群体为全部
     */
    if (openPartialCondition && userDisplay === AllUser) {
      return true
    }

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 用户的充值次数大于或者大于1次（仅限已首充会员）
     */
    if (openPartialCondition && isFirstDeposit) {
      return true
    }

    return false
  }
  /**是否能够打开下载APP的提示通过渠道配置 */
  static get canOpenDownloadAppByChannel() {
    const { hiddenDownload } = useMainStore()
    const { hasClickDownloadAPPByChannel } = useDownloadAppStore()
    return (
      !hasClickDownloadAPPByChannel &&
      this.isNotLoginApp &&
      !hiddenDownload &&
      !!this.showDownloadAppByChannel
    )
  }

  /**获取下载APP的格式化的金额-通过渠道配置 */
  private get formatDownloadAppRewardByChannel() {
    const { userInfos, downloadAppRewardByChannel } = useMainStore()
    const formatedDownloadAppRewardByChannel = currencyFormatter(
      downloadAppRewardByChannel || 0,
      {
        code: userInfos?.currency,
        precision: 2,
        symbol: ''
      }
    )
    return formatedDownloadAppRewardByChannel
  }

  /**创建一个下载APP弹出框通过渠道设置 */
  private async downloadAppByChannel() {
    const {
      downloadAppRewardByChannel,
      isShowDownloadRewardsByChannel,
      isForceDownloadByChannel
    } = useMainStore()
    const formatDownloadAppRewardByChannel =
      this.formatDownloadAppRewardByChannel

    await useDownloadAppStore().getDownloadConfig()
    const { urlslist } = useDownloadAppStore()
    const { t } = useI18n()
    Modal.create({
      destroyOnClose: true,
      title: (
        <div class={style.titleFromChannel}>
          <icon-sprite sprite={createSvgSprite('comm_icon_zyts')} />
          <span class={style.title}>
            {t('lobby.rightBar.titleFromChannel')}
          </span>
        </div>
      ),
      closable: !isForceDownloadByChannel || this.isFromCallGameApi,
      hiddenBtns: true,
      class: style.downloadModal,
      width: BusinessUtils.px2rem(600),
      content: () => {
        return (
          <div class={style.downloadContent}>
            <div class={style.wrapperChannel}>
              <span class={style.channelTip1}>
                {t('lobby.rightBar.channelTip1')}
              </span>
              {!!downloadAppRewardByChannel && isShowDownloadRewardsByChannel && (
                <span class={style.channelTip2}>
                  {t('lobby.rightBar.channelTip2')}
                  <b>{formatDownloadAppRewardByChannel}</b>
                  {t('lobby.rightBar.channelTip3')}
                  {this.isShowRewardsConditionLiteral &&
                    t('lobby.rightBar.channelTip5')}
                </span>
              )}
            </div>
            <div
              class={style.wrapperUrlslistByChannel}
              ref={'downloadBtnRef'}
              data-clipboard-text={this.getClipBoardParams}
            >
              {urlslist?.map((item, index) => {
                let count = 0
                const appVerson = this.getAppVersion(item)
                return (
                  <Button.Tsx
                    key={item.name}
                    type="primary"
                    ghost={!(index % 2)}
                    class={style.downloadItem}
                    onClick={() => {
                      this.handlerDownloadBtn(item, count)
                      count++
                    }}
                  >
                    {!!downloadAppRewardByChannel &&
                      isShowDownloadRewardsByChannel && (
                        <TooltipsComp
                          charge={1}
                          top={BusinessUtils.px2rem(-20)}
                          text={formatDownloadAppRewardByChannel}
                        />
                      )}
                    {isMobile().apple.device ? (
                      <icon-sprite
                        class={style.appIcon}
                        sprite={createSvgSprite('comm_icon_xz-ios')}
                      />
                    ) : (
                      <my-img
                        class={style.appImg}
                        src={
                          '/lobby_asset/common/web/home/comm_icon_xz-android.png'
                        }
                      />
                    )}

                    <span class={style.appName}>
                      {appVerson}
                      {item.name}
                    </span>
                  </Button.Tsx>
                )
              })}
            </div>
          </div>
        )
      }
    })
    this.$nextTick(this.createClipBoard)
  }
  render() {
    return <template></template>
  }
}
