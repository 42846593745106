import { assetsPath } from './business-utils/assets'
import { createOssAssetsPathOrigialRetry } from './business-utils/retry'
import { loadImage } from './Loader'
import { useServiceStore, xInitValue, yInitValue } from '@/views/service/store'
/**
 * 设置拖动移动位置
 * @param element
 */
const delta = { x: 0, y: 0, ox: 0, oy: 0 }

/**
 * @description 三方配置的按钮位置模式，一共有两种，分别是左侧和右侧
 * 三方配置的按钮的位置是否是左侧模式
 * */
let isLeftSideModeByThirdConfig = true

const moveTo = (element: HTMLElement) => {
  if (!element) return
  const body_width = document.body.clientWidth
  const body_height = document.body.clientHeight
  const dom_width = element.clientWidth
  const dom_height = element.clientHeight

  // 如果是左侧模式
  if (isLeftSideModeByThirdConfig) {
    if (delta.x + dom_width > body_width) {
      element.style.left = body_width - dom_width + 'px'
    } else {
      element.style.left = delta.x < 0 ? 0 + 'px' : delta.x + 'px'
    }
  } else {
    if (delta.x + dom_width > body_width) {
      element.style.right = body_width - dom_width + 'px'
    } else {
      element.style.right = delta.x < 0 ? 0 + 'px' : delta.x + 'px'
    }
  }

  if (delta.y + dom_height > body_height) {
    element.style.bottom = body_height - dom_height + 'px'
  } else {
    element.style.bottom = delta.y < 0 ? 0 + 'px' : delta.y + 'px'
  }
}
export function setDragToMove(element: HTMLElement): void {
  if (!element) return
  element.setAttribute('draggable', 'true')
  element.addEventListener('dragstart', (e: DragEvent) => {
    const rect = element.getBoundingClientRect()
    delta.ox = e.pageX - rect.left
    delta.oy = e.pageY - rect.top
  })
  element.addEventListener('drop', (e: DragEvent) => {
    const chatIframe = document.querySelector(
      '[id="chat-widget-minimized"]'
    ) as HTMLElement
    chatIframe && (chatIframe.style.pointerEvents = 'auto')
    e.preventDefault()
    // 检测到拖动事件结束后，需要将拖动的位置记录下来，作为下次按钮的初始值
    const { setThirdServiceButtonCoordinate } = useServiceStore()
    setThirdServiceButtonCoordinate({
      x: delta.x,
      y: delta.y
    })
  })
  element.addEventListener('drag', (e: DragEvent) => {
    if (e.pageX === 0 && e.pageY === 0) {
      return
    }
    const body_width = document.body.clientWidth
    const body_height = document.body.clientHeight
    const chatIframe = document.querySelector(
      '[id="chat-widget-minimized"]'
    ) as HTMLElement
    chatIframe && (chatIframe.style.pointerEvents = 'none')
    const rect = element.getBoundingClientRect()
    if (isLeftSideModeByThirdConfig) {
      delta.x = e.pageX - delta.ox
    } else {
      delta.x = body_width - e.pageX - delta.ox - rect.width / 2
    }

    delta.y = body_height - e.pageY - delta.oy - rect.height / 2
    moveTo(element)
    e.preventDefault()
  })

  document.addEventListener(
    'dragover',
    function (event) {
      // 防止拖拉效果被重置，允许被拖拉的节点放入目标节点
      event.preventDefault()
    },
    false
  )

  element.addEventListener('touchstart', (e: TouchEvent) => {
    e.stopPropagation()
    const rect = element.getBoundingClientRect()
    delta.ox = e.touches[0].pageX - rect.left
    delta.oy = e.touches[0].pageY - rect.top
  })
  element.addEventListener('touchmove', (e: TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const body_width = document.body.clientWidth
    const body_height = document.body.clientHeight
    const rect = element.getBoundingClientRect()
    if (isLeftSideModeByThirdConfig) {
      delta.x = e.touches[0].pageX - delta.ox
    } else {
      delta.x = body_width - e.touches[0].pageX - delta.ox - rect.width / 2
    }

    delta.y = body_height - e.touches[0].pageY - delta.oy - rect.height / 2
    moveTo(element)
  })
  element.addEventListener('touchend', (e: TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const { setThirdServiceButtonCoordinate } = useServiceStore()
    setThirdServiceButtonCoordinate({
      x: delta.x,
      y: delta.y
    })
  })
}

export async function createMaskToDrag(element: HTMLElement): Promise<void> {
  initDeltaByThirdConfig(element)
  initDeltaByStorage()
  const moveIcon = await createOssAssetsPathOrigialRetry(async (origin) => {
    const src = assetsPath(
      '/lobby_asset/common/web/support/icon_kf_drag.png',
      origin
    )
    return loadImage(src)
  }).catch(() => document.createElement('img'))

  if (moveIcon instanceof HTMLImageElement) {
    moveIcon.style.cssText =
      'position: absolute; width:30px;height:30px;right:49px;bottom:54px;cursor: move;'
    element.appendChild(moveIcon)
    setDragToMove(element)
    const hanleListenChange = () => {
      if (element.clientWidth > 200) {
        moveIcon.style.display = 'none'
      } else {
        moveIcon.style.display = 'block'
        moveTo(element)
      }
    }
    const mutaionObserver = new MutationObserver(hanleListenChange)
    mutaionObserver.observe(element, { attributes: true })
  }
}

/**初始化x坐标和y坐标的值通过本地数据 */
const initDeltaByStorage = () => {
  const {
    thirdServiceButtonCoordinate: { x, y }
  } = useServiceStore()
  if (x === xInitValue && y === yInitValue) {
    return
  }
  delta.y = y
  delta.x = x
}
/**
 * @description 初始化客服的位置，根据三方的配置
 */
const initDeltaByThirdConfig = (element: HTMLElement) => {
  const styleElement = element.style
  /**
   * @description left, right, bottom这些值属性值，都是通过三方传进来的，避免报错影响
   */
  try {
    const { left, right, bottom } = styleElement
    if (bottom && parseInt(bottom)) {
      delta.y = parseInt(bottom)
    }
    if (left && parseInt(left)) {
      delta.x = parseInt(left)
    }
    if (right && parseInt(right)) {
      delta.x = parseInt(right)
      isLeftSideModeByThirdConfig = false
    }
  } catch (error) {}
}
