import { Base, Component, Prop, Watch } from '@/vue-property-decorator'
import {
  BrandLogoType,
  GuideLanguage,
  SuggestDownloadConfig
} from '@/api/common/type'
import { GlobalConst } from '@/context'
import { throttle } from 'lodash'
import { useHomeStore } from '@/store/home'
import { useMainStore } from '@/store/index'
import { windowConfig } from '@/utils/window'
import FoldableBox from './components/foldable-box'
import MainContent from './components/main-content'
import style from './style.module.scss'
export type DownContext = {
  /** 类型：1自定义上传 2.图文结合 */
  renderType: BrandLogoType
  /** 品牌名称 */
  brandName: string
  /** 环境配置 */
  ctxConfig: SuggestDownloadConfig
  /** 多语言配置,还需要匹配多语言,因为不同的多语言有不同的图文,图文的渲染完全依赖多语言配置 */
  langConfig: GuideLanguage
  /** 版心(如青蓝版的布局需要版心) */
  centerMaxWidth?: string
  /** H5引导下载开关 0关闭1开启 */
  isShowDownloadBar: boolean
}
type State = {
  /** 是否访问 */
  isVisible: boolean
}
type Position = `${'h5' | 'pc'}_${SuggestDownloadConfig['placement']}`
type Props = {
  /** 该组件放置位置,支持传入数组,多位置适配 */
  position: Position[] | Position
  /** 版心(如青蓝版的布局需要版心) */
  centerMaxWidth?: string
}
/** 引导下载 */
@Component<SuggestDwonload>({ name: 'SuggestDwonload' })
export default class SuggestDwonload extends Base<State, Props> {
  private mainStore = useMainStore()
  private homeStore = useHomeStore()
  @Prop({ required: true })
  private readonly position!: Props['position']
  @Prop({ required: false })
  private readonly centerMaxWidth!: Props['centerMaxWidth']
  state: State = {
    isVisible: false
  }
  /** 获取当前下载广告的配置环境 */
  get downContext(): DownContext {
    const { isWeb, brandLogoInfos } = this.mainStore
    if (!brandLogoInfos) return null as unknown as DownContext
    /** 根据不同的条件,筛选出对应的配置项字段  */
    // 使用当前的场景生成key
    const configType: `${'web' | 'h5'}_${'1' | '2'}` = `${
      isWeb ? 'web' : 'h5'
    }_${brandLogoInfos.type}`
    // 将key与config进行映射(策略模式)
    const configMapper: Record<typeof configType, SuggestDownloadConfig> = {
      web_1: brandLogoInfos.customizeWebConfig,
      web_2: brandLogoInfos.webConfig,
      h5_1: brandLogoInfos.customizeH5Config,
      h5_2: brandLogoInfos.h5Config
    }
    // 取得当前最适合的config对象
    const ctxConfig = configMapper[configType]
    /** 在多语言数组中,找出当前语言匹配的项 */
    const serveLang = GlobalConst.ServiceLanguageMap[this.mainStore?.language]
    const langConfig = ctxConfig?.downloadLogoUrlLanguage?.find((item) => {
      return item.key.includes(serveLang)
    }) as GuideLanguage
    // 环境四个参数,缺一不可,因此,只要最后一层嵌套的语言环境缺失,直接全部为null
    if (!langConfig) return null as unknown as DownContext
    return {
      centerMaxWidth: this.centerMaxWidth,
      renderType: brandLogoInfos.type,
      brandName: brandLogoInfos.brandName,
      ctxConfig,
      langConfig,
      isShowDownloadBar: ctxConfig.isShowDownloadBar
    }
  }

  /** 是否展示本组件,终端类型与摆放位置居匹配上,才展示该组件 */
  get isExhibitThis() {
    //极速包不展示
    if (windowConfig.package.isLite) {
      return false
    }

    if (!this.downContext) return false
    const device = this.mainStore.isWeb ? 'pc' : 'h5'
    const configPosition = `${device}_${this.downContext.ctxConfig.placement}`
    // 字符串和数组都支持includes所以没有必要合成数组
    if (!this.position.includes(configPosition as Position)) return false
    // 根据渠道cid 从接口拉取是否显示状态
    if (this.mainStore.hiddenDownload) return false
    return true
  }

  @Watch('isExhibitThis')
  @Watch('$route.path')
  watchRoute() {
    if (this.$route.path !== '/home/game') {
      // 必须要环境先加载完之后的路由切换才能触发它.
      if (this.downContext) this.handleLeaveHomePage()
    } else {
      this.handleGotoHomePage('/home/game')
    }
  }

  /** 离开首页,无过渡,瞬间隐藏广告 */
  private handleLeaveHomePage = throttle(() => {
    // 用户主动点击过关闭了,这次事件不再触发
    if (this.homeStore.isUserCloseSuggest) return
    this.state.isVisible = false
    ;(this.$refs['foldableBoxRef'] as FoldableBox)?.handleHidden(false)
  }, 300)
  /** 回到首页,有过渡,缓慢显示广告
   * @param {"/home/game"|string} from  来自route变化触发,或者是来自search页面触发的返回按钮,才执行后面的内容
   */
  private handleGotoHomePage = throttle((fromRoute?: '/home/game' | string) => {
    // 如果不是由 /home/game 路由下,触发该事件,则不让显示广告
    if (fromRoute !== '/home/game') return
    // 用户主动点击过关闭了,这次事件不再触发
    if (this.homeStore.isUserCloseSuggest) return
    this.state.isVisible = true
    this.$nextTick(() => {
      ;(this.$refs['foldableBoxRef'] as FoldableBox)?.handleShow(false)
    })
  }, 300)

  created() {
    // 绑定事件车和解绑事件车.
    this.$root.$on('leaveHomePage', this.handleLeaveHomePage)
    // 按原理来讲.可以不用绑定该事件的,但未知原因,如果不监听该事件,从搜索页面返回并无法触发下载横幅再次出现
    this.$root.$on('gotoHomePage', this.handleGotoHomePage)
    this.$once('hook:beforeDestroy', () => {
      this.$root.$off('leaveHomePage', this.handleLeaveHomePage)
      this.$root.$off('gotoHomePage', this.handleGotoHomePage)
    })
  }

  mounted(): void {
    const downContext = this.downContext
    if (!downContext) {
      return
    }
    const { isShowDownloadBar } = downContext
    //未开启下载功能
    if (!isShowDownloadBar) {
      return
    }
    this.state.isVisible = true
    // 如果不在首页,直接就不展示
    if (this.$route.path !== '/home/game') return
    setTimeout(() => {
      if (this.homeStore.isUserCloseSuggest === true) return
      ;(this.$refs['foldableBoxRef'] as FoldableBox)?.handleShow(true)
      // 延迟展示出来.避免卡掉动画效果.
    }, 300)
  }

  /** 处理关闭事件 */
  private handleClose = () => {
    ;(this.$refs['foldableBoxRef'] as FoldableBox)?.handleHidden(true, true)
    this.state.isVisible = false
    // 本次会话持久化用户的关闭行为,不会再弹出广告.
    const { setIsUserCloseSuggest } = useHomeStore()
    setIsUserCloseSuggest(true)
  }

  render() {
    if (!this.isExhibitThis) return <template></template>
    return (
      <FoldableBox
        // 给一个ID名,方便页面其它内容查找到它
        id={`suggest-download-${this.position}`}
        class={{
          [style['suggest-download']]: true,
          'is-close': this.homeStore.isUserCloseSuggest
        }}
        ref="foldableBoxRef"
        downContext={this.downContext}
      >
        <MainContent
          onClose={this.handleClose}
          downContext={this.downContext}
        ></MainContent>
      </FoldableBox>
    )
  }
}
