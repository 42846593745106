export enum RouteName {
  ROOT = 'root',
  /**
   * 首页游戏列表
   */
  GAME = 'game',
  /**
   * wg自营体育
   */
  WG_GAME = 'WGGame',
  /**
   * 子游戏界面
   */
  SUB_GAME = 'subGame',
  /**
   * 查询游戏
   */
  GAME_SEARCH = 'gameSearch',
  /**
   * 内嵌游戏界面
   */
  GAME_EMBEDDED = 'gameEmbedded',
  /**
   * 活动中心
   */
  EVENT = 'event',
  EVENT_ITEM = 'event/item',
  /**
   * 任务中心
   */
  TASK = 'task',
  /**
   * 实时返水
   */
  CASHBACK = 'cashback',
  /**
   * 推广赚钱
   */
  PROMOTE = 'promote',
  /**
   * 代理转帐
   */
  PROXYTRANSFER = 'proxyTransfer',
  /**
   * 代理转帐
   */
  MYTRANSFERRECORD = 'myTransferRecord',
  /**
   * 领取记录
   */
  PICKUPRECORD = 'pickUpRecord',
  /**
   * vip中心
   */
  VIP = 'vip',
  /**
   * 奖励提取
   * @deprecated 已废弃，使用CANRECEIVE跳转
   */
  AWARD = 'award',
  /**
   * 个人中心-设置
   */
  SETTING = 'setting',
  /**
   * 个人中心-报表
   */
  REPORT = 'report',
  /**
   * 个人中心-安全中心
   */
  SECURITY = 'security',
  /**
   * 个人中心-关于我们
   */
  ABOUT_US = 'about-us',
  /**
   * 个人中心-有奖反馈
   */
  FEEDBACK = 'feedback',
  /**
   * 余额宝
   */
  YUEBAO = 'yuebao',
  /**
   * 中心钱包
   */
  CENTER_WALLET = 'center-wallet',
  /**
   * 联系客服
   */
  CUSTOMER_SERVICE = 'service',
  /**
   * 财务[提现]
   */
  WITHDRAW = 'withdraw',
  WITHDRAW_DETAILS = 'withdraw-details',
  AUDIT_DETAILS = 'audit-details',

  /**
   *
   */
  CUSTOMERS_QUESTION_DETAIL = 'customers-question-detail',
  /**
   * 公告通知跑马灯
   */
  NOTICE = 'notice',
  /**
   * 奖励领取记录
   */
  RECORDS = 'records',
  /** 待领取页面 */
  CANRECEIVE = 'canReceive',
  /**
   * 自定义申请记录
   */
  RECEIVERECORDS = 'receive-records',
  /**
   * 竞猜记录
   */
  BETTING_RECORDS = 'betting_records',
  /**
   * 引导过度页，一个新的import Splash from '@/splash' 的实例
   */
  PROMOTE_GAME = 'promote-game',
  /*
   * demo 快速注册页面
   */
  DEMO_REGISTER = 'demo-register',
  /**
   * 版本更新
   */
  VERSION_UPDATE = 'version-update',
  /**
   * 错误提示页
   */
  ERROR_TEXT = 'error-text',
  /**
   * 404
   */
  NOT_FOUND = '404',
  /**
   * 自营体育
   */
  SPORTS_HOME = 'sports-subgame',
  /**
   * 体育详情
   */
  SPORTS_DETAIL = 'sports-detail',
  /**
   * 串关列表
   */
  SPORTS_PARLAYBET = 'sports-parlay-bet',
  /**
   * 投注记录
   */
  SPORTS_BETRECORD = 'sports-bet-record',

  DEVTOOLS = 'dev-tools',
  IFRAME_ROUTE = 'iframe-route',
  /**
   * 我的页面
   */
  MINE = 'mine',
  /**
   * 公积金
   */
  RECHARGE_FUND = 'rechargeFund',
  /**
   * 登录设备
   */
  DEVICE_INFO = 'deviceInfo'
}
/**
 *
 * 需要引导登录的页面 弃用，
 * 用meta.private = true 代替
 * @deprecated
 */
export const needGuide2LoginRouteName: RouteName[] = []

/**
 * 未登录需要直接到首页的页面，例如一些
 * @deprecated
 */
export const needBack2Home = []
