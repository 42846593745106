/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-10 15:55:20
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\ReporterAbst.ts
 * @Description: 各平台 & 各种方式上报错误;
 */
import {
  EStategyType,
  EUploadPlatform,
  TRuleSuiteResult,
  TUploadPayload
} from './type'
import { deepObjectDiff } from '../utils'

export abstract class ReportAbst {
  protected platformName: string
  private timer: { [key: string]: NodeJS.Timeout } = {}
  private uploadPayload: {
    [key: string]: {
      origin: Record<string, unknown>
      diff: Array<{ [key: string]: Record<string, unknown> }>
      relationIds: Array<string>
    }
  } = {}

  constructor(platformName: EUploadPlatform) {
    this.platformName = platformName
    this.init()
  }

  /**
   * 触发上报，这里会根据规则判断和策略是否需要上报
   * @param ruleSuite
   */
  public action(rs: TRuleSuiteResult) {
    const { strategy, payload, extParams, eventName, eventType } = rs
    const currentPayload = {
      payload,
      extParams,
      eventName,
      eventType
    }
    // 非默认模式，需要节流，防抖等操作
    if (strategy.name !== EStategyType.default && strategy.option) {
      // 事件名或事件类型作为 key
      const key =
        strategy.option.targetBy === 'eventName' ? eventName : eventType
      // 防抖模式
      if (strategy.name === EStategyType.debounce) {
        if (this.timer[key]) {
          clearTimeout(this.timer[key])
        }
        this.timer[key] = setTimeout(() => {
          // 上报最后一次的数据
          this.upload(currentPayload)
          delete this.timer[key]
        }, strategy.option?.duration)
        return
      }
      // 节流模式
      if (strategy.name === EStategyType.throttle) {
        if (this.timer[key]) {
          return
        }
        this.upload(currentPayload)
        this.timer[key] = setTimeout(() => {
          delete this.timer[key]
        }, strategy.option?.duration)
      }
      // 合并模式，一段时间内的上报数据会被合并，并添加 diff 字段
      if (strategy.name === EStategyType.merge) {
        if (!this.timer[key]) {
          const relationIds = strategy.option.relationKey
            ? [payload[strategy.option.relationKey] as string]
            : []
          this.uploadPayload[key] = {
            origin: payload,
            diff: [],
            relationIds
          }
          this.timer[key] = setTimeout(() => {
            this.upload({
              payload: {
                ...this.uploadPayload[key].origin,
                __diff: this.uploadPayload[key].diff,
                __relationIds: this.uploadPayload[key].relationIds
              },
              extParams,
              eventName,
              eventType
            })
            delete this.uploadPayload[key]
            delete this.timer[key]
          }, strategy.option?.duration)
        }
        const originPayload = this.uploadPayload[key].origin
        this.uploadPayload[key].diff.push(
          deepObjectDiff(originPayload, payload)
        )
        if (strategy.option.relationKey) {
          this.uploadPayload[key].relationIds.push(
            payload[strategy.option.relationKey] as string
          )
        }
      }
      return
    }
    // 默认模式，直接上报
    this.upload(rs)
  }

  /**
   * 初始化（这里包含初始化第三方SDK相关的操作，例如初始化GA添加KEY等....）
   */
  protected abstract init(): void

  /**
   * 上传数据
   * @param ruleSuite 上报数据
   */
  protected abstract upload(data: TUploadPayload): void
}

export default ReportAbst
