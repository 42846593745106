/* eslint-disable @typescript-eslint/no-explicit-any*/
import { PatternCode } from './patternCode'
import { useMainStore } from '../../../../../store/index/index'
import Skin, { ThemeSkinType } from '@/controller/Skin'
/** 一键控制调试模式 */
const isDebug = window.location.search.includes('isDebug=true')
/**
平台：(不在热门下的)
默认素材 https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/EA/3/2/default.png
默认无log素材 https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/EA/3/2/default_unLogo.png
平台:(在热门下的)
自定义素材:https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/080/EA/9/1/custom_CNY.png
平台(热门图素材) https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/080/EA/hot/9/1/custom_CNY.png

子游戏:(在不在热门下的都一样)
自游戏默认:https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/g/EA/14/3/140001/default.png
自定义:https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/g/080/HZ/13/11/5001/custom.png
无logo的原图:https://xvo35z-024-ttt.oss-ap-southeast-1.aliyuncs.com/game_pictures/g/CL/103/3/1030001/default_unLogo.png
 */
/** 游戏类型 */
export enum GameType {
  /** 平台 */
  Platform = 'p',
  /** 个体游戏 */
  Individual = 'g'
}

/** 拼接的参数 */
export type CombineParams = {
  /** 卡片原始数据(用于方便调试,无实际作用) */
  cardData: any
  /** 渲染的数据(用于方便调试,无实际作用) */
  renderName: string
  /** 游戏类型 */
  gameType: GameType
  /** 平台id */
  platformId: number
  /** 游戏类别id */
  gameCategoryId: number
  /** 游戏类别 */
  gameId?: number
  /** 是否自定义图片 */
  isCustomIcon: boolean
  /** 是否美术上传的原始图片 */
  isOriginImg?: boolean
  /** 是否显示热门图标 */
  isExhibitHotIcon?: boolean
}

/** 拼装地址 */
export class PictureUrlCore {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  private static instance: PictureUrlCore
  private static getInstance() {
    if (!this.instance) {
      this.instance = new PictureUrlCore()
    }
    return this.instance
  }

  /** 对外暴露的静态方法,用来将卡片数据解析成资源路径 */
  public static run(combineParams: CombineParams) {
    /** 生成最终的图片资源路径 */
    return this.getInstance().generateIconPath(combineParams)
  }

  /** 图标形状代号控制器 */
  private patternCode = new PatternCode()
  /** 站点code */
  private siteCode = useMainStore().siteCode
  /** 图片资源域名地址 */
  private ossHost = useMainStore().ossHost

  /** 获得站点信息拼接字符串 */
  private getSiteCodeStr(params: CombineParams) {
    const { isCustomIcon, isOriginImg, gameType } = params
    if (gameType === GameType.Individual) {
      // 如果是子游戏,并且是自定义,则拼接站点,否则其它情况都不拼接.
      if (isCustomIcon) {
        return `/${this.siteCode}`
      } else {
        return ''
      }
    }
    // 如果是平台,[自定义||非美术原图]需要拼接站点,否则也不拼
    if (isCustomIcon || !isOriginImg) {
      return `/${this.siteCode}`
    } else {
      return ''
    }
  }

  /** 获得热门形态的平台资源 */
  private getPlatformHotStr(params: CombineParams) {
    const { isExhibitHotIcon } = params
    return isExhibitHotIcon ? '/hot' : ''
  }

  /** 获得图片名称
   * @param patternCode 卡片类型
   */
  private getImgName(params: CombineParams, patternCode: string) {
    const { isOriginImg, isCustomIcon, gameType, isExhibitHotIcon } = params
    const currency = useMainStore().userInfos?.currency
    /** 处理单个游戏 单个游戏不考虑拼接币种 */
    const handleIndividual = () => {
      if (isCustomIcon) {
        return 'custom.png'
      } else {
        // 如果是已经改造过的版式,游戏直接使用默认图片(有拼接上logo)
        if (this.reconstructSkin.includes(Skin.skinType as any)) {
          return 'default.png'
        } else {
          // 还未改造的版式,如果是【方型非WG的】卡片,需要拼接logo. 其它的所有卡片(WG的方型卡片,垂直卡片,横向卡片)都不拼装logo,由代码拼装.
          // patternCode==="CL" 表示【方型游戏卡片或方型热门平台(同方型游戏卡片走相同的渲染方式)】
          // platformId !== SelfOpertedId 表示【非WG的卡片】
          // 两个条件合起来就是 【方型非WG的游戏卡片】
          // if (patternCode === 'CL' && platformId !== SelfOpertedId) {
          //   return 'default.png'
          // } else {
          //   return 'default_unLogo.png'
          // }
          // 未改造的欧美版子游戏和平台都不带logo,因为在代码层面,在名字下方,有品牌名称,平台在左边有logo图标
          if (patternCode === 'EA') {
            return 'default_unLogo.png'
          }
          // 现在方型仅有wg还在用未拼接logo的了,直接考虑从代码层面完全取消掉所有方形logo的拼接.
          else {
            return 'default.png'
          }
        }
      }
    }
    /** 处理平台游戏 平台游戏需要考虑币种的拼接 */
    const handlePlatform = () => {
      // 如果是[自定义图片]或[未使用美术默认图],则拼接上币种
      if (isCustomIcon) {
        return `custom_${currency}.png`
      } else {
        // 如果是已经改造过的版式,平台的逻辑上直接使用整图
        if (this.reconstructSkin.includes(Skin.skinType as any)) {
          if (isOriginImg) {
            return 'default.png'
          } else {
            return `default_${currency}.png`
          }
        }
        // 如果是未改造过的版式
        else {
          // 如果是使用热门列表类型的平台数据,则需要拼接上背景
          if (isExhibitHotIcon) {
            // 当前未处理过的垂直的热门卡片,不能带logo,因为代码层面本来就有拼接logo了
            if (patternCode === 'EA') {
              if (isOriginImg) {
                return 'default_unLogo.png'
              } else {
                return `default_unLogo_${currency}.png`
              }
            }
            // 除了EA/hot(竖版,旧代码有logo,所以不带logo),当前其实也只有CL/hot(方型,需要带上logo)
            else {
              if (isOriginImg) {
                return 'default.png'
              } else {
                return `default_${currency}.png`
              }
            }
          } else {
            // 如果是垂直版的卡片,仍然把背景带上,因为旧版的大部分都是有带背景的
            if (patternCode === 'EA') {
              if (isOriginImg) {
                return 'default_unLogo.png'
              } else {
                return `default_unLogo_${currency}.png`
              }
            }
            // 如果是横向卡片的资源,则使用图片裸图,什么都不拼
            else {
              if (isOriginImg) {
                return 'material.png'
              } else {
                return `material_${currency}.png`
              }
            }
          }
        }
      }
    }
    // 执行名称的生成
    if (gameType === GameType.Individual) {
      return handleIndividual()
    } else {
      return handlePlatform()
    }
  }

  /** 获得游戏id字符串 */
  private getGameIdStr(params: CombineParams) {
    const { gameId, gameType } = params
    // 如果是平台类型卡片,是没有游戏id的
    if (gameType === GameType.Platform) {
      return ''
    }
    return `/${gameId}`
  }

  /** 已重构的版式,目前只有经典,欧美,欧美简约 */
  private reconstructSkin = [
    ThemeSkinType.NORMAL,
    ThemeSkinType.EUROPEAN_AMERICAN,
    ThemeSkinType.STAKE
  ]

  /** 注:该函数不要删除,打印调试数据(仅在调试的时候打开它) */
  // private printDebuggingData(params: CombineParams) {
  //   // 调试时,在此处输入特定的名称,就能打印出相应的数据
  //   const checkNames = ['CQ9 Slots', 'WG Slots']
  //   if (
  //     checkNames.includes(params?.cardData?.gameName) ||
  //     checkNames.includes(params.cardData.platName)
  //   ) {
  //     console.warn(
  //       `printCardData-------------------------55555555`,
  //       JSON.parse(JSON.stringify(params))
  //     )
  //   }
  // }

  /** 生成成图片路径 */
  private generateIconPath(params: CombineParams) {
    const { gameType, gameCategoryId, platformId } = params
    /** 站点code字符串 '/080'或者'' */
    const siteCodeStr = this.getSiteCodeStr(params)
    /** 形状类型代号 EA|HZ等 (平台同时生成热门图和普通图,需要改到它,但它还是不要随便改了.因为在最后游戏名称中,它耦合了一系列的逻辑,乱改容易出事,还是保证该函数的职责单一,只生成一个路径更好) */
    const patternCode = this.patternCode.run(params)
    /** 平台热门字符串 */
    const platformHotStr = this.getPlatformHotStr(params)
    /** 游戏id字符串 */
    const gameIdStr = this.getGameIdStr(params)
    /** 游戏名称 */
    const gameName = this.getImgName(params, patternCode)
    // 注:仅在调试的时候才开启该注释
    // this.printDebuggingData(params)
    let imgUrl = `${this.ossHost}game_pictures/${gameType}${siteCodeStr}/${patternCode}${platformHotStr}/${platformId}/${gameCategoryId}${gameIdStr}/${gameName}`
    // 调试代码,当需要进行调试的时候,就在页面路径上加入地址信息
    if (isDebug) {
      // 此处是在拼装上相应的参数逻辑,方便排查
      imgUrl += '?----------'
      for (const key in params) {
        if (['cardData', 'renderName'].includes(key)) {
          continue
        }
        imgUrl += `${key}_${params[key as keyof typeof params]}------`
      }
      imgUrl += `skinType_${Skin.skinType}`
    }
    return imgUrl
  }
}
