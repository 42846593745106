import { Entity, Model, from, nullable, parse, type } from 'typox'
import { _PlatformCategoriesItemInter } from './platformCategoriesItemInter'
import { gameOssDomin } from '../util/util'
import { numberToBool } from '@/service/extensions'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 平台游戏信息 */
@Entity()
export default class _PlatformCategoriesItem
  extends Model
  implements _PlatformCategoriesItemInter
{
  /** 类型守卫 */
  public static isThisType(data: Any): data is _PlatformCategoriesItem {
    const keys = ['platformIcon', 'brand', 'slogan']
    if (keys.every((key) => Reflect.has(data, key))) {
      return true
    } else {
      return false
    }
  }

  /** web端使用的唯一id */
  @type(String)
  @parse((_, item) => `${item?.t4}_${item?.t9}_${item?.t1}`)
  webId = ''

  /** 游戏 id */
  @from('t1')
  @type(Number)
  @nullable()
  gameId = 0

  /** 是否支持试玩 0-否, 1-是 */
  @from('t2')
  @type(Boolean)
  @parse(numberToBool)
  demoModeSupport = false

  /** 自定义图片 0-否, 1-是  */
  @from('t3')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isCustomIcon = false

  /** 是否美术上传的原始图片 0-否, 1-是 */
  @from('t19')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isOriginImg = false

  /** 游戏类别id */
  @from('t4')
  @type(Number)
  gameCategoryId = 0

  /** 平台logo */
  @from('t5')
  @parse(gameOssDomin)
  @type(String)
  @nullable()
  platformLogo = ''

  /** 维护状态 0-否, 1-是  */
  @from('t6')
  @type(Boolean)
  @parse(numberToBool)
  maintainStatus = false

  /** 最小准入余额 */
  @from('t7')
  @type(Number)
  minimumEntryBalance = 0

  /** 平台图标  不在此处解析了,因为后台返回的数据结构的问题,有些数据还需要从别的地方取,再加上它比较消耗性能,按需解析,避免重复*/
  // @from('t8')
  // @parse((val, data) => CombinePictureUrl.parsePlatformItem(data))
  // @type(String)
  // @nullable()
  platformIcon = ''

  /** 平台 id */
  @from('t9')
  @type(Number)
  platformId = 0

  /** 平台名称 */
  @from('t10')
  @type(String)
  @nullable()
  platformName = ''

  /** 宣传语 */
  @from('t11')
  @type(String)
  @nullable()
  slogan = ''

  /** 游戏打开方式 0-内嵌, 1-外链 */
  @from('t12')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isExternalLink = false

  /** 平台类型名称 */
  @from('t15')
  @type(String)
  @nullable()
  brand = ''

  /** 是否主播号 0-否, 1-是 */
  @from('t16')
  @type(Boolean)
  @parse(numberToBool)
  anchorSupport = false

  /** 自定义热门图
   * @deprecated 已废弃,请使用 normalHotIcon 代替,语义化更准确.
   */
  // @from('t17')
  @from('t17')
  @parse(gameOssDomin)
  @nullable()
  customHotIcon = ''

  /** 亚太经验下,热门平台图片  不在此处解析了,因为后台返回的数据结构的问题,有些数据还需要从别的地方取,再加上它比较消耗性能,按需解析,避免重复*/
  // @parse((val, data) => CombinePictureUrl.parseNormalHotPlatformItem(data))
  // @type(String)
  // @nullable()
  normalHotIcon = ''

  /** 是否三合一图(图片/logo/背景色) 0-否，1是
   * @deprecated 新的游戏路径拼接方式中,它已无用
   */
  @from('t18')
  @type(Boolean)
  @parse(numberToBool)
  isCombinate = false
}
