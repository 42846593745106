import { AutoVisitorStatus, LinkType, ThemeSkinType } from '@/api/common/type'
import { CustomPlatformId, GameCategoryIds } from '@/views/game/consts'
import { GlobalEvents, GlobalManager } from '@/context'
import { RouteName, needGuide2LoginRouteName } from './config'
import { ThemeComponentType } from '@/context/components-manager'
import { apiReportview } from '@/api/promote/binding'
import { createRouter, resetRouter, withLayoutComponent } from '.'
import { disabledModal } from '@/utils/Tool'
import { pick } from 'lodash'
import { trace } from '@/context/tacker'
import { useMainStore } from '@/store/index'
import HallCustomization from '@/controller/hallCustomization'
import Modal from '@/controller/Modal'
import NProgress from 'multi-nprogress'
import Router, { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import Site from '@/controller/Site'
import Vue from 'vue'
import autoModalManager from '@/context/autoModalManager'
import themeConfig from '@/context/components-manager/config/themes'
import type { RouterViewWithTransitionMode } from '@/store/index/type'

export enum QueryType {
  /** 直达大厅首页 不显示登录注册弹窗 */
  HOME = '2',
  /** 直达试玩页面 不显示登录注册弹窗 */
  TEST_REGISTER = '3',
  /** 显示注册弹窗（已登录的用户不用再次弹窗） */
  REGISTER = '4',
  /** 直达活动首页 不显示登录注册弹窗 */
  EVENT = '5'
}

const loaded = {} as Record<string, boolean>
const timed = {} as Record<string, number>

/**
 * 基础依赖数据的请求
 */
const initData = async (
  callback: () => void,
  route: Route,
  useInjectData = false
) => {
  /**
   * 确保store已经初始化持久化数据已经装载
   */
  await new Promise((resolve) => {
    Vue.nextTick(() => {
      resolve('')
    })
  })
  /**
   * 获取登录前必要的一些信息
   */
  await GlobalManager.Site.init(useInjectData)
  const { hasLogined, userkeyFromUrl, uuid, useFastLogin, reRenderRoot } =
    useMainStore()

  /**
   * 未正式登录过的情况下带有cid、id的参数进行代理、渠道访问上报
   */
  if (!hasLogined) {
    const url = new URL(location.href)
    const promoter_info =
      url.searchParams.get('cid') || url.searchParams.get('id')
    if (promoter_info)
      apiReportview({
        promoter_info,
        accessUrl: url.origin,
        visitor_device: uuid
      })
  }
  /**
   * 登录行为
   */
  const doLogin = async () => {
    /**
     * 如果已经是登录状态且开启了自动登录，则进行快速登陆更新用户信息
     * 否则进行试玩账号登录
     */
    if (useFastLogin) {
      const userkey = userkeyFromUrl
      await GlobalManager.User.fastLogin(
        {
          userkey
        },
        {
          noErrorMessage: true
        }
      ).catch(() => {
        if (userkeyFromUrl) {
          useMainStore().isUserKeyFromUrlFail = true
        }
        Vue.nextTick(() => {
          reRenderRoot()
        })
        GlobalManager.User.mockUnLoginedUserInfo()
      })
    } else {
      GlobalManager.User.mockUnLoginedUserInfo()
    }
  }

  /**
   * 1.下载站点资源配置
   */
  await Promise.all([
    GlobalManager.Site.initAssetsConfig(),
    (async () => {
      const isPrivatePage =
        route.meta?.private ||
        needGuide2LoginRouteName.includes(route.name as RouteName)
      if (isPrivatePage) {
        await doLogin().catch(() => {
          /**
           * 允许登录出错
           */
        })
      } else {
        /**
         * 登录可以不等待
         * 唯一等待的场景是后续业务内的请求依赖登录后的token做加解密机制
         * 现在已经公共请求层做了拦截，业务中依赖登录token加密的接口如果提前调用会进行队列收集
         * 待登录的key生成后再放行请求
         */
        doLogin()
      }
    })()
  ])

  // 所有初始化完成后根据后台自定义配置覆盖客户端全局颜色值
  HallCustomization.setGlobalTextColor()
  const layoutType = useMainStore().siteConfig?.type as ThemeSkinType
  if (typeof layoutType !== 'undefined') {
    /**
     * 提前下载game路由
     */
    if (route.name === RouteName.GAME) {
      const com =
        themeConfig[layoutType as ThemeSkinType][ThemeComponentType.HomePage]
          .com
      if (typeof com === 'function') {
        ;(com as () => Promise<unknown>)()
      }
    }
    /**
     * 路由动态替换
     */
    resetRouter(createRouter(layoutType))
    GlobalEvents.dispatch({
      type: 'REFRESH_URL',
      payload: {}
    })
    /**
     * 异步加载多语言配置和合图资源
     */
    setTimeout(() => {
      GlobalManager.Site.initializeLanguageAndSprites(useInjectData)
    }, 10)

    /**
     * 提前主动下载动态layout
     */
    const layout = withLayoutComponent(layoutType)
    if (typeof layout === 'function') {
      await (layout as () => Promise<unknown>)().catch(() => {
        /**
         *
         */
      })
    }
  }

  callback && callback()
}

const routerNProgress = NProgress()
routerNProgress.configure({
  showSpinner: false
})
export const routerNProgressController = {
  state: [] as string[],
  start: (key: string) => {
    if (routerNProgressController.state.includes(key)) return
    const { isInitializationCompleted } = useMainStore()
    if (isInitializationCompleted) {
      routerNProgress.start()
    }
  },
  done: (key: string) => {
    routerNProgress.done()
    const index = routerNProgressController.state.indexOf(key)
    if (index > -1) {
      routerNProgressController.state.splice(index, 1)
    }
    routerNProgressController.state.push(key)
  }
}

/**
 * 1. web 版 强制使用Base
 * 2. mobile版 优先使用 路由切换时指定的 ，如果未指定，路由层级配置 > 路由堆栈顺序
 */
const handleRouterViewTransition = (from: Route, to: Route) => {
  const routerStack = routerNProgressController.state.reduce((pre, cur) => {
    if (cur.startsWith('route-')) {
      pre.push(cur.replace(/^route-/, ''))
    }
    return pre
  }, [] as string[])

  const { isWeb } = useMainStore()
  let mode: RouterViewWithTransitionMode

  /**
   * WEB 版强行覆盖为Base
   */
  if (isWeb) {
    mode = 'Base'
  } else {
    /**
     * 如果当前指定了使用什么动画就用当前指定的
     * 否则采用配置匹配（优先使用depIndex配置，其次使用路由堆栈中的打开顺序）
     */
    const routerViewWithTransitionModeOnCurrentChange =
      useMainStore().routerViewWithTransitionModeOnCurrentChange
    if (routerViewWithTransitionModeOnCurrentChange) {
      mode = routerViewWithTransitionModeOnCurrentChange
      useMainStore().routerViewWithTransitionModeOnCurrentChange = null
    } else {
      if (from.name && from.name !== to.name) {
        let fromIndex = routerStack.indexOf(from.name || '')
        let toIndex = routerStack.indexOf(to.name || '')
        /**
         * 如果配置表中有配置索引，且配置表中的值两者不同，以配置表为准
         * 否则以路由记录为准
         */
        if (
          typeof from.meta?.depIndex === 'number' &&
          typeof to.meta?.depIndex === 'number' &&
          from.meta?.depIndex !== to.meta?.depIndex
        ) {
          fromIndex = from.meta?.depIndex
          toIndex = to.meta?.depIndex
        }

        mode = fromIndex > toIndex ? 'Pop' : 'Push'
      } else {
        mode = 'Base'
      }
    }
  }

  useMainStore().routerViewWithTransitionMode = mode
}
/**
 * 初始化逻辑控制
 */
const initializationController = {
  /**
   * 记录是否正在初始化数据
   * 因开始访问一些隐藏路由时可能有重定向到根目录的逻辑，此时也不能再次初始化数据
   */
  initializing: false,
  filter: (route: Route) => {
    return ![RouteName.PROMOTE_GAME].includes((route.name || '') as RouteName)
  },

  action: async (route: Route) => {
    const { isInitializationCompleted } = useMainStore()
    if (
      !initializationController.filter(route) ||
      initializationController.initializing ||
      isInitializationCompleted
    ) {
      return
    }

    initializationController.initializing = true

    await initData(
      () => {
        initializationController.done(route)
      },
      route,
      !!window.LOBBY_SITE_CONFIG.INJECT_DATA
    ).catch(() => {
      initializationController.done(route)
    })
  },
  done: async (route: Route) => {
    const { isInitializationCompleted } = useMainStore()
    if (!initializationController.filter(route)) {
      // 注释可留在骨架屏
      window.initSplash.destroy()
      return
    }
    if (isInitializationCompleted) return
    const mainStore = useMainStore()
    mainStore.$patch({
      isInitializationCompleted: true
    })

    GlobalEvents.dispatch({ type: 'CLIENT_DATA_INITIALIZATION_COMPETED' })
    Vue.nextTick(async () => {
      Site.getConfigDependencies(true)
      await Site.initIpInfos().catch(() => {
        /**
         * 让其不阻塞流程
         */
      })
      if (
        [RouteName.NOT_FOUND, RouteName.ERROR_TEXT].includes(
          route.name as RouteName
        )
      ) {
        return
      }
      /**
       * 跳转注册、大厅、登录、活动页优先级
       * 链接参数>接口查询>后台配置
       */
      const { systemInfos, linkType, hasLogined } = useMainStore()
      const agentPayload = pick(route.query, ['cid', 'id', 'type'])
      /**
       * 直达首页、试玩活动页不展示登陆注册弹框
       */
      // 如果存在 cid 则重新调整优先级，优先判断 agentPayload 的 type
      if (agentPayload.cid && agentPayload.type) {
        if (
          [QueryType.TEST_REGISTER, QueryType.EVENT, QueryType.HOME].includes(
            agentPayload.type as QueryType
          )
        ) {
          return
        }
      } else {
        // 如果不存在 cid 或者 agentPayload 的 type 不符合条件，然后才判断 linkType
        if (
          [LinkType.TEST_REGISTER, LinkType.HOME].includes(linkType as LinkType)
        ) {
          return
        }
      }

      /**
       * 这里cocosh5下架，兼容了手机浏览器访问和PC访问的判断
       * 如果后台开启跳过注册页或渠道推广链接配置显示注册页则不用显示登录注册弹框（如果是推广链接，选择了注册页，则不受影响）
       * 不再区分PC和H5 都用H5的字段
       */
      const auto_visitor_login =
        systemInfos?.auto_visitor_login_h5 === AutoVisitorStatus.OPEN
      if (
        !hasLogined &&
        (auto_visitor_login ||
          agentPayload?.type === QueryType.REGISTER ||
          linkType === LinkType.REGISTER)
      ) {
        mainStore.$patch({
          enterPagePopupType: 1
        })

        if (disabledModal()) {
          return
        }

        if (!Modal.hasOpeningModal() && autoModalManager.isFinished) {
          Modal.openLoginRegisterModal()
        }
        return
      }
    })
  }
}
/**
 * 是否放行过
 */
let isAfterOne = false

export default function guard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const agentPayload = pick(to.query, ['cid', 'id', 'type'])
    /**
     * 如果cid和id参数同时存在, 跳转错误页
     */
    // if (
    //   to.name !== RouteName.ERROR_TEXT &&
    //   agentPayload?.id &&
    //   agentPayload?.cid
    // ) {
    //   next({
    //     name: RouteName.ERROR_TEXT,
    //     query: to.query
    //   })
    //   return
    // }

    // 保存每一次跳转的路由名称
    useMainStore().updatePreviousRouteName(from.name as string)
    /**
     * 跳转试玩页
     */
    if (
      agentPayload?.type === QueryType.TEST_REGISTER &&
      !useMainStore().hasLogined &&
      to.name !== RouteName.SUB_GAME &&
      from.name !== RouteName.SUB_GAME
    ) {
      next({
        name: RouteName.SUB_GAME,
        query: {
          ...to.query,
          gameCategoryId: `${GameCategoryIds.DEMO}`,
          platformId: `${GameCategoryIds.DEMO}${CustomPlatformId}`
        }
      })
      return
    }

    /**
     * 跳转活动页
     */
    if (
      agentPayload?.type === QueryType.EVENT &&
      to.name !== RouteName.EVENT &&
      from.name !== RouteName.EVENT
    ) {
      next({
        name: RouteName.EVENT,
        query: to.query
      })
      return
    }

    // 标记未下载资源的开始时间
    if (to.name && !loaded[to.name as string]) {
      timed[to.name as string] = Date.now()
    }

    const nextWithProgress: NavigationGuardNext<Vue> = (config) => {
      routerNProgressController.start(`route-${to.name}`)
      next(config)
    }

    if (window.LOBBY_SITE_CONFIG.SITE_DOMAIN_NOT_MATCH) {
      return
    }

    try {
      await initializationController.action(to)
    } catch (e) {
      await initializationController.action(to)
    }
    return checkLoginPemission(to, from, nextWithProgress)
  })

  router.beforeResolve((to: Route, from, next) => {
    if (to.name) {
      // 注释可留在骨架屏
      window.initSplash.destroy()
      handleRouterViewTransition(from, to)
      if (!loaded[to.name as string]) {
        trace('resource', {
          href: to.fullPath,
          name: to.name,
          time: Date.now() - timed[to.name as string]
        })
      }
      loaded[to.name as string] = true
    }

    next()
  })

  router.afterEach((to, from) => {
    const { doLoginForceBindPopup, autoUpdateIsFromBindModal } = useMainStore()
    autoUpdateIsFromBindModal(to.name)
    // 如果当前没有弹窗 且 顺序队列已经执行完
    if (!Modal.hasOpeningModal() && autoModalManager.isFinished) {
      doLoginForceBindPopup()
    }

    // 弹窗队列管理
    const isFromHome = from.name === RouteName.GAME
    const isToHome = to.name === RouteName.GAME

    if (isToHome) {
      autoModalManager.resume()
    } else if (isFromHome) {
      autoModalManager.pause()
      autoModalManager.hideAllModal() // 强行标识，当前没有显示中的弹窗
    }

    isAfterOne = true
    routerNProgressController.done(`route-${to.name}`)
  })

  router.onError((res) => {
    trace('router-error', { message: res.message })
  })
}

/**
 * 校验登录权限
 * @returns
 */
export const checkLoginPemission: NavigationGuard = async (to, from, next) => {
  const { isRealLogined, isDemoMode, userInfos } = useMainStore()
  /**
   * 是否需要登录
   */
  const needLogin = to.meta?.private

  /**
   * 角色白名单
   */
  const whiteRoleType = to.meta?.whiteRoleType || []

  /**
   * 检测当前角色能否进入页面，无关是否登录
   */
  const canCurrentRolePass = () => {
    if (isDemoMode) {
      return whiteRoleType.includes(userInfos?.account_type) || !needLogin
    }
    return true
  }

  const back2Home = () => {
    next({
      replace: true,
      name: RouteName.ROOT
    })
  }
  try {
    /**
     * 需要登录
     */
    if (needLogin) {
      /**
       * 已经进入过页面了引导注册后再进入
       */
      if (isAfterOne) {
        if (canCurrentRolePass()) {
          await GlobalManager.Site.guide2Register(() => {
            next()
          })
        } else {
          if (isDemoMode) {
            GlobalManager.Site.guide2DemoAcountDialog()
            return
          }
        }
        /**
         * 刷新进入
         */
      } else {
        if (canCurrentRolePass() && isRealLogined) {
          next()
        } else {
          back2Home()
        }
      }
    } else {
      next()
    }
  } catch {
    back2Home()
  }
}
