import {
  ApiLoginRegisterType,
  BrandLogoInfosRes,
  ContryInfos,
  CurrencyInfos,
  DownloadUrlInfos,
  EventCategoriesAvailable,
  EventCategoriesAvailableEnum,
  FrameBoxInfos,
  HomeLayoutInfos,
  IpBindInfos,
  LargeAmountMustBind,
  LinkType,
  MaintainType,
  OsType,
  RewardBindData,
  SiteConfig,
  SiteInfos,
  SiteURLInfos,
  SystemInfos,
  UserInfos
} from '@/api/common/type'
import { ApiServiceInformationResponese } from '@/api/service/type'
import {
  ApiVipSwitchStatusResponse,
  ApiVipDetailsResponese as VipDetails,
  ApiUserVipDetailsResponse as VipInfos
} from '@/api/vip/type'
import { AxiosError, AxiosResponseHeaders } from 'axios'
import { EventDiscountsRedDot } from '@/api/event/apiEventAndDiscountsRedDot/apiEventAndDiscountsRedDot'
import { IpCheck } from '@/service/site/entity/site'
import { Language } from '@/i18n'
import {
  PopRechargeList,
  ReceiveAwardAllSwitchEnum,
  RechargeFundConfigItem,
  ReturnGoldConfigItem,
  ReturnGoldSwitchEnum,
  VipConfig,
  YueBaoConfig
} from '@/api/event/type'
import { SkinType } from '@/context/const'
import { ThemeCssVar } from '@/config/theme.config'
import { createAsyncQueue } from '@/utils/Tool'
import type { PngSprite, SvgSprite } from '@/components/icon-sprite'
/** 任务弹框关闭的事件参数 */
export type TaskModelCloseEmitParams = {
  /** 触发方式 [forward:点击前往按钮] */
  trigger?: 'forward'
}

/**
 * userOptResult  操作权限字段列表（1-是，0-否）   1代表已设置过 0表示未设置
 * 1，不可设置， 一键注册的用户默认未设置，普通注册的用户默认为1，不可以修改用户名
 */
export enum UserOperationResult {
  WithdrawPwd, // 是否设置过取款密码
  SecretSecurity, // 是否设置过密保问题
  MobilePhoneNumber, // 是否绑定过手机
  DeviceId, // 是否绑定过设备
  CanSetUsernameAndPwd // 是否设置过用户名和密码
}

/**
 * permissionOpt  登录返回字段新增permissionOpt(替代userOptResult)
 */
export enum PermissionOperation {
  WithdrawalPasswd = 'hasWithdrawPasswd', // 是否设置过取款密码
  SecretSecurity = 'hasSecurityQuestion', // 是否设置过密保问题
  MobilePhoneNumber = 'hasPhone', // 是否绑定过手机
  DeviceId = 'hasAccountDeviceId', // 是否绑定过设备
  canSetUsernameAndPassword = 'usernamePasswdMutable', // 是否设置过用户名和密码
  WithdrawalAccout = 'hasWithdrawAccount' // 是否设置过提现账号
}

/**
 * 当前页tabs
 */
export enum CurrentPageTabs {
  withdrawAccount = 3
}

/**
 * 窗口宽度对应尺寸
 */
export enum ScreenSize {
  S = 'small', // 750以下
  M = 'medium', // 750-1200
  L = 'large' // 1200以上
}

export enum OtherHoliday {
  MidAutumn = 101, // 中秋节
  NationalDay = 102, // 国庆节
  MotherOfGod = 103, // 圣母节
  MotherOfGod2 = 104, // 圣母节
  Dussehra = 105, // 十胜节
  HalloweenPurple = 106, // 万圣节紫
  HalloweenBlue = 107 // 万圣节蓝
}

// 108以上的节日主题统一设计尺寸
export enum NormalHoliday {
  Double11 = 108, //雙十一1
  Double12 = 109, //雙十一2
  Double13 = 110, //雙十一3
  Diwali = 111, // 排灯节
  Diwali2 = 112, // 排灯节2
  Brazil = 113, // 巴西共和国成立日

  LoyKrathong = 114, // 泰国水灯节
  ThaiNational = 115, //泰国国庆节
  DoubleTwelve1 = 116, // 双十一1
  DoubleTwelve2 = 117, // 双十一2

  Christmas1 = 118, // 圣诞节1
  Christmas2 = 119, // 圣诞节2

  NewYear1 = 120, //元旦1
  NewYear2 = 121, //元旦2
  NewYear3 = 122, //元旦3
  NewYear4 = 123, //元旦4
  Snowflake1 = 124, // 雪花节1
  Snowflake2 = 125, // 雪花节2
  India = 126, // 印度共和国节
  Harvest = 127, //印度丰收节
  Carnival1 = 128, //狂欢节1
  Carnival2 = 129, //狂欢节2
  Carnival3 = 130, //狂欢节3
  Carnival4 = 131, //狂欢节4
  Carnival5 = 132, //狂欢节5
  ChineseNewYear1 = 133, //农历新年1
  ChineseNewYear2 = 134, //农历新年2
  ChineseNewYear3 = 135, //农历新年3
  ChineseNewYear4 = 136, //农历新年4
  ChineseNewYear5 = 137, //农历新年5
  ValentinesDay1 = 138, //情人节1
  ValentinesDay2 = 139, //情人节2
  ValentinesDay3 = 140, //情人节3
  LanternFestival1 = 141, //元宵节1
  LanternFestival2 = 142, //元宵节2
  LanternFestival3 = 143, //元宵节3
  WanfoFestival1 = 144, //万佛节1
  WanfoFestival2 = 145, //万佛节2
  Shiva1 = 146, //印度湿婆神节
  Holi = 147, //洒红节
  GoodFriday = 148, //耶稣受难日
  Easter = 149, //复活节
  Water = 151, //泼水节
  TeluguNewYear = 152, //泰卢固新年
  VietnamKing = 153, //越南雄王纪念日
  TamilNewYear = 154, //泰米尔新年
  BrazilianIndependenceDay1 = 155, //巴西独立英雄日主题1
  BrazilianIndependenceDay2 = 156, //巴西独立英雄日主题2
  BrazilianIndependenceDay3 = 157, //巴西独立英雄日主题3
  MexicanNationalDay = 158 //墨西哥民族独立运动日
}
// 159以上的节日主题统一设计尺寸,老板要求更改的设计规范
export enum BossDesignThemeType {
  Mayday1 = 159, //劳动节1
  Mayday2 = 160, //劳动节2
  Mayday3 = 161, //劳动节3
  IndiaOpenEat1 = 162, //印度开斋节1
  IndiaOpenEat2 = 163, //印度开斋节2
  BrazilianCorolla1 = 164, //巴西圣体节主题1
  BrazilianCorolla2 = 165, //巴西圣体节主题2
  BrazilFesta1 = 166, //巴西六月庆典1
  BrazilFesta2 = 167, //巴西六月庆典2
  BrazilFesta3 = 168, //巴西六月庆典3
  DragonBoatFestival1 = 169, //端午节1
  DragonBoatFestival2 = 170, //端午节2
  PhilippineNational = 171, //菲律宾国庆节
  EuroCup1 = 172, //欧洲杯1
  EuroCup2 = 173, //欧洲杯2
  EuroCup3 = 174, //欧洲杯3
  Guerban = 175 //伊斯兰古尔邦节
}

/** 以前的节日OtherHoliday尺寸不固定，NormalHoliday代表统一设计尺寸的节日 */
export const Holiday = {
  ...OtherHoliday,
  ...NormalHoliday,
  ...BossDesignThemeType
}

export const DefaultActivesAuthInfos = {
  activeCount: EventCategoriesAvailableEnum.Show,
  taskCount: EventCategoriesAvailableEnum.Show,
  returnGoldClosed: ReturnGoldSwitchEnum.Open,
  rewardCount: EventCategoriesAvailableEnum.Show,
  disableReceiveAwardAll: ReceiveAwardAllSwitchEnum.Close,
  rechargeFundClosed: ReturnGoldSwitchEnum.Open,
  loading: false
}

export type MainSkinSprite = Record<string, PngSprite>

export type ImagesAssets = {
  images: Record<string, string>
  svgs: Record<string, SvgSprite>
}

export type RouterViewWithTransitionMode = 'Base' | 'Push' | 'Pop'

/**登录注册弹窗新人福利 */
export interface RegisterBubbleData {
  /**货币类型 */
  currency?: string
  /**注册信息 */
  register?: RewardBindData
}

/** 记住密码类型 */
export interface AutoFillData {
  account?: string
  userpass?: string
  smsSegment?: string
  loginType?: ApiLoginRegisterType
}

export interface MainState {
  versionReloadRecord: Record<string, number>
  routerViewWithTransitionModeOnCurrentChange: RouterViewWithTransitionMode | null
  routerViewWithTransitionMode: RouterViewWithTransitionMode
  skinAssetsHash: Record<string, string>
  /**saba接口是否维护 */
  sabaMaintenance: boolean
  /**
   * 收到维护公告后记录维护的时间，供到点后去做检测是否需要弹维护
   */
  maintainTimeInfo: Pick<MaintainType, 'end_time' | 'start_time'> | null
  ipCheck: IpCheck
  currentTheme: ThemeCssVar
  responseHeaders: Record<string, AxiosResponseHeaders>
  isSystemStatusLoading: boolean
  isBannerLoading: boolean
  /**
   * 标记为true时标识 尝试使用 地址栏的 userkey登录但失败了
   */
  isUserKeyFromUrlFail: boolean
  defaultUrlQueryParams: Record<string, unknown>
  /**
   * 为false代表限制了，true代表不限制
   */
  areaStatus: boolean
  /**
   * true代表维护了
   */
  siteMaintenanceStatus: boolean
  rootKey: string
  osType: OsType
  /**
   * 极验限制操作的时间点
   */
  geetestRestrictedTime: number
  uuid: string
  rememberPwd: boolean
  isInitializationCompleted: boolean
  isFetchingPrize: boolean
  /** 记住密码密钥 */
  autoFillSignKey: string
  /** 记住密码缓存数据 */
  autoFillData: string
  lastLoginedUsername: string
  /**
   * 服务器时间-本机时间的差值 集合
   */
  timeDiffs: Array<number>
  language: Language
  siteConfig: SiteConfig | null
  siteURLInfos: SiteURLInfos | null
  urltime?: number
  siteInfos: SiteInfos | null
  serviceInformation: ApiServiceInformationResponese | null
  /** 服务端默认区号 */
  defaultCountry: ContryInfos | null
  /** 短信区号列表 */
  smsCountrys: ContryInfos[]
  /** 用户选择的区号 */
  smsSegment: string
  systemInfos: SystemInfos | null
  ipBindInfos: IpBindInfos | null
  userInfos: UserInfos | null
  userKeyForFastLogin: string
  skinName: SkinType
  walletDownloadUrl?: string
  allPngSprite: Record<string, PngSprite> | null
  baseURL: string
  bestBaseURL: string
  vipSwitchStatus: Array<ApiVipSwitchStatusResponse> | null
  vipInfos: VipInfos | null
  vipDetails: VipDetails | null
  scrollLock: boolean
  brandLogoInfos: BrandLogoInfosRes | null
  /**下载配置数据来自站点数据配置 */
  channelGlobalConfig: DownloadUrlInfos | null
  frameBoxInfos: Array<FrameBoxInfos> | []
  /** 活动任务返水是否显示 */
  activesAuthInfos: EventCategoriesAvailable
  /** 返水配置 */
  returnGoldConfig: ReturnGoldConfigItem[]
  /** vip配置 */
  vipConfig: VipConfig
  /** 利息宝配置 */
  yueBaoConfig: YueBaoConfig
  /** 公积金配置 */
  rechargeFundConfig: RechargeFundConfigItem[]
  activePageSort: string
  /**
   * 全站奖励气泡
   */
  rewardBindData: Array<RewardBindData> | []
  checkNewBenefits: boolean // 选中新人福利当次不再提示
  /** 检测是否选中每日任务不再提示 */
  checkDailyTask: boolean
  /** 检测是否选中每周任务不再提示 */
  checkWeeklyTask: boolean
  /** 检测是否选中每三日任务不再提示 */
  checkEvery3DayTask: boolean
  isNewBenefitsInSaveUrl: boolean // 进行保存网址不显示新人福利弹窗
  checkNeverNewBenefits: boolean // 选中新人福利永不再提示
  isNoticePopupFinish: boolean // 通知公告是否执行完成
  isNewBenefitFinish: boolean // 新人福利是否执行完成
  /** 检测是否每日任务是否执行完成 */
  isDailyTaskFinish: boolean
  /** 检测每周任务是否执行完成 */
  isWeeklyTaskFinish: boolean
  /** 检测每三日任务是否执行完成 */
  isEvery3DayTaskFinish: boolean
  globalModalManager: ReturnType<typeof createAsyncQueue> | null
  /** 是否是点击安全中心绑定安全项弹框进来的 */
  isFromBindModal: boolean
  /** 点击过安全中心绑定项的跳过 或者 从绑定弹窗过去的返回 按钮的集合 */
  ignoreSecurityBindkey: Record<UserInfos['username'], boolean>
  bindModalIsOpen: boolean //强制绑定弹窗是否已经弹出 避免多次弹出
  bindModalIsLargeBalances: boolean //强制绑定的触发原因是否是因为大量余额触发 这个决定弹出的弹出提示语文案
  currencyList: Array<CurrencyInfos>
  screenSize: ScreenSize
  screenAspectRatio: number // 屏幕纵横比（高/宽）
  linkType: LinkType | null
  popRechargeEvent?: PopRechargeList
  homeLayoutInfos: HomeLayoutInfos | null
  enterPagePopupType: number
  /** 浏览器指纹标识 */
  browserFingerId: string
  /** 极验设备验 token */
  geeGuardToken: string
  getredDot: EventDiscountsRedDot | null
  gameListTabActive: number // 首页游戏列表tab标签ss中项

  installPrompt: { prompt: () => void } | null
  currentTryCurrency: CurrencyInfos | null //游客当前选中的试玩币种
  realnameValue: string
  viewResize: number //屏幕尺寸改变随机码(每当屏幕尺寸发生变化的,随机)
  /** 屏幕尺寸改变随机码(每当屏幕尺寸发生变化的,进行更新记录) */
  rootFontSize: number
  /**隐藏App下载 */
  hiddenDownload: boolean
  /** svg合图 */
  loadingSvgSprite: boolean
  /** 雪碧合图 png合图 */
  loadingImgSprite: boolean
  /** 三方应用中提示浏览器打开是否已经选择 */
  isBrowserOpenChoosed: boolean
  /** 记录强制绑定已弹窗内容 */
  alreadyPopupBindItem: string
  /**为true时不再尝试oss端皮肤资源 */
  useLocalSkinAssets: boolean
  /** 皮肤id */
  skinId: string
  /**大额强制绑定配置*/
  largeAmountMustBind?: LargeAmountMustBind | null
  /**触发大额强制绑定所需的余额量*/
  largeAmountRange?: Record<string, number> | null
  previousRouteName?: string
  bindModalIsWithdrawVerify?: boolean //当前强制绑定是否由体验资产验证触发
  /** 是否为机器人，1表示机器人，0表示非机器人 */
  robot?: 0 | 1
  /** 当前邮箱是否已注册账号 */
  isEmailExist?: boolean
  /** 当前手机号是否已注册账号 */
  isPhoneExist?: boolean
  /** 登录注册弹窗新人福利 */
  registerBubbleData: RegisterBubbleData
  /** 是否完成绑定密保操作 */
  isSecurityHad: boolean
  /** 是否强制修改提现密码标识 */
  needChangeWithdrawPassword: boolean // 是否需要修改提现密码
  hasChangedWithdrawPassword: boolean // 是否已经修改提现密码
  /**已经点击了桌面快捷方式的按钮 */
  hasClickButtonSaveShortCut: boolean
  /**以及点击过了通过渠道创建的桌面快捷方式的弹出框 */
  hasClickButtonSaveShortCutByChannel: boolean
  /**新人福利下载奖励数据 */
  downloadReward: RewardBindData
  /**5198主题滚动奖金池红包 */
  theme18RollingRed: number
  /** 金币刷新是否超时 */
  isCurrencyTimeoutError: boolean
  /** 品牌接口错误 */
  brandAxiosError: null | AxiosError
  brandAxiosErrorLoading: boolean
}

/**
 * 是否需要修改提现密码的ws推送
 */
export interface ChangePasswordWs {
  data: {
    isRegister: boolean
  }
}
