import { Button } from '@/plugins/ant-design-vue/tsx-support'
import {
  ChannelStatusList,
  ChannelTypeList,
  RewardsCondition,
  UserDisplayList,
  ViewDownloadList
} from '@/views/channel/const'
import { Component, Mixins } from '@/vue-property-decorator'
import {
  GlobalEventActions,
  GlobalEventActionsTypeEnum
} from '@/context/events'
import { GlobalEvents } from '@/context'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { getCurrentDevice } from '@/utils/Device'
import { supportShortcut } from '../../utils'
import { useChannelStore } from '@/views/channel/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BaseMixins from '@/layouts/components/downloadApp/mixin'
import BusinessUtils from '@/utils/business-utils'
import Modal from '@/controller/Modal'
import TooltipsComp from '@/components/business-components/tooltips'
import style from './index.module.scss'
const isSupportShortcut = supportShortcut()
const device = getCurrentDevice()
const isH5Pwa = device.isH5Pwa()
interface State {
  /**是否展示创建桌面快捷方式弹出框通过渠道的 */
  showSaveShortByChannel: boolean
}
/**通过渠道配置开启提示创建桌面快捷方式 */
@Component<SaveShortByChannel>({ name: 'SaveShortByChannel' })
export default class SaveShortByChannel extends Mixins(BaseMixins) {
  private handleSaveShortByChannel(action: GlobalEventActions) {
    if (action.type === GlobalEventActionsTypeEnum.PWA_INSTALL_BY_CHANNEL) {
      this.saveShortcutByChannel()
    }
  }

  state: State = {
    showSaveShortByChannel: true
  }

  mounted() {
    GlobalEvents.subscribe(this.handleSaveShortByChannel)
  }

  beforeDestroy() {
    GlobalEvents.unsubscribe(this.handleSaveShortByChannel)
  }

  /**是否通过PWA打开 */
  private static get isInStandaloneMode() {
    return device.isInStandaloneMode()
  }

  /**是否展示创建桌面快捷方式来自渠道 */
  private static get showSaveShortcutLayoutByChannel() {
    const { channelInfo } = useChannelStore()
    const { isFirstDeposit } = useMainStore()
    if (!channelInfo) {
      return false
    }
    const { downloadDisplay, userDisplay, viewDownload } = channelInfo
    const { Open } = ChannelStatusList
    const { AllUser } = UserDisplayList
    const { H5 } = ViewDownloadList
    const openPartialCondition = downloadDisplay === Open && viewDownload === H5

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 展示的用户群体为全部
     */
    if (openPartialCondition && userDisplay === AllUser) {
      return true
    }

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 用户的充值次数大于或者大于1次（仅限已首充会员）
     */
    if (openPartialCondition && isFirstDeposit) {
      return true
    }

    return false
  }

  /**渠道类型是否为渠道链接类型 */
  private static get isChannelByLink(): boolean {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return false
    }
    const { type } = channelInfo
    if (ChannelTypeList.ChannelLink === type) {
      return true
    }
    return false
  }

  /**是否能够打开创建桌面快捷方式的提示通过渠道配置 */
  static get canOpenSaveShortCutByChannel() {
    const { hasClickButtonSaveShortCutByChannel } = useMainStore()
    const { userInfos } = useMainStore()
    const isChannelPrize = !!userInfos?.isChannelPrize
    return (
      isSupportShortcut &&
      !isChannelPrize &&
      !isH5Pwa &&
      this.isChannelByLink &&
      !hasClickButtonSaveShortCutByChannel &&
      !!this.showSaveShortcutLayoutByChannel
    )
  }

  /**获取下载APP的格式化的金额-通过渠道配置 */
  private get formatDownloadAppRewardByChannel() {
    const { userInfos, downloadAppRewardByChannel } = useMainStore()
    const formatedDownloadAppRewardByChannel = currencyFormatter(
      downloadAppRewardByChannel || 0,
      {
        code: userInfos?.currency,
        precision: 2,
        symbol: ''
      }
    )
    return formatedDownloadAppRewardByChannel
  }

  /**
   * 是否展示领取奖励条件的文案
   * 条件为
   * 1.展示为全部会员
   * 2.领取条件为首充会员
   */
  private get isShowRewardsConditionLiteral() {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return false
    }
    const { userDisplay, rewardsCondition } = channelInfo
    const { AllUser } = UserDisplayList
    const { FirstDeposit } = RewardsCondition
    if (userDisplay === AllUser && rewardsCondition === FirstDeposit) {
      return true
    }
    return false
  }

  private saveShortcutByChannel() {
    const {
      brandLogoInfos,
      downloadAppRewardByChannel,
      isShowDownloadRewardsByChannel,
      isForceDownloadByChannel
    } = useMainStore()
    const formatDownloadAppRewardByChannel =
      this.formatDownloadAppRewardByChannel
    const onCancel = () => {
      // 如果是强制模式，那么需要再安装成功h5桌面快捷方式之后，再去掉该弹出框
      if (!isForceDownloadByChannel) {
        modal.destroy()
      }

      const { setHasClickButtonSaveShortCutByChannel } = useMainStore()
      setHasClickButtonSaveShortCutByChannel(true)
    }
    const destroyModal = () => {
      modal?.destroy()
      window.removeEventListener('appinstalled', destroyModal)
    }
    window.addEventListener('appinstalled', destroyModal)
    const modal = Modal.create({
      destroyOnClose: true,
      closable: !isForceDownloadByChannel,
      hiddenBtns: true,
      class: style.downloadModal,
      width: BusinessUtils.px2rem(600),
      onCancel,
      afterClose: onCancel,
      content: () => {
        const { t } = useI18n()
        return (
          <div class={style.downloadContent}>
            <div class={style.logo}>
              <my-img src={brandLogoInfos?.appIconBigPath ?? ''} />
              <span class={style.title}>
                <div class={style.wrapperChannel}>
                  <span class={style.channelTip1}>
                    {t('lobby.rightBar.channelTip4')}
                  </span>
                  {!!downloadAppRewardByChannel &&
                    isShowDownloadRewardsByChannel && (
                      <span class={style.channelTip2}>
                        {t('lobby.rightBar.channelTip2')}
                        <b>{formatDownloadAppRewardByChannel}</b>
                        {t('lobby.rightBar.channelTip3')}
                        {this.isShowRewardsConditionLiteral &&
                          t('lobby.rightBar.channelTip5')}
                      </span>
                    )}
                </div>
              </span>
            </div>
            <Button.Tsx
              type="primary"
              class={style.downloadItem}
              onClick={() => {
                GlobalEvents.dispatch({
                  type: GlobalEventActionsTypeEnum.PWA_INSTALL
                })
                onCancel()
              }}
            >
              {!!downloadAppRewardByChannel &&
                isShowDownloadRewardsByChannel && (
                  <TooltipsComp
                    charge={1}
                    top={BusinessUtils.px2rem(-20)}
                    text={formatDownloadAppRewardByChannel}
                  />
                )}
              <span class={style.appName}>
                {t('lobby.rightBar.saveShortcutMessage')}
              </span>
            </Button.Tsx>
          </div>
        )
      }
    })
  }
  render() {
    return <template></template>
  }
}
