import { type AppEventType } from './type'
import { DateFormatType } from '@/context/common/date'
import { GlobalConst, GlobalEvents, GlobalManager } from '@/context'
import {
  InjectData,
  KindType,
  MaintainType,
  PackKindType,
  SiteInfos
} from '@/api/common/type'
import { LobbyPollingType } from '@/controller/Polling'
import { LobbyService } from '@/context/service'
import { RED_COLOR } from '@/config/color.config'
import { RouteName } from '@/router/config'
import { SiteMaintainPayload } from '@/context/events'
import { apiPointerDomain } from '@/api/common'
import { cloneDeep, repeat } from 'lodash'
import { defaultUrlQueryParams, durl } from '@/context/const'
import { fetchUrl, getStorage, setStorage } from '@/utils/Tool'
import { getCurrentDevice } from '../Device'
import { isWgPackage, windowConfig } from '../window'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import Buried from '@/controller/Buried'
import DateUtils, { DateOptions, DateType } from '@/utils/DateUtils'
import moment from 'moment'

/**
 * 具有业务属性的公共方法，比如有业务特征的格式化 解析方法等
 */
export default class BusinessUtils {
  public static lastLayout: SiteInfos
  /**
   * 判断版式是否发生变更，如果变更
   * 业务中可能不能从缓存中取数据
   */
  public static isLayoutChange() {
    try {
      const { health, response } =
        BusinessUtils.parseInjectData<'apiGetSiteInfo'>('apiGetSiteInfo')
      if (health) {
        const newSiteInfos = response!.data.data
        const lastLayout = this.lastLayout
        for (const key in lastLayout) {
          if (
            lastLayout[key as keyof SiteInfos] !==
            newSiteInfos[key as keyof SiteInfos]
          ) {
            return true
          }
        }
        return false
      }
    } catch (error) {
      return true
    }

    return true
  }
  /**
   * 解析注入的数据
   */
  public static parseInjectData<T extends keyof InjectData>(
    name: keyof InjectData
  ) {
    const response = cloneDeep(window.LOBBY_SITE_CONFIG.INJECT_DATA?.[name]) as
      | InjectData[T]
      | undefined
    const data = response?.data?.data as
      | InjectData[T]['data']['data']
      | undefined
    const health = response?.data?.code === LobbyService.SERVICE_CODE.SUCCESS

    return {
      response,
      exit: !!response?.data,
      health,
      data
    }
  }
  /**
   * 三方账号脱敏
   * at sign 后面的全部显示
   * at sign 前小于等于3位的全部替换为*，其它情况只显示前三位
   */
  public static thirdPartyDesensitization(str: string, symbol = '*') {
    return str.replace(/^(.{2}).*(.{3})$/, (match, start, end) => {
      return start + symbol.repeat(str.length - 5) + end
    })
  }
  /**
   * 邮箱账号进行脱敏
   * at sign 后面的全部显示
   * at sign 前小于等于3位的全部替换为*，其它情况只显示前三位
   */
  public static emailDesensitization(email: string, symbol = '*') {
    const strBeforeAtSign = email.match(/^(\S+)@/)?.[1] || ''

    const doReplace = (visibleCount: number) =>
      email.replace(
        `${strBeforeAtSign}@`,
        `${strBeforeAtSign.slice(0, visibleCount)}${repeat(
          symbol,
          strBeforeAtSign.length - visibleCount
        )}@`
      )
    return doReplace(strBeforeAtSign.length <= 3 ? 0 : 3)
  }
  /**
   * 手机号脱敏规则
   * @param phone 带区号的手机号
   * @param digit 替换的最位数
   * @param symbol 替换的符号
   * @returns
   */
  public static phoneNumberDesensitization(
    phone: string,
    digit = 6,
    symbol = '*'
  ) {
    const start = 2 //不算区号从第三位开始脱敏
    if (~phone.indexOf('-')) {
      const { areaCode, value: phoneNum } = this.phoneNumberParse(phone)
      return (
        areaCode +
        phoneNum.slice(0, start) +
        repeat(symbol, Math.min(digit, phoneNum.length - 5)) +
        phoneNum.slice(start + Math.min(digit, phoneNum.length - 5))
      )
    } else {
      throw new Error('phone is not a correct phone number, phone = ' + phone)
    }
  }

  /**
   * cpf脱敏规则(第三位开始，最多6个)
   */
  public static cpfDesensitization(cpf: string) {
    if (!cpf) return ''
    if (/^\d+$/.test(cpf)) {
      if (cpf.length <= 3) {
        return repeat('*', cpf.length)
      } else {
        return cpf.slice(0, 2) + repeat('*', cpf.slice(2).length)
      }
    } else {
      throw new Error('cpf is not a correct cpf number, cpf = ' + cpf)
    }
  }

  /**
   * 解析手机号
   * @example phoneNumberParse('+86-21******2323')
   * //{origin:'+86-21******2323',areaCode:'+86-',placeholder:'******',value:'21******2323',prefix:'21',suffix:'2323'}
   */
  public static phoneNumberParse(template: string) {
    const [origin, areaCode, value, prefix, placeholder, suffix] =
      template.match(/(\+\d+\-)((\d+)(\**)(\d+))/) || []
    return {
      origin,
      areaCode,
      placeholder,
      value,
      prefix,
      suffix
    }
  }
  /**
   * ios新开窗口跳过马甲包判断返回window对象
   */
  public static iphoneOpenWindow(url = '') {
    let win!: Window | null
    if (navigator.userAgent.toLowerCase().includes('iphone') && !isWgPackage) {
      win = window.open(url, '_blank')
    }
    return win
  }
  /**
   * 解析邮箱号
   * @example emailCheckParse('1a_2b-3c******4c@gamil.com')
   */
  public static emailCheckParse(template: string) {
    const [useremail, emaildomain] = template.split('@')
    const [origin, prefix, placeholder, suffix] =
      useremail.match(/([^\*]*)(\**)([^\*]*)/) || []
    return {
      origin,
      prefix,
      placeholder,
      suffix,
      emaildomain
    }
  }

  public static px2rem(
    input: number | `${string}px`,
    remUnit?: number
  ): string {
    try {
      const mainStore = useMainStore()
      remUnit = remUnit || mainStore.remUnit
    } catch (error) {
      remUnit = remUnit || 100
    }
    const number = typeof input === 'string' ? parseFloat(input) : input
    return `${number / remUnit}rem`
  }

  /**
   * @description: https://day.js.org/docs/en/display/format
   * @param date 日期对象或者时间戳
   * @param options utcOffset指输出的时间的时区
   */
  static format(
    date: DateType = new Date(),
    fmt: DateFormatType = 'ymdhms',
    options?: DateOptions
  ) {
    const { language } = useMainStore()
    const fmtByLang =
      GlobalConst.DateFormatMap[language][fmt] ??
      GlobalConst.DateFormatMap.zh_CN.ymdhms
    return DateUtils.format(date, fmtByLang, options)
  }

  /**
   * 本地时间换成站点时间
   */
  static formatSiteOffsetTime(
    timestamp: number,
    fmt: DateFormatType = 'ymdhms'
  ) {
    const { siteInfos } = useMainStore()
    const serverTime = moment
      .utc(timestamp ? timestamp * 1000 : new Date().getTime())
      .utcOffset(siteInfos?.timeZone ?? '')
    const { language } = useMainStore()
    const fmtByLang =
      GlobalConst.DateFormatMap[language][fmt] ??
      GlobalConst.DateFormatMap.en_US.ymdhms
    return serverTime.format(fmtByLang)
  }
  /**
   * 格式化时间为 今天 18:18:18  昨天 18:18:18
   */
  static formatDisplayDate(time: Date | number, options?: DateOptions): string {
    let date: Date

    if (!time) {
      return ''
    }

    if (time instanceof Date) {
      date = time
    } else {
      const timestamp = time < 10000000000 ? time * 1000 : time // 判断是否是秒级别的时间戳
      date = new Date(timestamp)
    }

    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)
    const { t } = useI18n()
    if (date.toDateString() === today.toDateString()) {
      return `${t('lobby.common.date.today')} ${this.format(
        date,
        'hms',
        options
      )}`
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `${t('lobby.common.date.yesterday')} ${this.format(
        date,
        'hms',
        options
      )}`
    } else if (date.getFullYear() === today.getFullYear()) {
      return this.format(date, 'mdhms', options)
    } else {
      return this.format(date, 'ymdhms', options)
    }
  }
}

/**
 * 走马灯数据颜色映射符号格式化
 */
export const getMarqueeContent = (content: string, plainText = false) => {
  if (plainText) {
    return content.replace(/\/\||\/\#|\/\^|\/\-/g, '')
  }
  const colorsMap = {
    '/-': '#1678FF',
    '/|': '#04BE02',
    '/#': RED_COLOR,
    '/^': '#FFAA09'
  }
  for (const key in colorsMap) {
    let firstPosition = content.indexOf(key)
    while (firstPosition !== -1) {
      const color = colorsMap[key as keyof typeof colorsMap]
      content = content.replace(key, `<span style = "color: ${color}" >`)
      content = content.replace(key, '</span>')
      firstPosition = content.indexOf(key)
    }
  }
  return content
}

/**
 * 埋点-标准统计事件的代码
 */
export async function onAppEvent(
  eventName: AppEventType,
  params: {
    amount?: number
    value?: number
    currency?: string
    isFirst?: number
    success?: number
    domain?: string
    cid?: string | number
    id?: string | number
  } = {}
) {
  if (eventName == 'login') {
    Buried.initBuried()
  }
  const url = new URL(location.href)
  /** 域名 */
  const domain = url.host
  /** 渠道 */
  const cid = url.searchParams.get('cid') || ''
  /** 代理ID */
  const id = url.searchParams.get('id') || ''
  params = { ...params, domain, cid, id }
  try {
    /** 部分埋点平台需要强制使用 value 统一加上，用后端给的amount的值*/
    if (params.amount) params = { ...params, value: params.amount }
    const platform: { name: string; platformId?: string }[] = []
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    /** appflyer adjust 马甲包 */
    if (window.Android) {
      window.Android?.eventTracker?.(eventName, JSON.stringify(params))
      platform.push({ name: 'Android' })
    }
    /** uni马甲包 */
    if (window.uni && ['238'].includes(siteCode)) {
      window.uni.postMessage({
        data: {
          eventName,
          eventValues: params
        }
      })
      platform.push({ name: 'uni' })
    }
    /** 马甲包 */
    if (window.android_JSInter && ['171', '226', '638'].includes(siteCode)) {
      window.android_JSInter?.eventTracker(eventName, JSON.stringify(params))
      platform.push({ name: 'android_JSInter' })
    }
    /** 之后统一马甲包回调的埋点api */
    if (window.jsBridge) {
      window.jsBridge?.postMessage?.(eventName, JSON.stringify(params))
      platform.push({ name: 'jsBridge' })
    }
    /** Firebase */
    if (['230'].includes(siteCode)) {
      const { getAnalytics, logEvent } = await import('firebase/analytics')
      logEvent(getAnalytics(), eventName as string, params)
      platform.push({ name: 'firebase' })
    }
    /** ga埋点 */
    if (window.GTM) {
      if (window.GTM?.options?.gtagId) {
        window.GTM?.dataLayerPush('event', eventName, params)
        platform.push({ name: 'gtag', platformId: window.GTM?.options?.gtagId })
      }
      if (window.GTM?.options?.gtmId) {
        window.GTM?.GTMdataLayerPush('event', eventName, params)
        platform.push({ name: 'GTM', platformId: window.GTM?.options?.gtmId })
      }
    }
    /** MetaPixel埋点 */
    if (window.Pixel) {
      window.Pixel?.pixelEventPush(eventName, params)
      platform.push({
        name: 'MetaPixel',
        platformId: window.Pixel?.options?.pixelId
      })
    }

    /** KwaiPixel 埋点 */
    if (window.KwaiPixe) {
      window.KwaiPixe?.pixelEventPush(eventName, params)
      platform.push({
        name: 'KwaiPixe',
        platformId: window.KwaiPixe?.options?.kwaiqID
      })
    }

    /** tiktok 埋点 */
    if (window.Tiktok) {
      window.Tiktok?.pixelEventPush(eventName, params)
      platform.push({
        name: 'Tiktok',
        platformId: window.Tiktok?.options?.tiktokID
      })
    }

    /** Appier 埋点 */
    if (window.Appier) {
      window.Appier?.AppierEventPush(eventName, params)
      platform.push({ name: 'Appier' })
    }
    /** 187 渠道上报 */
    if (['187'].includes(siteCode) && cid) {
      if (eventName == 'register') {
        const url = `https://appcookie.ru/click.php?cnv_id=${cid}&event1=1&cnv_status=reg&cnv_status2=fb_mobile_complete_registration`
        fetchUrl(url)
      }
      if (eventName == 'firstrecharge') {
        const url = `https://appcookie.ru/click.php?cnv_id=${cid}&event2=1&cnv_status=dep&cnv_status2=fb_mobile_purchase`
        fetchUrl(url)
      }
    }

    /**【291】TJ埋点接入 */
    /** EVENT_CODE_MAP */
    if (window.EVENT_CODE_MAP && ['291'].includes(siteCode)) {
      platform.push({ name: 'EVENT_CODE_MAP' })
    }

    if (window.ReporterMananger) {
      GlobalEvents.dispatch({
        type: 'REPORT_MANAGER',
        eventName: eventName,
        payload: params
      })
    }

    /** 揭露事件 */
    window.postMessage({ eventType: 'onAppEvent', eventName, params })
    /** 回报日志目前只先回报注册成功/首次充值成功/充值成功的事件 */
    if (
      eventName == 'register' ||
      eventName == 'firstrecharge' ||
      eventName == 'recharge'
    ) {
      const username = useMainStore()?.userInfos?.username || ''
      const data = { ...params, platform, siteCode, username }
      import('@/context/tacker').then(({ trace }) => {
        trace(eventName, data, true)
      })
    }
  } catch (e) {
    // console.error(e)
  }
}

export { createMyPersistedStorage } from './presisted'

type PackageType =
  | ''
  | 'jsBridge.openWindow'
  | 'PlatNative.openWindow'
  | 'Android.openPG'
  | 'uni.openPG'
  | 'window.open'
const getType: () => PackageType = () => {
  const { siteInfos } = useMainStore()
  let deviceType: number | undefined
  if (getCurrentDevice().android()) {
    deviceType = 0
  } else if (getCurrentDevice().ios()) {
    deviceType = 1
  }

  const configs = Array.isArray(siteInfos?.vestBagJumpConfig)
    ? siteInfos?.vestBagJumpConfig
    : [siteInfos?.vestBagJumpConfig]

  let type =
    (configs?.length &&
      configs
        .filter((v) => v?.appType === deviceType)
        .find(
          (v) =>
            (window.WgPackage?.name || defaultUrlQueryParams['WgPackage']) ===
            v?.packageName
        )?.appJumpValue) ||
    ''

  /**
   * 最大限度不影响之前的逻辑
   * 极速包且没设置type就给一个默认的type为jsBridge.openWindow
   * */
  if (windowConfig.speedPackageConfig && !type) {
    type = 'jsBridge.openWindow'
  }
  return type
}

/**
 * href 为 要打开的地址或者 为 返回Promise<string>的函数，请求失败或者url异常请业务层传promise时主动抛错
 * options 配置 open函数的调用时机和初始页面
 * @see https://www.cg.xxx/doc-objectLibs-custom-0-274-27494.html
 */
export function openHack(
  href: string | (() => Promise<string>),
  options?: {
    keepOpener?: boolean /**控制打开的新window是否保留opener属性**/
    preUrl?: string
    createDelay?: number /**控制预开窗的调用时机*/
    openDelay?: number /**控制最终调用真实替换地址时的延迟*/
    created?: (context: Window | null) => void /**窗口创建时的回调**/
    opened?: (context: Window | null) => void /**最终地址跳转后的回调**/
    forceOpenDirect?: boolean // 不会提前新开窗（防浏览器拦截）
    openMode?: 'window.open.location' | 'A' | 'iframe-route'
    windowFeatures?: string /**window.open的windowFeatures参数**/
  }
) {
  /**
   * 以下三个平台的马甲包可确定不会用window.open打开链接，会用对应的事件处理
   * window.jsBridge 情况时有历史原因，有可能马甲包中未用事件，仍然响应的window.open，故需要排除
   */
  const isSyncHref = typeof href === 'string'
  const type = getType()

  // 一定不会使用window.open 去开窗打开
  const notUseWindow =
    (window.PlatNative || window.Android || window.uni) &&
    type !== 'window.open'

  // facebook
  const isFaceBookEnv = !!['FB_', 'FBAN', 'FBAV'].find((v) =>
    navigator.userAgent.includes(v)
  )

  const isInstagramEnv = !!['Instagram'].find((v) =>
    navigator.userAgent.includes(v)
  )

  const isIPhone = navigator.userAgent.toLowerCase().includes('iphone')

  const openMode = options?.openMode || 'window.open.location'

  const forceOpenDirect =
    options?.forceOpenDirect ?? (isWgPackage || isFaceBookEnv || isInstagramEnv)

  const notPreCreate = forceOpenDirect || openMode !== 'window.open.location'

  import('@/context/tacker').then(({ trace }) => {
    trace(
      'recharge-open',
      {
        type,
        mode: openMode,
        notUseWindow,
        durl,
        to: href
      },
      true
    )
  })

  options = Object.assign(
    {
      /**
       * 默认删除opener
       */
      keepOpener: false,
      /**
       * 默认打开空白页
       */
      preUrl: '',
      /**
       * 默认同步url或者ios设备延时为0（不延时），直接创建，
       * 异步接口响应时间超过4.8s以上 PC 会被拦截
       * 属于上面异常超长的场景下，调用时必须将createDelay 设为0 走提前开窗模式，防拦截
       */
      createDelay: 0,
      /**
       * 默认同步url或者马甲包事件派发环境延时为0（不延时），直接打开
       * ios设备100，其它设备300
       */
      openDelay: notPreCreate || isSyncHref ? 0 : isIPhone ? 100 : 300,

      // 不会提前创建 a | window
      notPreCreate,
      openMode,
      windowFeatures: ''
    },
    options
  )

  let winRef: Window | null = null
  const dom: HTMLElement | null = null

  /**
   * 记录最终打开的url值
   */
  let targetUrl = ''
  let timer: number | null = null
  /**
   * 预开窗口
   */
  const preCreate = () => {
    if (notPreCreate) return
    const needOpenTargetWhenCreate = !!targetUrl
    /**
     * 如果targetUrl已经有值了,说明创建时就需要赋值
     * 提前新开窗，为了解决正常浏览器会拦截url
     */
    winRef = window.open(
      targetUrl || options?.preUrl,
      '_blank',
      options?.windowFeatures
    )

    options?.created?.(winRef)
    if (needOpenTargetWhenCreate) {
      options?.opened?.(winRef)
      if (!options?.keepOpener) {
        if (winRef) {
          winRef.opener = null
        }
      }
    }
  }
  const createDelay = options?.createDelay
  if (createDelay && createDelay > 0) {
    timer = window.setTimeout(() => {
      preCreate()
    }, createDelay)
  } else if (!createDelay) {
    preCreate()
  }

  const open = (url: string) => {
    const done = () => {
      if (forceOpenDirect) {
        /**
         * 有jsBridge的马甲包尝试使用事件派发，但因历史原因，必须同时保留window.open
         * 历史业务场景分析：
         * 1.旧马甲包注册了事件派发，屏蔽了window.open ==> 用事件响应打开 ==> 正常
         * 2.旧马甲包未注册事件派发，未屏蔽window.open ==> 必须依赖window.open调用 ==> 正常
         * 3.旧马甲包注册了事件派发，未屏蔽window.open ==> 未兼容场景2,window.open必须调用，同时事件也会调用，导致打开两次 ==> 异常
         */
        windowOpen(url)
      } else {
        const resultURL = `${
          location.origin
        }/pages/open/index.html?to=${encodeURIComponent(url)}`

        if (openMode === 'window.open.location') {
          /**
           * 无winRef说明createDelay设置过高
           */
          if (winRef) {
            /**
             * 判断winRef是否有值，如有则已打开了新窗口，则将新窗口地址置为url
             * 否则将url赋给targetUrl即可
             */
            if (!options?.keepOpener) {
              winRef.opener = null
            }
            winRef.location = resultURL
            options?.opened?.(winRef)
          } else {
            targetUrl = url
          }
        } else if (openMode === 'A') {
          const dom = document.createElement('a')
          dom.setAttribute('target', '_brank')
          dom.setAttribute('href', resultURL)
          dom.click()
          setTimeout(() => {
            dom && document.body.removeChild(dom)
          }, 1000)
        } else if (openMode === 'iframe-route') {
          GlobalManager.App.$router.push({
            name: RouteName.IFRAME_ROUTE,
            query: {
              to: encodeURIComponent(resultURL)
            }
          })
        }
      }
    }
    if (options?.openDelay && options.openDelay > 0) {
      setTimeout(done, options.openDelay)
    } else {
      done()
    }
  }

  if (href instanceof Function) {
    return href()
      .then((href) => {
        open(href)
      })
      .catch((error) => {
        winRef && (winRef as Window)?.close?.()
        dom && document.body.removeChild(dom)
        timer && clearTimeout(timer)
        return Promise.reject(error)
      })
  } else {
    open(href)
  }
}

export function windowOpen(url: string | undefined = '', log = false) {
  const type = getType()
  if (log) {
    import('@/context/tacker').then(({ trace }) => {
      trace(
        'recharge-wopen',
        {
          durl,
          to: url,
          type,
          env: {
            jsb: !!window.jsBridge,
            pn: !!window.PlatNative,
            uni: !!window.uni,
            ap: !!window.Android
          }
        },
        true
      )
    })
  }

  try {
    if (window.jsBridge && type === 'jsBridge.openWindow') {
      window.jsBridge?.postMessage('openWindow', JSON.stringify({ url: url }))
      return
    } else if (window.PlatNative && type === 'PlatNative.openWindow') {
      /** 169 使用这个 不能删除 */
      window.PlatNative?.event('openWindow', JSON.stringify({ url: url }))
      return
    } else if (window.Android && type === 'Android.openPG') {
      window.Android?.eventTracker('openPG', JSON.stringify({ url: url }))
      return
    } else if (window.uni && type === 'uni.openPG') {
      window.uni.postMessage({
        data: {
          eventName: 'openPG',
          eventValues: { url }
        }
      })
      return
    } else if (type === 'window.open') {
      window.open(url)
      return
    }
  } catch (e) {
    // console.error(e)
  }

  if (window.jsBridge) {
    window.jsBridge?.postMessage('openWindow', JSON.stringify({ url: url }))
  }
  if (window.PlatNative) {
    /** 169 使用这个 不能删除 */
    window.PlatNative?.event('openWindow', JSON.stringify({ url: url }))
  } else if (window.Android) {
    window.Android?.eventTracker('openPG', JSON.stringify({ url: url }))
  } else if (window.uni) {
    window.uni.postMessage({
      data: {
        eventName: 'openPG',
        eventValues: { url }
      }
    })
  } else {
    window.open(url)
  }
}

let maintainCheckTimeout: number | null = null

export const maintainCheckOnStartTime = (
  maintainTimeInfo: Pick<MaintainType, 'end_time' | 'start_time'>
) => {
  const timeDiff = maintainTimeInfo.start_time * 1000 - Date.now()
  if (timeDiff > 0) {
    GlobalManager.Polling.destroy(LobbyPollingType.MAINTAININFO)
    if (maintainCheckTimeout) {
      window.clearTimeout(maintainCheckTimeout)
    }
    maintainCheckTimeout = window.setTimeout(() => {
      /**
       * 打开后进行轮询进行更新或者关闭操作
       */
      GlobalManager.Polling.create({
        key: LobbyPollingType.MAINTAININFO,
        leading: true,
        interval: 1000 * 60 * 5,
        callback: () => {
          GlobalEvents.dispatch({
            type: 'SITE_MAINTAIN'
          })
        }
      })
    }, timeDiff)
  }
}

export const formatMaintainTimeInfo = (
  maintainTimeInfo: Pick<
    MaintainType,
    'end_time' | 'start_time' | 'site_time_zone'
  >
) => {
  const { siteInfos } = useMainStore()
  const { start_time, end_time, site_time_zone } = maintainTimeInfo
  const utcOffset =
    site_time_zone || siteInfos?.timeZone || DateUtils.localUtcOffset
  try {
    const duration = DateUtils.moment
      .duration(end_time && start_time ? end_time - start_time : 0, 'seconds')
      .asHours()
    const now = Date.now()
    const roundUpToNextHalfHour = (hours: number) => {
      // 首先将小时转换为半小时单位
      const halfHours = hours * 2
      // 使用Math.ceil来始终向上舍入到下一个整数
      const roundedHalfHours = Math.ceil(halfHours)
      // 再将半小时单位转换回小时单位
      return roundedHalfHours / 2
    }
    return {
      health: now >= start_time * 1000 && now <= end_time * 1000,
      now: BusinessUtils.format(undefined, undefined, {
        utcOffset
      }),
      start: BusinessUtils.format(start_time, undefined, {
        utcOffset
      }),
      end: BusinessUtils.format(end_time, undefined, {
        utcOffset
      }),
      duration: Math.max(0.5, roundUpToNextHalfHour(duration))
    }
  } catch (error) {
    return {
      now: BusinessUtils.format(undefined, undefined, {
        utcOffset
      }),
      health: false
    }
  }
}
export const windowReload = (tips: string, time = 1000) => {
  const { t } = useI18n()
  const { isWeb } = useMainStore()
  const timeout = setTimeout(() => {
    window.location.reload()
  }, time)
  GlobalManager.Modal.create({
    title: t('lobby.receiveModal.kindTips'),
    width: isWeb ? BusinessUtils.px2rem(550) : BusinessUtils.px2rem(600),
    contentPosition: 'center',
    content: tips,
    onOk: async () => {
      clearTimeout(timeout)
      window.location.reload()
    }
  })
}

/**
 * 域名埋点
 */
export const sendPointerDomain = async (
  kind: KindType = KindType.Normal,
  packKind?: PackKindType
) => {
  // 设备唯一标识
  const fingerId = getStorage()['uuid']
  // 请求限制标识，一天一次
  const expirationTime = getStorage('expirationTime')['value']
  const nowTime = moment().endOf('day').valueOf()
  const isByDay =
    (expirationTime && nowTime > expirationTime) || !expirationTime
  // 如果是次日或者还没请求过则可以请求
  if (isByDay || kind !== KindType.Normal) {
    if (isByDay) {
      setStorage({ value: nowTime }, 'expirationTime')
    }
    await apiPointerDomain({
      fingerId,
      kind,
      packKind,
      domain: window.location.hostname
    })
  }
}

/**
 * 格式化维护请求相关的信息
 */
export const formatMaintainRequestInfo = (options: SiteMaintainPayload) => {
  const res = options.res
  const isSiteFreeze =
    options.isSiteFreeze ??
    res?.data?.code === LobbyService.SERVICE_CODE.SITE_FREEZE_ERROR
  const isSiteFreezeAndMaintain =
    options.isSiteFreezeAndMaintain ??
    res?.data?.code === LobbyService.SERVICE_CODE.SITE_FREEZE_AND_MAINTAIN_ERROR
  const config = res?.config || {}
  const content = {
    ...options,
    isSiteFreeze,
    isSiteFreezeAndMaintain,
    requestInfo: {
      url: config?.url,
      xRequestId: config?.headers?.['x-request-id'],
      code: res?.data?.code
    }
  }
  return content
}

interface StringRecord {
  [key: string]: string
}

/**
 * 将style字符串转换为一个对象
 */
export const styleStringToObject = (styleString: string): StringRecord => {
  // 使用 semicolon (;) 分割样式字符串来获取样式数组
  const styleArray = styleString.split(';')
  return styleArray.reduce((obj: StringRecord, styleDeclaration) => {
    const [property, value] = styleDeclaration.split(':')
    if (property && value) {
      // 删除属性名和属性值周围的空白字符并将它们添加到对象中
      obj[property.trim()] = value.trim()
    }
    return obj
  }, {})
}

/** 判断是否是数组json序列化后的字符串 */
export const isJSONStringArray = (str: string) => {
  try {
    const result = JSON.parse(str)
    return Array.isArray(result)
  } catch (error) {
    return false
  }
}
/**
 * 将富文本中a标签href属性拼接参数
 */
export const appendParamsToATagsInRichText = (
  htmlContent: string,
  params: StringRecord
) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent, 'text/html')

  const aTags = doc.querySelectorAll('a')

  aTags.forEach((a) => {
    const href = a.getAttribute('href') ?? ''

    const pattern = /^(http|https):\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/\S*)?$/
    if (href && pattern.test(href)) {
      const url = new URL(href)

      Object.entries(params).forEach(([key, value]) => {
        // 添加新参数，避免重复拼接相同参数
        if (!url.searchParams.has(key)) {
          url.searchParams.append(key, value)
        }
      })
      a.setAttribute('href', url.toString().replace(/&amp;/g, '&'))
    }
  })

  const richTextContent = Array.from(doc.body.childNodes)
    .map((node) =>
      node instanceof HTMLElement ? node.outerHTML : node.textContent
    )
    .join('')

  return richTextContent
}
