import { useMainStore } from '@/store/index'

export interface Options {
  pixelId?: string
}
export default class MetaPixel {
  public options!: Options

  constructor(options: Options) {
    this.options = Object.assign({}, options)
    this.init()
  }

  public init(): void {
    this.loadJs()
    this.createNoScript()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public pixelEventPush(eventName: string, params: object): void {
    const { userInfos } = useMainStore()
    const metaPixelTracker = (eventNameFormat: { [key: string]: string }) => {
      const mappingEventName = eventNameFormat[eventName]
      if (mappingEventName) {
        window.fbq('track', mappingEventName, params, {
          eventID: userInfos?.username ?? ''
        })
      }
      window.fbq('trackCustom', eventName, params)
    }
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    if (['169', '669', '927', '738', '218'].includes(siteCode)) {
      /** 事件转化官方事件
       * register：completeRegistration 注册
       * rechargeClick：InitiateCheckout 注册点击
       * firstrecharge：purchase 付费
       * recharge：AddToCart 加入购物车-加购
       */
      const eventNameFormat: { [key: string]: string } = {
        register: 'CompleteRegistration',
        rechargeClick: 'InitiateCheckout',
        firstrecharge: 'Purchase',
        recharge: 'AddToCart'
      }
      metaPixelTracker(eventNameFormat)
      return
    } else {
      /** 事件转化官方事件
       * register：completeRegistration 注册
       * rechargeClick：InitiateCheckout 注册点击
       * firstrecharge：purchase 付费
       * recharge：Purchase 付费
       */
      const eventNameFormat: { [key: string]: string } = {
        register: 'CompleteRegistration',
        rechargeClick: 'InitiateCheckout',
        firstrecharge: 'Purchase',
        recharge: 'Purchase'
      }
      metaPixelTracker(eventNameFormat)
    }
  }

  private async loadJs() {
    document.querySelector('#fb-script')?.remove()
    const script = document.createElement('script')
    script.id = 'facebook-script'
    script.type = 'text/javascript'
    // <!-- Meta Pixel Code -->
    script.appendChild(
      document.createTextNode(`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${this.options.pixelId}');
      fbq('track', 'PageView');
      fbq('track', 'ViewContent');
      `)
    )
    document.head.appendChild(script)
  }

  private createNoScript(): void {
    document.querySelector('#fb-noscript')?.remove()
    const $noscript = document.createElement('noscript')
    $noscript.id = 'fb-noscript'
    $noscript.innerHTML = `<iframe
        title="facebook"
        src="https://www.facebook.com/tr?id=${this.options.pixelId}&ev=PageView&noscript=1"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
      >`
    document.body.insertBefore($noscript, document.body.children[0])
  }
}
