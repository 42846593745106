/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-10 15:55:20
 * @LastEditors: Wallis@cg.app
 * @FilePath: \web-lobby\src\service\reporter\ReporterGoImp.ts
 * @Description: 使用 GO API 上报错误
 */
import { EUploadPlatform, TUploadPayload } from './type'
import ReportAbst from './ReporterAbst'

export class ReporterGoImp extends ReportAbst {
  constructor() {
    super(EUploadPlatform.GO)
  }

  protected init(): void {
    // 初始化GO
    // eslint-disable-next-line
    console.info('GO init')
  }

  public upload(data: TUploadPayload): void {
    // 其他需要封装的参数，写这里 * 仅封装该上传渠道特定的参数，非公共参数
    const payload = {
      ...data.payload,
      ...data.extParams
    }
    // eslint-disable-next-line
    console.info('GO upload', payload)
    // apiTraceReport(payload)
  }
}

export default ReporterGoImp
