/*
 * @Author: Wallis@cg.app
 * @Date: 2024-05-14 18:09:22
 * @LastEditors: Wallis@cg.app
 * @FilePath: \reportersdk\src\reporter\type.d.ts
 * @Description:
 */
import { EEffecitveType } from '../rules/effective'
import { EParamsType } from '../rules/params'
import { TConditions } from '../rules/effective'

export enum EUploadPlatform {
  GA = 'ga', // Google Analytics
  GO = 'go', // Go API
  SENSORS = 'sensors' // 神策
}

// 所有枚举的类型

// export interface ContextInfo {}

export interface NavigatorConnectionInfo {
  downlinkMax?: string
  effectiveType?: string
  rtt?: number
  saveData?: boolean
}

export enum EEventType {
  ERROR = 'error',
  NETWORK = 'network',
  PERFORMANCE = 'performance',
  CUSTOM = 'custom',
  DEFAULT = 'default'
}

type TStrategy = {
  name: EStategyType
  option?: {
    duration: number
    targetBy: 'eventName' | 'eventType' | undefined
    relationKey?: string
  }
}

type TRuleSuiteProps = {
  uploadPlatforms: Array<EUploadPlatform>
  params?: Array<EParamsType>
  eventType?: EEventType
  effective?: Array<{
    name: EEffecitveType
    conditions?: TConditions
  }>
  strategy?: TStrategy
}

export type TUploadPayload = {
  eventName: string
  eventType: EEventType
  payload: Record<string, unknown>
  extParams: Record<string, unknown>
}

export type TRuleSuiteResult = {
  strategy: TStrategy
  effective: { [key: string]: boolean }
} & TUploadPayload

export interface IUploadData {
  rule?: TRuleSuiteProps
  eventName: string
  payload?: Record<string, unknown>
}

export enum EStategyType {
  // 默认模式
  default = 'default',
  // 节流模式
  throttle = 'throttle',
  // 防抖模式
  debounce = 'debounce',
  // 合并模式
  merge = 'merge'
}
