/* eslint-disable @typescript-eslint/no-explicit-any*/
import { CombineParams, GameType } from './pictureUrlCore'
import { GameItemInter } from '../../gameItem'
import { GamePlatformCatgory } from '@/views/game/consts/categorys'
import {
  PlatformCategoriesItem,
  PlatformCategoriesItemInter
} from '../../gameCategorie'
import AnchorHot from '../../anchorHot'
import GameItem, { GameItemType } from '../../gameItem'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 卡片原始数据 */
export type CardData = GameItemInter | PlatformCategoriesItemInter | AnchorHot
/** 从后端取到的数据,最终转换成拼装路径需要的参数 */
export class ResponseData2Params {
  /** 游戏数据转入参 */
  public static gameData2Params(data: CardData) {
    /** 拼接参数 */
    const combineParams = {
      cardData: data
    } as CombineParams
    /** 普通属性转换 */
    const normalPropertiesChange = (
      data: GameItemInter | PlatformCategoriesItemInter
    ) => {
      combineParams.gameCategoryId = data.gameCategoryId
      combineParams.platformId = data.platformId
      combineParams.gameId = data.gameId
      combineParams.isOriginImg = data.isOriginImg
      combineParams.isCustomIcon = data.isCustomIcon
    }
    // 游戏类型转换
    if (GameItem.isThisType(data)) {
      normalPropertiesChange(data)
      // 就算它是平台,这里取也是取的gameName,因为这个平台的格式是和Game一个格式
      combineParams.renderName = data.gameName
      // 如果是单个游戏的数据结构,类型又写的平台,则一定是热门列表下的平台类型
      // PS:这条规则,在后期必须会改,因为按道理来讲,平台类型的数据结构,其自身就应该含有方型类型,而不是以GameItem的格式渲染出来就是方型,就像以后要加圆型,平台自身也应该有圆型,等形状.
      if (data.gameType === GameItemType.Platform) {
        combineParams.gameType = GameType.Platform
        // [热门/最近/收藏/搜索结果]游戏icon需要一直展示热门图标
        if (data.isDisplayHotIcon) {
          combineParams.isExhibitHotIcon = true
        } else {
          combineParams.isExhibitHotIcon = false
        }
      }
      // 否则,就是普通的单个游戏.
      else {
        combineParams.gameType = GameType.Individual
        combineParams.isExhibitHotIcon = false
      }
    }
    // 平台类型转换
    else if (PlatformCategoriesItem.isThisType(data)) {
      normalPropertiesChange(data)
      combineParams.renderName = data.platformName
      combineParams.gameType = GameType.Platform
      // 平台类型的数据,是否热门列表的平台,永远为false,平台类型的数据结构只有纯平台
      combineParams.isExhibitHotIcon = false
    }
    // 主播游戏项
    else if (AnchorHot.isThisType(AnchorHot)) {
      combineParams.gameType = GameType.Individual
      combineParams.gameCategoryId = data.gameCategoryId
      combineParams.platformId = data.platformId
      combineParams.isExhibitHotIcon = false
      // combineParams.gameId='' //主播游戏没有游戏id
      // combineParams.isCustomIcon=data.isCustomIcon //主播游戏没有自定义图片
    }
    return combineParams
  }

  /** 游戏接口原始数据转精简版的web游戏数据 */
  public static responseData2simpleGameItem(data: Any) {
    return {
      responseData: data, //它存在的价值是为了方便调试
      gameName: data['g1'], //它存在的价值是为了方便调试
      gameIcon: '', // 类型守卫验证刚需
      gameCategoryId: data['g9'],
      platformId: data['g10'],
      gameId: data['g0'],
      isCustomIcon: Boolean(data['g3']),
      isOriginImg: Boolean(data['g16']),
      gameType:
        data['g11'] ||
        (GamePlatformCatgory.includes(data.g9)
          ? GameItemType.Platform
          : GameItemType.NormalGame),
      isDisplayHotIcon: data['isDisplayHotIcon']
    } as GameItemInter & { responseData: any }
  }

  /** 平台游戏接口原始数据转精简版的web平台数据 */
  public static responseData2simplePlatformCategoriesItem(data: Any) {
    return {
      responseData: data, //它存在的价值是为了方便调试
      platformName: data['t10'], //它存在的价值是为了方便调试
      platformIcon: '', // 类型守卫验证刚需
      brand: '', // 类型守卫验证刚需
      slogan: '', // 类型守卫验证刚需
      gameCategoryId: data['t4'],
      platformId: data['t9'],
      gameId: data['t1'],
      isCustomIcon: Boolean(data['t3']),
      isOriginImg: Boolean(data['t19'])
    } as PlatformCategoriesItemInter & { responseData: any }
  }
}
