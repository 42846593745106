export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Error sa System',
        NETWORK_ERROR:
          'Nabigo ang koneksyon sa network, mangyaring subukang muli pagkatapos lumipat sa network',
        RSP_DATA_NOT_JSON: 'Error sa Halaga ng Halaga ng Tugon',
        GEETEST_RESTRICTED:
          'Madalas na operasyon, pakisubukang muli sa loob ng {seconds} segundo'
      },
      tips: { title: 'Paalala' }
    }
  }
}
