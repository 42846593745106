export interface Options {
  gtagId?: string
  gtmId?: string
  prop?: string | 'dataLayer'
}
export default class GoogleTagManager {
  public options!: Options

  constructor(options: Options) {
    this.options = Object.assign({ prop: 'dataLayer' }, options)
    this.init()
  }

  public init(): void {
    this.loadJs()
    window.dataLayer = window.dataLayer || []
    if (this.options.gtagId) {
      this.dataLayerPush('js', new Date())
      this.dataLayerPush('config', this.options.gtagId)
    }
    if (this.options.gtmId) {
      this.createNoScript()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dataLayerPush(..._args: any): void {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer?.push(arguments)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public GTMdataLayerPush(...args: any): void {
    const value = args[2]
    window.dataLayer?.push({
      [args[0]]: args[1],
      value
    })
  }

  private async loadJs() {
    const { gtagId, gtmId } = this.options

    if (gtagId) {
      document.querySelector('#script-gtag')?.remove()
      const script = document.createElement('script')
      script.id = 'script-gtag'
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`
      document.head.appendChild(script)
    }

    if (gtmId) {
      document.querySelector('#script-gtm')?.remove()
      document.querySelector('#script-inner-gtm')?.remove()
      const script = document.createElement('script')
      script.id = 'script-gtm'
      script.type = 'text/javascript'
      // <!-- Meta Pixel Code -->
      script.appendChild(
        document.createTextNode(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;
      // 给创建的 script 元素设置 ID
      j.id = 'script-inner-gtm';
      f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`)
      )
      document.head.appendChild(script)
    }
  }

  private createNoScript(): void {
    document.querySelector('#noscript-gtm')?.remove()
    const $noscript = document.createElement('noscript')
    $noscript.id = 'noscript-gtm'
    $noscript.innerHTML = `<iframe
        title="googletagmanager"
        src="https://www.googletagmanager.com/ns.html?id=${this.options.gtmId}"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
      >`
    document.body.insertBefore($noscript, document.body.children[0])
  }
}
