import { PictureUrlCore } from './pictureUrlCore'
import { PreviewMode } from './previewMode'
import { ResponseData2Params } from './responseData2Params'
import _GameItem from '../../gameItem'
import _PlatformCategoriesItem from '../../gameCategorie/platformCategoriesItem'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 拼装图片路径 */
export class CombinePictureUrl {
  /** 平台数据解析出图片资源路径 */
  public static parsePlatformItem = (data: Any) => {
    /** 预览模式的地址 */
    const previewUrl = PreviewMode.run()
    // 如果预览模式有返回地址,则直接展示预览模式的数据
    if (previewUrl) {
      return previewUrl
    }
    /** 精简版的平台数据 */
    let simplePlatformItem = data
    // 如果传入的是后台原始数据,则处理成精简版的平台数据
    if (!_PlatformCategoriesItem.isThisType(simplePlatformItem)) {
      simplePlatformItem =
        ResponseData2Params.responseData2simplePlatformCategoriesItem(data)
    }
    /** 将卡片数据转换成图片路径函数需要的入参 */
    const params = ResponseData2Params.gameData2Params(simplePlatformItem)
    return PictureUrlCore.run(params)
  }

  /** 游戏数据解析出图片资源路径
   * @param data 可能是 GameItem ,也可能是后台返回的原始数据
   */
  public static parseGameItem = (data: Any) => {
    /** 预览模式的地址 */
    const previewUrl = PreviewMode.run()
    // 如果预览模式有返回地址,则直接展示预览模式的数据
    if (previewUrl) {
      return previewUrl
    }
    /** 精简版的游戏数据(该数据可能是处理过后的完整数据,不需要特殊处理) */
    let simpleGameItem = data
    // 如果传入的是后台原始数据,则处理成精简版的游戏数据
    if (!_GameItem.isThisType(data)) {
      simpleGameItem = ResponseData2Params.responseData2simpleGameItem(data)
    }
    /** 将卡片数据转换成图片路径函数需要的入参 */
    const params = ResponseData2Params.gameData2Params(simpleGameItem)
    return PictureUrlCore.run(params)
  }

  /** 平台数据解析成【热门类型】的图片资源
   * @param {PlatformCategoriesItem 或 t开头后台原始数据}
   */
  public static parseHotPlatformItem(data: Any) {
    /** 精简版的平台数据 */
    let simplePlatformItem = data
    // 如果传入的是后台原始数据,则处理成精简版的平台数据
    if (!_PlatformCategoriesItem.isThisType(simplePlatformItem)) {
      simplePlatformItem =
        ResponseData2Params.responseData2simplePlatformCategoriesItem(data)
    }
    /** 精简版的游戏结构(平台)数据 */
    const simpleGameItem = new _GameItem().parsePlatformCategoriesItem(
      simplePlatformItem
    )
    // 解析出热门平台的路径
    return CombinePictureUrl.parseGameItem(simpleGameItem)
  }

  /** 平台数据解析成【亚太经典版式下的热门类型】的图片资源
   * 当前仅有969/5159等特殊站点,平台图片是需要特殊处理.
   * 这个转换方法是不合理的,平台卡片应该有它自己平级的[横向图片与方型图片].
   * 但是它现在只有横向图片,没有与之平级的方型图片,不过可以从热门游戏资源那里找到平台的方型图片
   * 所以当前使用[亚太经典下的热门游戏的平台方型图片]代替[本应与横向图片平级的方型图片]
   */
  public static parseNormalHotPlatformItem(data: Any) {
    /** 精简版的平台数据 */
    let simplePlatformItem = data
    // 如果传入的是后台原始数据,则处理成精简版的平台数据
    if (!_PlatformCategoriesItem.isThisType(simplePlatformItem)) {
      simplePlatformItem =
        ResponseData2Params.responseData2simplePlatformCategoriesItem(data)
    }
    /** 将卡片数据转换成图片路径函数需要的入参 */
    const params = ResponseData2Params.gameData2Params(simplePlatformItem)
    /** 此时获得的图片资源,只可能是一张普通的平台资源,不可能是热门的平台资源,因为正统数据获得规则下>通过【平台数据格式】只能获得【普通的平台资源】 */
    let imgUrl = PictureUrlCore.run(params)
    // 正则逻辑,将普通的平台卡片地址,替换成【经典版式下原始方型(注意区分后期新的大方型卡片)的热门的】平台卡片地址
    // 正则规则测试代码 'https://xvo35z-024-ttt.oss-accelerate.aliyuncs.com/game_pictures/p/024/L1/41/4/default_unLogo_CNY.png'.replace(new RegExp(`/.\{1,3\}/41/4`),`/CL/hot/41/4`)
    imgUrl = imgUrl.replace(
      new RegExp(
        `/.\{1,3\}/${simplePlatformItem.platformId}/${simplePlatformItem.gameCategoryId}`
      ),
      `/CL/hot/${simplePlatformItem.platformId}/${simplePlatformItem.gameCategoryId}`
    )
    // 'https://y1q1tq-1320-ttt.oss-accelerate.aliyuncs.com/game_pictures/p/1320/CL/hot/50/4/material_CNY.png'.replace(/material(_\w{1,3})?(\.png)$/, 'default$1$2')
    // 'https://y1q1tq-1320-ttt.oss-accelerate.aliyuncs.com/game_pictures/p/CL/hot/52/4/material.png'.replace(/material(_\w{1,3})?(\.png)$/, 'default$1$2')
    // 这种特殊的使用热门图的地方,需要将原图material替换成default后缀,但是正统热门图是通过GameItem中解析出来的,所以它是不会走到此逻辑的,不会影响到它
    imgUrl = imgUrl.replace(/material(_\w{1,3})?(\.png)/, 'default$1$2') // 此处正则最后不加$不那么严谨,因为后面可能还有拼接?一些调试参数什么的..
    return imgUrl
  }
}
