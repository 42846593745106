import { EEffecitveType } from './effective'
import {
  EEventType,
  EStategyType,
  EUploadPlatform,
  TRuleSuiteProps
} from '../modules/type'
import { EParamsType } from './params'

// 规则配置
export enum ERuleType {
  COMMON_ALL = 'COMMON_ALL',
  COMMON_GO = 'COMMON_GO',
  COMMON_GA = 'COMMON_GA',
  COMMON_SENSORS = 'COMMON_SENSORS',
  NETWORK_ERROR = 'NETWORK_ERROR',
  USER_ACTION = 'USER_ACTION'
}
export const RULES: { [key in ERuleType]: TRuleSuiteProps } = {
  /**
   * 通用规则 COMMON，不附带任何参数，适用于所有场景，立即上报
   */
  COMMON_ALL: {
    uploadPlatforms: Object.values(EUploadPlatform),
    params: [EParamsType.withContext]
  },
  COMMON_GO: {
    uploadPlatforms: [EUploadPlatform.GO]
  },
  COMMON_GA: {
    uploadPlatforms: [EUploadPlatform.GA]
  },
  COMMON_SENSORS: {
    uploadPlatforms: [EUploadPlatform.SENSORS]
  },
  /**
   * 网络错误规则 NETWORK_ERROR，附带网络错误参数，适用于网络错误场景，非立即上报
   */
  NETWORK_ERROR: {
    uploadPlatforms: [EUploadPlatform.GO, EUploadPlatform.GA],
    params: [EParamsType.withContext, EParamsType.withNavigateConnection],
    effective: [{ name: EEffecitveType.filterGoEvent }],
    eventType: EEventType.NETWORK,
    strategy: {
      name: EStategyType.merge,
      option: {
        duration: 5 * 1000,
        targetBy: 'eventName',
        relationKey: 'id'
      }
    }
  },
  /**
   * 用户行为埋点，附带用户行为参数，适用于用户行为场景，立即上报
   */
  USER_ACTION: {
    uploadPlatforms: [EUploadPlatform.GA, EUploadPlatform.SENSORS],
    eventType: EEventType.CUSTOM
  }
}
